import {
    AL_APPLICATIONS,
    SA_APPLICATIONS,
} from '../../../../constants/constants';

export const italian = {
    // General
    language: 'Lingua',
    de: 'Tedesco',
    en: 'Inglese',
    fr: 'Francese',
    it: 'Italiano',

    save: 'Salva',
    cancel: 'Annulla',
    confirm: 'Conferma',
    decline: 'Respingere',
    close: 'Chiudi',
    refresh: 'Aggiorna',
    back: 'Indietro',
    signOut: 'Logout',

    reconnectingHeader: 'Connessione persa',
    reconnectingMessage:
        'Connessione al server persa. La connessione viene ripristinata...',

    // Device Settings

    deviceSettings: 'Impostazioni',
    cam: 'Videocamera',
    mic: 'Mircrofono',
    resolution: 'Risoluzione',
    comModeNone: 'Solo chat',
    comModeAudio: 'Audio',
    comModeBoth: 'Audio + video',

    // Meeting

    agenda: 'Ordine del giorno',
    showboard: 'Showboard',
    minutes: 'Rapporto',
    documents: 'Documenti',
    chat: 'Chat',
    surveys: 'Sondaggi',
    show: 'Mostra',

    startFullscreen: 'Apri schermo intero',
    stopFullscreen: 'Chiudi schermo intero',
    startScreenShare: 'Condividi lo schermo',
    stopScreenShare: 'Annulla condivisione schermo',
    leaveMeeting: 'Abbandona il meeting',
    muteMic: 'Disabilita il miscrofono',
    unmuteMic: 'Abilita il miscrofono',
    activateMic: 'Attiva il microfono',
    startCam: 'Abilita videocamera',
    stopCam: 'Disabilita videocamera',
    activateCam: 'Attiva videocamera',
    meetingInfo: 'Info meeting',
    sharedApplications: 'Shared applications',
    menu: 'Menu',
    raiseHand: 'Alzare la mano',

    msgRemoteMute: 'Un consulente ha disabilitato il suo microfono.',
    msgKick: 'Un consulente vi ha rimosso dal meeting.',

    bitrate: 'Bitrate',
    framerate: 'Framerate',
    packetLoss: 'Packet loss',

    // Recording
    recConfirmTitle: 'Acconsentire la registrazione',
    recConfirmMessage: "Vorremmo registrare questo meeting. È d'accordo",

    // Podium Player
    online: 'ONLINE',
    offline: 'OFFLINE',
    playerQuality: 'Qualità',
    playerVideoQuality: 'Qualità video',
    playerQualityAuto: 'Auto',
    posterTextPre: 'La trasmissione inizerà a breve.',
    posterTextPost:
        'La trasmissione è terminata. La ringrazioamo per la visione!',
    overlayTextPlayerError:
        'Il tuo browser o dispositivo non supporta la riproduzione di questa trasmissione.\nSi prega di utilizzare un altro browser o un dispositivo diverso.',
    volumeOff: 'Volume off',
    volumeOn: 'Volume on',

    // Errors

    connectionError: 'La connessione al server non riuscita.',
    serviceNotFound: 'Questo Service non esiste.',
    captchaCode: 'Codice Captcha',

    alcErrorDefault: 'Errore alc',
    alcErrorMeetingDoesNotExist: 'Il meeting è già terminato o non esiste.',
    alcErrorMeetingNeedsCaptchaCode:
        'La prossima richiesta di meeting richiede un codice Captcha.',
    alcErrorMeetingConnectionLost:
        'Connessione al Meeting-Server persa. Vi preghiamo di riprovare',
    alcErrorMeetingFull:
        'È stato superato il limite massimo di partecipanti al meeting.',
    alcErrorWebinarParticipantsFull:
        'Numero massimo di partecipanti raggiunto per questo webinar.',
    alcErrorNeedsRecordingConfirmation:
        'Può partecipare a questa riunione solo se accetta di essere registrato.',
    alcErrorAdviserIdMismatch:
        "L'invito che hai usato non è stato creato per il suo utente.",
    alcErrorExclusiveNotInvited:
        'Non siete invitati a questo meeting esclusivo',
    alcErrorNotAdmitted: 'Richiesta di partecipazione rifiutata',

    webRtcErrorDefault: 'Errore WebRTC',
    webRtcErrorNotFound:
        'Videocamera/microfono non trovati. Verificare che la videocamera o il microfono siano collegati, dopodiché cliccare Aggiorna.',
    webRtcErrorNotAllowed:
        "Consentire l'accesso alla microfono / videocamera nelle impostazioni del browser. Attivi successivamente l'audio o l'audio + video.",
    webRtcErrorDeviceInUse:
        'La videocamera è in uso in un’altra applicazione. Chiudere quell’applicazione o scegliere un’altra videocamera.',
    webRtcErrorFailedAllocateSource:
        'Non è stato possibile accedere alla videocamera.',
    webRtcErrorOverconstrained:
        'La sua videocamera salvata / Il suo microfono salvato non sono stati trovati. Verificare che la videocamera o il microfono siano collegati dopodichè cliccare Aggiorna o selezioni un altro dispositivo.',
    webRtcErrorConnectionTimeout:
        'Timeout nel tentativo di connettersi al meeting server',

    webRtcBrowserNotSupported:
        'Il tuo browser non è supportato. Utilizza una versione recente di Chrome/Firefox o Safari su iOS.',
    webRtcBrowserOutOfDate:
        'La vostra versione di {{browser}} è obsoleta e non è più supportata. Per favore aggiorna il tuo browser.',
    webRtcBrowserNonSafariIos:
        'Sfortunatamente {{browser}} non è supportata su iOS. Per favore utilizzi una versione recente di Safari',
    webRtcBrowserLegacyEdge:
        'State usando Edge Legacy. Questa versione non è aggiornata e non è più supportata. Vi consigliamo di utilizzare la nuova versione Edge che potete scaricare {{link}}.',
    webRtcBrowserIe:
        'Internet Explorer non è supportato. Vi consigliamo di utilizzare la nuova versione di Edge che potete scaricare {{link}}.',

    podiumSwitchError: 'Non è stato possibile concedere il premesso di parlare',

    // Communicator

    yourName: 'Nome',
    yourEmail: 'Indirizzo e-mail',
    invalidEmail: 'Indirizzo e-mail non valido.',
    enterNameAndEmail: "Inserisca il suo nome e l'indirizzo e-mail.",
    continue: 'Avanti',

    meetingTitle: 'Meeting',
    meetingCompleted: 'Meeting terminato',
    yourMeetingId: 'Il proprio meeting ID',
    yourMeeting: 'Meeting attuale',
    enterMeetingId: 'Inserire il meeting ID ricevuto.',
    meetingId: 'Meeting-ID',
    joinMeeting: 'Partecipa',
    meetingNotReady:
        'Il suo appuntamento avrà luogo il {{date}} all {{time}}. Prego attendere o riprova più tardi',
    meetingExpired:
        "L'appuntamento non è più valido. La peghiamo di fissare un nuovo appuntamento.",

    webinarTitle: 'Webinar',
    webinarCompleted: 'Webinar terminato',
    yourWebinarId: 'Il proprio webinar ID',
    yourWebinar: 'Webinar attuale',
    enterWebinarId: 'Inserire il webinar ID ricevuto.',
    webinarId: 'ID Webinar',
    joinWebinar: 'Partecipa',
    webinarNotReady:
        'Il webinar sie svolgerà il {{date}} alle {{time}}. Attendete o riprovate più tardi',
    webinarExpired: 'Il webinar non è più valido',

    consultationTitle: 'Consulenza',
    consultationCompleted: 'Consulenza terminata',
    yourConsultationId: 'Il proprio consulenza ID',
    yourConsultation: 'Consulenza attuale',
    enterConsultationId: 'Inserire il consulenza ID ricevuto.',
    consultationId: 'ID consulenza',
    joinConsultation: 'Partecipa',
    consultationNotReady:
        'La consulenza si svolgerà il {{date}} alle {{time}}. Attenda o riprovi più tardi.',
    consultationExpired: 'Questa consulenza non è più valida.',

    //Webinars
    participants: 'Partecipanti',
    requestSpeakerPermission: 'Richiedere permesso per parlare',
    cancelRequestSpeakerPermission: 'Annulalre richiesta per parlare',
    endSpeakerPermission: 'Terminare il permesso per parlare',
    speakerPermission: 'Permesso per parlare',
    speakerPermissionConfirmation:
        'Vi è stato concesso il permesso per parlare. Scelga il supporto di comunicazione desiderato.',
    leaveWebinar: 'Abbandona il webinar',

    // Admittance
    awaitAdmittanceTitle: 'Richiesta di partecipazione inviata',
    awaitAdmittanceText: 'Entrerai nel meeting non appena sarai ammesso',

    // Contact Form
    contactForm: 'Richiesta di contatto',
    contactFormText: 'Oppure ci invii le sue informazioni di contatto',
    contactFormSuccess:
        'Grazie per averci contattato. Abbiamo ricevuto le sue informazioni di contatto e la contatteremo.',
    send: 'Invia',
    name: 'Nome',
    email: 'E-mail',
    phone: 'Telefono',
    message: 'Messaggio',
    desiredDate: 'Data desiderata',

    // Call Manager
    callManagerStartText: 'Collegati ad un consulente disponibile',
    connect: 'Collegare',
    waitingTitle: 'Stiamo cercando un consulente libero.',
    waitingLine1: 'Attualmente è il numero {{pos}} sulla lista d’attesa.',
    waitingLine2: 'Si prega di pazientare un attimo.',
    connectingTitle: 'Siamo subito a sua disposizione!',
    connectingLine1: 'Un attimo, la stiamo collegando con un consulente.',
    noAdviserTitle: 'Nessun consulente disponibile.',
    noAdviserLine1: 'Al momento nessun cosulente è in linea.',

    // Side Panel
    ['appTitle-' + SA_APPLICATIONS.DOCUMENTS]: 'Documenti',
    ['appTitle-' + SA_APPLICATIONS.CHAT]: 'Chat',
    ['appTitle-' + SA_APPLICATIONS.STREAM_DOCUMENTS]: 'Documenti',
    ['appTitle-' + SA_APPLICATIONS.STREAM_CHAT]: 'Chat',
    ['appTitle-' + SA_APPLICATIONS.STREAM_SURVEYS]: 'Sondaggi',
    ['appTitle-' + AL_APPLICATIONS.PARTICIPANTS]: 'Partecipanti',
};
