import favicon from '../assets/favicons/favicon_gwtelfs.ico';
import React from 'react';
import('../css/themes/Theme_default.scss');

export const Config = {
    serviceUrl: 'https://al5-app11.adiacom.live',
    serviceIdFromUrl: true,
    favicon,
    documentTitle: 'Gemeinde Werke Telfs | Video-Beratung',
    postMeetingIFrameUrl:
        'https://forms.office.com/Pages/ResponsePage.aspx?id=oFmBM4_dr0WWcvqqVLxrJGg5E7wgCS9AlxsIlG09_C1UMUNNMkFESzlRNkc3MUVOMUJDS1hRT0tQNi4u',

    preJoinDisclaimerEnabled: true,
    preJoinDisclaimerCheckbox: true,
    preJoinDisclaimerContent: {
        de: '',
        en: '',
        fr: '',
        it: '',
    },

    preJoinDisclaimerLabel: {
        de: (
            <span>
                Durch den Start der Videoberatung werden personenbezogene Daten
                verarbeitet. Weitere Informationen erhalten Sie in unserer{' '}
                <a
                    href="https://www.gwtelfs.at/datenschutz.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutzinformation
                </a>
                .
            </span>
        ),
        en: (
            <span>
                Durch den Start der Videoberatung werden personenbezogene Daten
                verarbeitet. Weitere Informationen erhalten Sie in unserer{' '}
                <a
                    href="https://www.gwtelfs.at/datenschutz.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutzinformation
                </a>
                .
            </span>
        ),
        fr: (
            <span>
                Durch den Start der Videoberatung werden personenbezogene Daten
                verarbeitet. Weitere Informationen erhalten Sie in unserer{' '}
                <a
                    href="https://www.gwtelfs.at/datenschutz.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutzinformation
                </a>
                .
            </span>
        ),
        it: (
            <span>
                Durch den Start der Videoberatung werden personenbezogene Daten
                verarbeitet. Weitere Informationen erhalten Sie in unserer{' '}
                <a
                    href="https://www.gwtelfs.at/datenschutz.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutzinformation
                </a>
                .
            </span>
        ),
    },
};
