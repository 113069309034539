import React, { useCallback } from 'react';
import MeetingToolbarButton from './MeetingToolbarButton';

import { ReactComponent as UsersIcon } from '../../assets/icons/icon_users.svg';
import { useDispatch, useSelector } from 'react-redux';
import { hideSidePanelMenu, sidePanelShowApplication } from './actions';
import { AL_APPLICATIONS, SA_APPLICATIONS } from '../../constants/constants';
import TranslatedString from '../base/i18n/TranslatedString';
import { getActiveSidePanelApplications } from '../base/util/helpers';
import { Menu } from 'primereact/menu';

function SidePanelTabBar({ includeWrapper, asMenu }) {
    const dispatch = useDispatch();

    const speakerRequestPending = useSelector(
        (state) => state.meetings.speakerRequestPending
    );
    const meetingInfo = useSelector((state) => state.meetings.meetingInfo);
    const sidePanelIFrameReady = useSelector(
        (state) => state.meetings.sidePanelIFrameReady
    );
    const streamChatMessagePending = useSelector(
        (state) => state.meetings.streamChatMessagePending
    );
    const streamSurveyPending = useSelector(
        (state) => state.meetings.streamSurveyPending
    );
    const streamDocumentPending = useSelector(
        (state) => state.meetings.streamDocumentPending
    );
    const chatMessagePending = useSelector(
        (state) => state.meetings.chatMessagePending
    );
    const documentPending = useSelector(
        (state) => state.meetings.documentPending
    );
    const currentActiveSidePanelApplicationId = useSelector(
        (state) => state.meetings.activeSidePanelApplicationId
    );
    const lastActiveSidePanelApplicationId = useSelector(
        (state) => state.meetings.lastActiveSidePanelApplicationId
    );
    const publicServiceInfo = useSelector(
        (state) => state.landingPage.publicServiceInfo
    );

    //const mobileLayout = responsiveMode < RESPONSIVE_MODES.FULL;
    const mobileLayout = false; // todo: is only quick fix for Mobile-View

    const activeSidePanelApplicationId = mobileLayout
        ? lastActiveSidePanelApplicationId
        : currentActiveSidePanelApplicationId;

    const handleTabChange = useCallback(
        (appId) => {
            if (!mobileLayout || appId !== null) {
                dispatch(sidePanelShowApplication(appId));
                if (asMenu) {
                    dispatch(hideSidePanelMenu());
                }
            }
        },
        [dispatch, mobileLayout, asMenu]
    );

    // Stream Chat
    const streamChatTabItem = {
        icon: 'icon-chat',
        menuIcon: 'icon-chat',
        appId: SA_APPLICATIONS.STREAM_CHAT,
        disabled: !sidePanelIFrameReady,
        badge: streamChatMessagePending ? 'N' : '',
    };
    const surveysTabItem = {
        icon: 'icon-presentation',
        menuIcon: 'icon-presentation',
        appId: SA_APPLICATIONS.STREAM_SURVEYS,
        disabled: !sidePanelIFrameReady,
        badge: streamSurveyPending ? 'N' : '',
    };
    const streamDocumentsTabItem = {
        icon: 'icon-share-doc',
        menuIcon: 'icon-share-doc',
        appId: SA_APPLICATIONS.STREAM_DOCUMENTS,
        disabled: !sidePanelIFrameReady,
        badge: streamDocumentPending ? 'N' : '',
    };

    // Old Chat
    const chatTabItem = {
        icon: 'icon-chat',
        menuIcon: 'icon-chat',
        appId: SA_APPLICATIONS.CHAT,
        disabled: !sidePanelIFrameReady,
        badge: chatMessagePending ? 'N' : '',
    };
    const documentsTabItem = {
        icon: 'icon-share-doc',
        menuIcon: 'icon-share-doc',
        appId: SA_APPLICATIONS.DOCUMENTS,
        disabled: !sidePanelIFrameReady,
        badge: documentPending ? 'N' : '',
    };

    const participantsTabItem = {
        icon: <UsersIcon />,
        menuIcon: 'side-panel-icon users',
        appId: AL_APPLICATIONS.PARTICIPANTS,
        badge: speakerRequestPending ? 'N' : '',
    };

    let tabItems = [];

    const activeSidePanelApplications = getActiveSidePanelApplications(
        meetingInfo,
        publicServiceInfo
    );
    activeSidePanelApplications.forEach((application) => {
        switch (application) {
            case SA_APPLICATIONS.STREAM_CHAT: {
                tabItems.push(streamChatTabItem);
                break;
            }
            case SA_APPLICATIONS.STREAM_DOCUMENTS: {
                tabItems.push(streamDocumentsTabItem);
                break;
            }
            case SA_APPLICATIONS.STREAM_SURVEYS: {
                tabItems.push(surveysTabItem);
                break;
            }
            case SA_APPLICATIONS.CHAT: {
                tabItems.push(chatTabItem);
                break;
            }
            case SA_APPLICATIONS.DOCUMENTS: {
                tabItems.push(documentsTabItem);
                break;
            }
            case AL_APPLICATIONS.PARTICIPANTS: {
                tabItems.push(participantsTabItem);
                break;
            }
            default: {
                break;
            }
        }
    });

    if (asMenu) {
        const menuItems = tabItems.map((item) => {
            return {
                icon: item.menuIcon + (item.badge ? ' showBadge' : ''),
                label: <TranslatedString id={'appTitle-' + item.appId} />,
                className:
                    activeSidePanelApplicationId === item.appId
                        ? 'side-panel-menu-active'
                        : '',
                command: () => {
                    handleTabChange(
                        activeSidePanelApplicationId === item.appId
                            ? null
                            : item.appId
                    );
                },
            };
        });
        return <Menu model={menuItems} />;
    }

    const tabButtons = tabItems.map((item, index) => {
        item.toggled = activeSidePanelApplicationId === item.appId;
        item.onToggle = handleTabChange;
        item.label = <TranslatedString id={'appTitle-' + item.appId} />;
        return <MeetingToolbarButton {...item} key={index} />;
    });

    if (includeWrapper) {
        return (
            <div className="side-panel-tab-bar">
                <div className="toolbox-content">
                    <div className="button-group-full-right">{tabButtons}</div>
                </div>
            </div>
        );
    }

    return <React.Fragment>{tabButtons}</React.Fragment>;
}

export default SidePanelTabBar;
