import React from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';

import { RESPONSIVE_MODES } from '../../constants/constants';
import PlayerPanel from './PlayerPanel';
import LivePlayerToolbar from './LivePlayerToolbar';
import SidePanel from '../meetings/SidePanel';
import { LoadingScreen } from '../base/loadingScreen/LoadingScreen';

function LivePlayer(props) {
    const { alcMeetingJoined, chatOpen, responsiveMode } = props;

    if (!alcMeetingJoined) return <LoadingScreen />;

    //const mobileLayout = responsiveMode < RESPONSIVE_MODES.FULL;
    const mobileLayout = false; // todo: is only quick fix for Mobile-View
    const sidePanelOverlayed = responsiveMode < RESPONSIVE_MODES.MEDIUM;
    return (
        <div className="live-player">
            <PlayerPanel chatOpen={chatOpen} mobileLayout={mobileLayout} />

            <div
                className={classNames('side-panel-wrapper', {
                    hidden: !chatOpen && !mobileLayout,
                    mobile: mobileLayout,
                    overlayed: sidePanelOverlayed,
                })}
            >
                <SidePanel />
            </div>

            <div className="toolbar-wrapper">
                <LivePlayerToolbar />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        responsiveMode: state.base.common.responsiveMode,
        alcMeetingJoined: state.meetings.alcMeetingJoined,
        chatOpen: !!state.meetings.activeSidePanelApplicationId,
    };
};

export default connect(mapStateToProps, null)(LivePlayer);
