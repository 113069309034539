import React from 'react';
import { connect } from 'react-redux';
import TranslatedString from '../base/i18n/TranslatedString';
import { Config } from '../../config/Config';
import { getTranslatedString } from '../base/i18n/translations';

export class WaitingPage extends React.Component {
    render() {
        let content;
        let title;

        if (this.props.clientInfo.state.name === 'waiting') {
            title = <TranslatedString id="waitingTitle" />;
            const line1 = getTranslatedString(
                this.props.language,
                'waitingLine1'
            ).replace('{{pos}}', this.props.clientInfo.state.value);
            content = (
                <React.Fragment>
                    {line1}
                    <br />
                    <TranslatedString id="waitingLine2" />
                </React.Fragment>
            );
        } else if (
            this.props.clientInfo.state.name === 'connecting' ||
            this.props.clientInfo.state.name === 'connected'
        ) {
            title = <TranslatedString id="connectingTitle" />;
            content = <TranslatedString id="connectingLine1" />;
        } else {
            title = <TranslatedString id="waitingTitle" />;
        }

        return (
            <div className="msgBox">
                <div className="contentPart">
                    <img
                        className="msgIcon"
                        src={Config.landingPageImage}
                        alt={'icon'}
                    />
                    <div className="msgTitle">{title}</div>
                    {content}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        clientInfo: state.meetings.clientInfo,
        isLoading: state.landingPage.isLoading,
        language: state.base.i18n.language,
    };
};

export default connect(mapStateToProps, undefined)(WaitingPage);
