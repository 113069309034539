import { take, put, all } from 'redux-saga/effects';

import { Config } from '../../config/Config';
import { APP_STATE } from '../../constants/constants';

import {
    ALC_GET_MEETING_INFO_SUCCESS,
    CONFIRM_DISCLAIMER,
    GET_MEETING_INFO,
    INIT_APP,
    LOGIN_SUCCESS,
    PUBLIC_SERVICE_INFO_FAILURE,
    PUBLIC_SERVICE_INFO_SUCCESS,
} from './actionTypes';
import {
    ALC_CLIENT_INFO_UPDATE,
    ALC_ERROR,
    ALC_INITIALIZED,
    WEBRTC_INIT_FAILURE,
    WEBRTC_INIT_SUCCESS,
} from '../meetings/actionTypes';
import {
    requestPublicServiceInfo,
    getMeetingInfo,
    changeAppState,
    newError,
    alcGetMeetingInfo,
    setTopicId,
    login,
    alcParticipantAuth,
    participantInfoUpdate,
    setWebRtcFatalError,
} from './actions';
import {
    webRtcInit,
    alcInit,
    joinMeeting,
    joinMeetingStandalone,
} from '../meetings/actions';
import {
    alcErrorCodeToErrorId,
    webRtcErrorCodeToErrorId,
} from '../base/util/helpers';
import authHelper from '../base/util/authHelper';

function* initAppSaga() {
    while (true) {
        yield take(INIT_APP);
        let serviceId;
        if (Config.serviceIdFromUrl) {
            serviceId = window.location.pathname
                .split('/')
                .filter((item) => item)
                .pop();
        } else {
            // get serviceId from params for testing on deployments with fixed serviceId
            const params = new URLSearchParams(window.location.search);
            serviceId = params.get('serviceId');
            if (!serviceId) {
                serviceId = Config.serviceId;
            }
        }
        if (!serviceId) {
            yield put(changeAppState(APP_STATE.INIT_FAILURE));
            yield put(newError('serviceNotFound'));
        }
        yield put(requestPublicServiceInfo(serviceId));
        const serviceInfoAction = yield take([
            PUBLIC_SERVICE_INFO_SUCCESS,
            PUBLIC_SERVICE_INFO_FAILURE,
        ]);
        if (serviceInfoAction.type === PUBLIC_SERVICE_INFO_FAILURE) {
            yield put(changeAppState(APP_STATE.INIT_FAILURE));
            yield put(newError(serviceInfoAction.errorId));
        } else {
            yield put(
                webRtcInit(
                    serviceInfoAction.publicServiceInfo.webRtcMode,
                    serviceInfoAction.publicServiceInfo.webRtcMaxAudioBitrate,
                    serviceInfoAction.publicServiceInfo.webRtcMaxVideoBitrate,
                    serviceInfoAction.publicServiceInfo.useSimulcast
                )
            );

            const initAction = yield take([
                WEBRTC_INIT_SUCCESS,
                WEBRTC_INIT_FAILURE,
            ]);
            if (initAction.type === WEBRTC_INIT_FAILURE) {
                if (initAction.error.errorCode === 51) {
                    yield put(
                        setWebRtcFatalError(
                            webRtcErrorCodeToErrorId(
                                initAction.error.errorCode
                            ),
                            initAction.error.errorCode,
                            initAction.error.errorData
                        )
                    );
                } else {
                    yield put(changeAppState(APP_STATE.INIT_FAILURE));
                    yield put(
                        newError(
                            webRtcErrorCodeToErrorId(
                                initAction.error.errorCode
                            ),
                            initAction.error.errorCode
                        )
                    );
                    return;
                }
            }

            const serviceId = serviceInfoAction.serviceId;
            yield put(alcInit(serviceId));
            yield take(ALC_INITIALIZED);
            if (Config.disclaimerEnabled) {
                yield put(changeAppState(APP_STATE.DISCLAIMER));
                yield take(CONFIRM_DISCLAIMER);
            }
            const params = new URLSearchParams(window.location.search);
            const topicId = params.get('topicId');
            if (topicId) {
                yield put(setTopicId(topicId));
                yield put(changeAppState(APP_STATE.CM_ENTER_INFO));
            } else {
                const meetingId = params.get('id');
                if (!meetingId) {
                    yield put(changeAppState(APP_STATE.ENTER_MEETING_ID));
                } else {
                    yield put(getMeetingInfo(meetingId));
                    const meetingInfoAction = yield take(
                        ALC_GET_MEETING_INFO_SUCCESS
                    );
                    const params = new URLSearchParams(window.location.search);
                    if (params.get('recorder') === 'true') {
                        yield put(
                            joinMeeting(
                                meetingInfoAction.meetingInfo._id,
                                'recordingClient',
                                undefined,
                                { recorder: true },
                                params.get('auth')
                            )
                        );
                    }
                    // poc
                    else if (
                        params.get('singleApp') &&
                        meetingInfoAction.participantInfo
                    ) {
                        yield put(
                            joinMeetingStandalone(
                                meetingInfoAction.meetingInfo._id
                            )
                        );
                    } else {
                        yield put(
                            changeAppState(APP_STATE.DISPLAY_MEETING_INFO)
                        );
                    }
                }
            }
        }
    }
}

function* getMeetingInfoSaga() {
    while (true) {
        const { meetingId, captchaCode } = yield take(GET_MEETING_INFO);
        yield put(alcGetMeetingInfo(meetingId, captchaCode));
        while (true) {
            const meetingInfoAction = yield take([
                ALC_GET_MEETING_INFO_SUCCESS,
                ALC_ERROR,
            ]);
            if (
                meetingInfoAction.type === ALC_ERROR &&
                meetingInfoAction.context === 'getMeetingInfo'
            ) {
                yield put(changeAppState(APP_STATE.ENTER_MEETING_ID));
                yield put(
                    newError(
                        alcErrorCodeToErrorId(meetingInfoAction.error.errorNo),
                        meetingInfoAction.error.errorNo,
                        meetingInfoAction.error
                    )
                );
                break;
            } else if (
                meetingInfoAction.type === ALC_GET_MEETING_INFO_SUCCESS
            ) {
                const url = new URL(window.location);
                if (!url.searchParams.get('id')) {
                    url.searchParams.set('id', meetingId);
                    window.history.pushState({}, '', url);
                }

                if (
                    (meetingInfoAction.participantInfo &&
                        meetingInfoAction.participantInfo
                            .isAuthenticatedParticipant) ||
                    url.searchParams.get('login') === 'true'
                ) {
                    yield put(login());
                    yield put(changeAppState(APP_STATE.LOADING));
                    yield take(LOGIN_SUCCESS);
                    yield put(alcParticipantAuth(authHelper.getToken()));
                    const clientInfoAction = yield take(ALC_CLIENT_INFO_UPDATE);
                    yield put(
                        participantInfoUpdate(clientInfoAction.clientInfo.info)
                    );
                }

                yield put(changeAppState(APP_STATE.DISPLAY_MEETING_INFO));
                break;
            }
        }
    }
}

export function* landingPageSagas() {
    yield all([initAppSaga(), getMeetingInfoSaga()]);
}
