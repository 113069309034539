import favicon from '../assets/favicons/em.png';
import { MEETING_TYPES } from '../constants/constants';
import React from 'react';
import('../css/themes/Theme_default.scss');

export const Config = {
    serviceUrl: 'https://al5-app10-uc.adialive.ch',
    serviceId: 'energie-und-management',
    documentTitle: 'E&M',

    favicon,

    communicatorType: MEETING_TYPES.WEBINAR,

    preJoinDisclaimerEnabled: true,
    preJoinDisclaimerCheckbox: true,
    preJoinDisclaimerContent: {
        de: '',
        en: '',
        fr: '',
        it: '',
    },

    preJoinDisclaimerLabel: {
        de: (
            <span>
                Ich habe die{' '}
                <a
                    href="https://www.energie-und-management.de/unternehmen/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutzbestimmungen
                </a>{' '}
                gelesen und akzeptiere diese.
            </span>
        ),
        en: (
            <span>
                Ich habe die{' '}
                <a
                    href="https://www.energie-und-management.de/unternehmen/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutzbestimmungen
                </a>{' '}
                gelesen und akzeptiere diese.
            </span>
        ),
        fr: (
            <span>
                Ich habe die{' '}
                <a
                    href="https://www.energie-und-management.de/unternehmen/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutzbestimmungen
                </a>{' '}
                gelesen und akzeptiere diese.
            </span>
        ),
        it: (
            <span>
                Ich habe die{' '}
                <a
                    href="https://www.energie-und-management.de/unternehmen/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutzbestimmungen
                </a>{' '}
                gelesen und akzeptiere diese.
            </span>
        ),
    },
};
