import favicon from '../assets/favicons/favicon_esb.ico';
import('../css/themes/Theme_esb.scss');

export const Config = {
    serviceUrl: 'https://al5-app11.adiacom.live',
    serviceIdFromUrl: true,
    favicon,
    documentTitle: 'Energie Südbayern - ESB | Videoberatung',
    postMeetingIFrameUrl:
        'https://www.research-survey.com/Lighthouse/ESBVIBE/cgi-bin/ciwweb.pl?studyname=ESBVIBE',

    languageOverrides: {
        de: {
            meetingTitle: '',
            consultationTitle: '',
            yourMeeting: 'Ihre Video-Beratung',
            yourConsultation: 'Ihre Video-Beratung',
        },
    },
};
