import * as types from './actionTypes';

export function alcInit(serviceId) {
    return { type: types.ALC_INIT, serviceId };
}

export function alcJoinMeeting(
    meetingId,
    participantName,
    email,
    communicationMode,
    recordingConfirmed,
    modes,
    token
) {
    return {
        type: types.ALC_JOIN_MEETING,
        meetingId,
        participantName,
        email,
        communicationMode,
        recordingConfirmed,
        modes,
        token,
    };
}

export function alcLeaveMeeting() {
    return { type: types.ALC_LEAVE_MEETING };
}

export function alcLeaveMeetingSuccess() {
    return { type: types.ALC_LEAVE_MEETING_SUCCESS };
}

export function alcKicked() {
    return { type: types.ALC_KICKED };
}

export function alcTransferred() {
    return { type: types.ALC_TRANSFERRED };
}

export function alcInitialized() {
    return {
        type: types.ALC_INITIALIZED,
    };
}

export function alcReconnecting() {
    return { type: types.ALC_RECONNECTING };
}

export function alcSessionRestored() {
    return { type: types.ALC_SESSION_RESTORED };
}

export function alcJoinMeetingSuccess(
    joinOptions,
    meetingInfo,
    saAuth,
    responsiveMode
) {
    return {
        type: types.ALC_JOIN_MEETING_SUCCESS,
        joinOptions,
        meetingInfo,
        saAuth,
        responsiveMode,
    };
}

export function alcClientInfoUpdate(clientInfo) {
    return { type: types.ALC_CLIENT_INFO_UPDATE, clientInfo };
}

export function alcMeetingInfoUpdate(meetingInfo) {
    return { type: types.ALC_MEETING_INFO_UPDATE, meetingInfo };
}

export function alcPodiumInfoUpdate(podiumInfo, changes) {
    return { type: types.ALC_PODIUM_INFO_UPDATE, podiumInfo, changes };
}

export function alcStreamInfoUpdate(streamInfo, changes) {
    return { type: types.ALC_STREAM_INFO_UPDATE, streamInfo, changes };
}

export function alcMeetingParticipantsUpdate(meetingParticipants) {
    return { type: types.ALC_MEETING_PARTICIPANTS_UPDATE, meetingParticipants };
}

export function alcAdviserAuth(authToken) {
    return { type: types.ALC_ADVISER_AUTH, authToken };
}

export function alcSetCurrentApp(sharedApplicationId) {
    return { type: types.ALC_SET_CURRENT_APP, sharedApplicationId };
}

export function alcSetEditPermissions(userId, editPermissions) {
    return { type: types.ALC_SET_EDIT_PERMISSIONS, userId, editPermissions };
}

export function alcChangeActiveUser(userId) {
    return { type: types.ALC_CHANGE_ACTIVE_USER, userId };
}

export function alcStartRecording() {
    return { type: types.ALC_START_RECORDING };
}

export function alcStopRecording() {
    return { type: types.ALC_STOP_RECORDING };
}

export function alcMeetingCompleted() {
    return { type: types.ALC_MEETING_COMPLETED };
}

export function alcClose() {
    return { type: types.ALC_CLOSE };
}

export function alcError(context, error) {
    return { type: types.ALC_ERROR, context, error };
}

export function alcUpdateServerTimeDiff(serverTimeDiff) {
    return { type: types.ALC_UPDATE_SERVERTIME_DIFF, serverTimeDiff };
}

export function alcSetHandRaised(clientId, handRaised) {
    return { type: types.ALC_SET_HAND_RAISED, clientId, handRaised };
}

export function alcRequestSpeakerPermission(isRequesting) {
    return { type: types.ALC_REQUEST_SPEAKER_PERMISSION, isRequesting };
}

export function alcChangeSpeakerPermission(permission, joinOptions, saAuth) {
    return {
        type: types.ALC_CHANGE_SPEAKER_PERMISSION,
        permission,
        joinOptions,
        saAuth,
    };
}

export function revokeSpeakerPermission() {
    return { type: types.REVOKE_SPEAKER_PERMISSION };
}

export function alcConfirmRecording(confirmed) {
    return { type: types.ALC_CONFIRM_RECORDING, confirmed };
}

export function alcUpdateUserMode(mode) {
    return { type: types.ALC_UPDATE_USER_MODE, mode };
}

export function alcRequestAdmittance(meetingId, name, isRequesting) {
    return {
        type: types.ALC_REQUEST_ADMITTANCE,
        meetingId,
        name,
        isRequesting,
    };
}

export function alcAdmittanceChanged(admitted) {
    return { type: types.ALC_ADMITTANCE_CHANGED, admitted };
}

export function webRtcInit(
    webRtcMode,
    webRtcMaxAudioBitrate,
    webRtcMaxVideoBitrate,
    useSimulcast
) {
    return {
        type: types.WEBRTC_INIT,
        webRtcMode,
        webRtcMaxAudioBitrate,
        webRtcMaxVideoBitrate,
        useSimulcast: !(useSimulcast === false),
    };
}

export function webRtcInitSuccess(webRtcFlags, communicationMode) {
    return { type: types.WEBRTC_INIT_SUCCESS, webRtcFlags, communicationMode };
}

export function webRtcInitFailure(error) {
    return { type: types.WEBRTC_INIT_FAILURE, error };
}

export function webRtcStartLocalMedia() {
    return { type: types.WEBRTC_START_LOCAL_MEDIA };
}

export function webRtcStartLocalMediaSuccess(
    communicationMode,
    currentSettings
) {
    return {
        type: types.WEBRTC_START_LOCAL_MEDIA_SUCCESS,
        communicationMode,
        currentSettings,
    };
}

export function webRtcStartLocalMediaFailure(error) {
    return { type: types.WEBRTC_START_LOCAL_MEDIA_FAILURE, error };
}

export function webRtcStopLocalMedia() {
    return { type: types.WEBRTC_STOP_LOCAL_MEDIA };
}

export function webRtcJoin(joinOptions, meetingInfo) {
    return { type: types.WEBRTC_JOIN, joinOptions, meetingInfo };
}

export function webRtcJoinSuccess(
    ownVideoView,
    communicationMode,
    currentSettings
) {
    return {
        type: types.WEBRTC_JOIN_SUCCESS,
        ownVideoView,
        communicationMode,
        currentSettings,
    };
}

export function webRtcJoinFailure(error) {
    return { type: types.WEBRTC_JOIN_FAILURE, error };
}

export function webRtcLeave() {
    return { type: types.WEBRTC_LEAVE };
}

export function webRtcUserJoined(videoView) {
    return { type: types.WEBRTC_USERJOINED, videoView };
}

export function webRtcUserLeft(userId, userType) {
    return { type: types.WEBRTC_USERLEFT, userId, userType };
}

export function webRtcSpeakerChanged(userId) {
    return { type: types.WEBRTC_SPEAKER_CHANGED, userId };
}

export function webRtcStartScreenShare() {
    return { type: types.WEBRTC_START_SCREENSHARE };
}

export function webRtcStartScreenShareSuccess(screenShareView) {
    return { type: types.WEBRTC_START_SCREENSHARE_SUCCESS, screenShareView };
}

export function webRtcStopScreenShare() {
    return { type: types.WEBRTC_STOP_SCREENSHARE };
}

export function webRtcStopScreenShareSuccess() {
    return { type: types.WEBRTC_STOP_SCREENSHARE_SUCCESS };
}

export function alcMuteParticipant(userId) {
    return { type: types.ALC_MUTE_PARTICIPANT, userId };
}

export function webRtcClose() {
    return { type: types.WEBRTC_CLOSE };
}

export function webRtcReconnecting() {
    return { type: types.WEBRTC_RECONNECTING };
}

export function webRtcReconnectingSuccess() {
    return { type: types.WEBRTC_RECONNECTING_SUCCESS };
}

export function webRtcReconnectingFailure() {
    return { type: types.WEBRTC_RECONNECTING_FAILURE };
}

export function joinMeeting(meetingId, participantName, email, modes, token) {
    return {
        type: types.JOIN_MEETING,
        meetingId,
        participantName,
        email,
        modes,
        token,
    };
}

export function joinMeetingStandalone(
    meetingId,
    participantName,
    email,
    token
) {
    return {
        type: types.JOIN_MEETING_STANDALONE,
        meetingId,
        participantName,
        email,
        token,
    };
}

export function joinMeetingSuccess() {
    return { type: types.JOIN_MEETING_SUCCESS };
}

export function joinMeetingStandaloneSuccess() {
    return { type: types.JOIN_MEETING_STANDALONE_SUCCESS };
}

export function joinMeetingFailure() {
    return { type: types.JOIN_MEETING_FAILURE };
}

export function leaveMeeting() {
    return { type: types.LEAVE_MEETING };
}

export function joinPodium(meetingId, participantName, email) {
    return { type: types.JOIN_PODIUM, meetingId, participantName, email };
}

export function joinPodiumSuccess() {
    return { type: types.JOIN_PODIUM_SUCCESS };
}

export function joinPodiumFailure() {
    return { type: types.JOIN_MEETING_FAILURE };
}

export function showSharedApplications() {
    return { type: types.SA_SHOW };
}

export function switchOwnSharedApplication(sharedApplicationId) {
    return { type: types.SA_SWITCH_OWN, sharedApplicationId };
}

export function hideSharedApplications() {
    return { type: types.SA_HIDE };
}

export function sharedApplicationsIFrameLoad() {
    return { type: types.SA_IFRAME_LOAD };
}

export function sharedApplicationsIFrameReady() {
    return { type: types.SA_IFRAME_READY };
}

export function sharedApplicationsIFrameInitConfirmed() {
    return { type: types.SA_IFRAME_INIT_CONFIRMED };
}

export function sharedApplicationsIFrameNewRef(iFrameRef) {
    return { type: types.SA_IFRAME_NEW_REF, iFrameRef };
}

export function documentsNewUpload() {
    return { type: types.DOCUMENTS_NEW_UPLOAD };
}

export function chatNewMessage() {
    return { type: types.CHAT_NEW_MESSAGE };
}

export function sidePanelIFrameLoad() {
    return { type: types.SIDE_PANEL_IFRAME_LOAD };
}

export function sidePanelFrameReady() {
    return { type: types.SIDE_PANEL_IFRAME_READY };
}

export function sidePanelIFrameInitConfirmed() {
    return { type: types.SIDE_PANEL_IFRAME_INIT_CONFIRMED };
}

export function sidePanelIFrameNewRef(iFrameRef) {
    return { type: types.SIDE_PANEL_IFRAME_NEW_REF, iFrameRef };
}

export function sidePanelShowApplication(applicationId) {
    return { type: types.SIDE_PANEL_SHOW_APPLICATION, applicationId };
}

export function streamChatNewMessage() {
    return { type: types.STREAM_CHAT_NEW_MESSAGE };
}

export function streamChatNewDocument() {
    return { type: types.STREAM_CHAT_NEW_DOCUMENT };
}

export function streamChatNewSurvey() {
    return { type: types.STREAM_CHAT_NEW_SURVEY };
}

export function showControls() {
    return { type: types.CONTROLS_SHOW };
}

export function hideControls() {
    return { type: types.CONTROLS_HIDE };
}

export function showMenu() {
    return { type: types.MENU_SHOW };
}

export function hideMenu() {
    return { type: types.MENU_HIDE };
}

export function showSidePanelMenu() {
    return { type: types.SIDE_PANEL_MENU_SHOW };
}

export function hideSidePanelMenu() {
    return { type: types.SIDE_PANEL_MENU_HIDE };
}

export function fullscreenNewRef(fullscreenRef) {
    return { type: types.FULLSCREEN_NEW_REF, fullscreenRef };
}

export function fullscreenToggle() {
    return { type: types.FULLSCREEN_TOGGLE };
}

export function fullscreenChange(fullscreenActive) {
    return { type: types.FULLSCREEN_CHANGE, fullscreenActive };
}

export function showSpeakerConfirmation() {
    return { type: types.SPEAKER_CONFIRMATION_SHOW };
}

export function hideSpeakerConfirmation() {
    return { type: types.SPEAKER_CONFIRMATION_HIDE };
}
