import favicon from '../assets/favicons/favicon_vz.ico';
import('../css/themes/Theme_vz.scss');

export const Config = {
    serviceUrl: 'https://ovk-meet-int-app01.vzch.com',
    serviceId: 'VZ-CH-01',
    documentTitle: 'VZ Videoberatung',

    favicon,
    hideTitleResponsiveSmall: false,
    languageOverrides: {
        de: {
            meetingTitle: 'Videobesprechung',
            meetingCompleted: 'Videobesprechung beendet',
            yourMeeting: 'Ihre Videobesprechung',
            yourMeetingId: 'Ihr Zugangscode',
            enterMeetingId: 'Geben Sie Ihren erhaltenen Zugangscode an.',
            meetingId: 'Zugangscode',
            agenda: 'Besprechungsinhalt',
        },
    },
};
