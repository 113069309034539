export const APP_STATE = {
    LOADING: 'LOADING',
    DISCLAIMER: 'DISCLAIMER',
    ENTER_MEETING_ID: 'ENTER_MEETING_ID',
    CONTACT_FORM: 'CONTACT_FORM',
    DISPLAY_MEETING_INFO: 'DISPLAY_MEETING_INFO',
    JOINING_MEETING: 'JOINING_MEETING',
    RECORDING_CONFIRMATION: 'RECORDING_CONFIRMATION',
    IN_MEETING: 'IN_MEETING',
    IN_MEETING_STANDALONE: 'IN_MEETING_STANDALONE',
    PODIUM_STREAM: 'PODIUM_STREAM',
    LEFT_MEETING: 'LEFT_MEETING',
    CM_ENTER_INFO: 'CM_ENTER_INFO',
    CM_CONNECTING: 'CM_CONNECTING',
    AWAIT_ADMITTANCE: 'AWAIT_ADMITTANCE',
    INIT_FAILURE: 'INIT_FAILURE',
};

export const SA_NAMESPACE = 'adiaLive';

export const SA_IDENTIFIERS = {
    SHARED_APPLICATIONS: 'adiaLive.sharedApplications',
    CHAT_DOCUMENTS: 'adiaLive.chat-documents',
    SIDE_PANEL: 'adiaLive.streamChat',
};

export const SA_APPLICATIONS = {
    AGENDA: 'agenda',
    SHOWBOARD: 'showboard',
    MINUTES: 'minutes',
    DOCUMENTS: 'documents',
    CHAT: 'chat',
    STREAM_CHAT: 'streamChat',
    STREAM_SURVEYS: 'streamSurveys',
    STREAM_DOCUMENTS: 'streamDocuments',
};

export const AL_APPLICATIONS = {
    PARTICIPANTS: 'participants',
};

export const KELDANO_APPLICATIONS = {
    SHARED_APPLICATIONS: 'sharedApplications',
};

export const IFRAME_COMMANDS = {
    INIT: 'init',
    INIT_CONFIRMED: 'initConfirmed',
    SHOW: 'show',
    HIDE: 'hide',
    READY: 'ready',
    SHOW_CONFIRMED: 'showConfirmed',
    CHAT_NEW_MESSAGE: 'newMessage',
    DOCUMENTS_NEW_UPLOAD: 'newDocument',
    NEW_SURVEY: 'newSurvey',
    JOIN_MEETING: 'joinMeeting',
    CHECK_COMPLETED: 'checkCompleted',
    CURRENT_APP: 'currentApp',
};

export const IFRAME_INIT_INTERVAL = 500;

export const RESOLUTION_LIST = [
    '480x270',
    '640x360',
    '1280x720',
    '1920x1080',
    '607x1080',
    '720x1280',
];

export const DEFAULT_RESOLUTION = '1280x720';

export const COMMUNICATION_MODES = {
    NONE: 'none',
    AUDIO: 'audio',
    VIDEO: 'video',
    BOTH: 'both',
};

export const WEBRTC_MODES = {
    LIVESWITCH_SFU: 'liveSwitchSfu',
    LIVESWITCH_P2P: 'liveSwitchPeer',
    ADIA_P2P: 'adiaP2P',
};

export const RESPONSIVE_MODES = {
    SMALL: 0,
    MEDIUM: 760,
    FULL: 1260,
};

export const RESPONSIVE_ORIENTATIONS = {
    PORTRAIT: 'PORTRAIT',
    LANDSCAPE: 'LANDSCAPE',
};

export const CONNECTION_QUALITY_THRESHOLDS = {
    GOOD: 80,
    MEDIUM: 60,
};

export const TOOLTIP_TOUCH_DELAY = 500;

export const ERRORS = {
    SERVER_UNREACHABLE_ERROR: {
        internalError: true,
        errorNo: 0,
        message: 'could not reach server',
    },
    PARSING_ERROR: {
        internalError: true,
        errorNo: 1,
        message: 'could not parse response',
    },
    INVALID_ROLE_ERROR: {
        internalError: true,
        errorNo: 2,
        message: 'user must have adviser role',
    },
};

export const MEETING_TYPES = {
    NOT_SET: 0,
    MEETING: 1,
    WEBINAR: 2,
    PHONE_CONSULTING: 4,
    PODIUM: 8,
    OUTLOOK_MEETING: 16,
};

export const RECORDING_CONFIRMATION_STATE = {
    PENDING: 0,
    CONFIRMED: 1,
    DECLINED: 2,
};

export const SPEAKER_PERMISSION_STATE = {
    NONE: 0,
    REQUESTING: 1,
    GRANTED_PREPARING: 2,
    GRANTED: 4,
    DECLINED: 8,
    FAILED: 16,
};

export const SHOWBOARD_TOOLBAR_HEIGHT = 64;

export const VIEW_MODES = {
    MAIN_ONLY: 'mainOnly',
    VIDEO_LIST: 'videoList',
};

export const SCREEN_SHARING_MODES = {
    VIDEO: 'video',
    COMBI: 'combi',
    SCREEN: 'screen',
};

export const AUTH_MIN_TOKEN_VALIDITY = 150; //in seconds
export const AUTH_MAX_INACTIVITY_TIME = 900; //in seconds
export const AUTH_REFRESH_TOKEN_INTERVAL = 60; //in seconds
