import React, { useCallback, useMemo, useRef, useState } from 'react';
import SidePanelTabBar from '../meetings/SidePanelTabBar';
import MeetingToolbarButton from '../meetings/MeetingToolbarButton';
import TranslatedString from '../base/i18n/TranslatedString';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
    RESPONSIVE_MODES,
    SPEAKER_PERMISSION_STATE,
} from '../../constants/constants';
import {
    alcRequestSpeakerPermission,
    fullscreenToggle,
    revokeSpeakerPermission,
} from '../meetings/actions';
import {
    playerSetQualityLevel,
    playerSetVolume,
} from '../../libs/@adiacast/player/src/view/redux/actions';
import { Slider } from 'primereact/slider';
import { fullscreenEnabled } from '../base/util/helpers';
import { Menu } from 'primereact/menu';
import classNames from 'classnames';
import { getTranslatedString } from '../base/i18n/translations';

function LivePlayerToolbar() {
    const dispatch = useDispatch();
    const toolBarRef = useRef(null);
    const menuBtnRef = useRef(null);
    const speakerPermissionState = useSelector(
        (state) =>
            state.meetings.clientInfo &&
            state.meetings.clientInfo.speakerPermissionState
    );
    const fullscreenActive = useSelector(
        (state) => state.meetings.fullscreenActive
    );
    const prevVolume = useSelector((state) => state.player.prevVolume);
    const volumeMuted = useSelector((state) => state.player.volumeMuted);
    const volumeSupported = useSelector(
        (state) => state.player.volumeSupported
    );
    const qualities = useSelector((state) => state.player.qualities);
    const qualityLevel = useSelector((state) => state.player.qualityLevel);
    const resolution = useSelector((state) => state.player.resolution);
    const language = useSelector((state) => state.base.i18n.language);
    const webRtcSupport = useSelector(
        (state) => state.landingPage.webRtcSupport
    );

    const putMainControlsLeft = useSelector(
        (state) => state.base.common.responsiveMode < RESPONSIVE_MODES.MEDIUM
    );
    const [menuVisible, setMenuVisible] = useState(false);

    const handleRaiseHand = useCallback(() => {
        if (speakerPermissionState === SPEAKER_PERMISSION_STATE.GRANTED) {
            dispatch(revokeSpeakerPermission());
        } else if (
            speakerPermissionState === SPEAKER_PERMISSION_STATE.REQUESTING
        ) {
            dispatch(alcRequestSpeakerPermission(false));
        } else if (
            speakerPermissionState !==
            SPEAKER_PERMISSION_STATE.GRANTED_PREPARING
        ) {
            dispatch(alcRequestSpeakerPermission(true));
        }
    }, [dispatch, speakerPermissionState]);

    const raisedHandTextId = useMemo(() => {
        if (speakerPermissionState === SPEAKER_PERMISSION_STATE.GRANTED) {
            return 'endSpeakerPermission';
        } else if (
            speakerPermissionState === SPEAKER_PERMISSION_STATE.REQUESTING
        ) {
            return 'cancelRequestSpeakerPermission';
        } else {
            return 'requestSpeakerPermission';
        }
    }, [speakerPermissionState]);

    const menuRightPos = useMemo(() => {
        if (!menuVisible || !menuBtnRef.current || !toolBarRef.current) {
            return 0;
        }

        const menuButtonRightPos =
            menuBtnRef.current.getBoundingClientRect().right;
        const containerWidth = toolBarRef.current.getBoundingClientRect().width;

        return Math.min(
            containerWidth - menuButtonRightPos - 100,
            containerWidth - 205
        );
    }, [menuVisible, menuBtnRef, toolBarRef]);

    const handleToggleMute = useCallback(() => {
        if (volumeMuted) {
            dispatch(playerSetVolume(prevVolume < 0.1 ? 0.1 : prevVolume));
        } else {
            dispatch(playerSetVolume(0));
        }
    }, [prevVolume, volumeMuted, dispatch]);

    const volumeBtn = (
        <MeetingToolbarButton
            label={
                <TranslatedString id={volumeMuted ? 'volumeOn' : 'volumeOff'} />
            }
            icon={
                <FontAwesomeIcon
                    icon={volumeMuted ? faVolumeMute : faVolumeUp}
                />
            }
            toggled={false}
            onToggle={handleToggleMute}
        />
    );

    const menuBtn = (
        <MeetingToolbarButton
            label={<TranslatedString id="menu" />}
            icon="icon-thumb-menu"
            toggled={menuVisible}
            btnRef={menuBtnRef}
            onToggle={() => setMenuVisible(!menuVisible)}
        />
    );

    const raiseHandBtn = !webRtcSupport ? null : (
        <MeetingToolbarButton
            label={<TranslatedString id={raisedHandTextId} />}
            icon="icon-raised-hand"
            toggled={
                speakerPermissionState === SPEAKER_PERMISSION_STATE.GRANTED ||
                speakerPermissionState === SPEAKER_PERMISSION_STATE.REQUESTING
            }
            onToggle={handleRaiseHand}
        />
    );

    return (
        <div className="meeting-toolbar visible" ref={toolBarRef}>
            <div
                className="toolbox-menu-container"
                style={{ right: menuRightPos }}
            >
                {menuVisible && (
                    <ToolbarMenu
                        fullscreenActive={fullscreenActive}
                        qualities={qualities}
                        qualityLevel={qualityLevel}
                        resolution={resolution}
                        language={language}
                        onFullscreenToggle={() => {
                            dispatch(fullscreenToggle());
                            setMenuVisible(false);
                        }}
                        onQualityChange={(level) => {
                            dispatch(playerSetQualityLevel(level));
                        }}
                    />
                )}
            </div>

            <div className="toolbox-content">
                {putMainControlsLeft ? (
                    <React.Fragment>
                        <div className="button-group-left">
                            {volumeSupported && volumeBtn}
                            {menuBtn}
                            {raiseHandBtn}
                        </div>
                        <div className="button-group-center" />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="button-group-left" />
                        <div className="button-group-center">
                            {volumeSupported && (
                                <React.Fragment>
                                    {volumeBtn}
                                    <VolumeSlider />
                                </React.Fragment>
                            )}
                            {menuBtn}
                            {raiseHandBtn}
                        </div>
                    </React.Fragment>
                )}

                <div className="button-group-right">
                    <SidePanelTabBar />
                </div>
            </div>
        </div>
    );
}

function VolumeSlider() {
    const dispatch = useDispatch();
    const volume = useSelector((state) => state.player.volume);

    const handleVolumeChange = useCallback(
        (e) => {
            if (e.value !== volume * 100) {
                dispatch(playerSetVolume(e.value > 0 ? e.value / 100 : 0));
            }
        },
        [volume, dispatch]
    );

    return (
        <div className="volume-slider-wrapper">
            <Slider
                className="volume-slider"
                value={volume * 100}
                min={0}
                max={100}
                step={5}
                onChange={handleVolumeChange}
            />
        </div>
    );
}

const ToolbarMenu = React.memo((props) => {
    const {
        fullscreenActive,
        qualities,
        resolution,
        qualityLevel,
        language,
        onFullscreenToggle,
        onQualityChange,
    } = props;

    const [activeMenu, setActiveMenu] = useState('main');

    const handleQualityChange = (level) => () => {
        if (qualityLevel === level) return;
        onQualityChange(level);
    };

    const menuItems = [];
    if (activeMenu === 'main') {
        if (fullscreenEnabled) {
            if (fullscreenActive) {
                menuItems.push({
                    label: <TranslatedString id="stopFullscreen" />,
                    icon: 'pi pi-fw pi-md-fullscreen-exit',
                    command: () => onFullscreenToggle(),
                });
            } else {
                menuItems.push({
                    label: <TranslatedString id="startFullscreen" />,
                    icon: 'pi pi-fw pi-md-fullscreen',
                    command: () => onFullscreenToggle(),
                });
            }
        }

        menuItems.push({
            label: <TranslatedString id="playerQuality" />,
            icon: 'pi pi-md-settings',
            command: () => setActiveMenu('quality'),
        });
    } else if (activeMenu === 'quality') {
        menuItems.push(
            {
                label: <TranslatedString id="playerVideoQuality" />,
                icon: 'pi pi-md-chevron-left',
                command: () => setActiveMenu('main'),
            },
            {
                separator: true,
            },
            {
                className: classNames('menu-button quality', {
                    selected: qualityLevel === -1,
                    active: qualityLevel === -1,
                }),
                label: `${getTranslatedString(language, 'playerQualityAuto')}${
                    qualityLevel === -1 && resolution && resolution.label
                        ? ` (${resolution.label})`
                        : ''
                }`,
                command: handleQualityChange(-1),
            }
        );

        if (qualities.length > 1) {
            qualities.forEach((quality) => {
                menuItems.push({
                    className: classNames('menu-button quality', {
                        selected: qualityLevel === quality.level,
                        active:
                            qualityLevel === quality.level &&
                            resolution.level === quality.level,
                    }),
                    label: quality.label,
                    command: handleQualityChange(quality.level),
                });
            });
        }
    }

    return <Menu className="toolbar-menu" model={menuItems} />;
});

export default LivePlayerToolbar;
export { VolumeSlider };
