import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { RESPONSIVE_MODES, WEBRTC_MODES } from '../../constants/constants';

export function RecordingIndicator(props) {
    if (props.isRecording) {
        return (
            <div
                className={classNames('recording-indicator', 'blink', {
                    'controls-visible':
                        props.controlsVisible &&
                        props.responsiveMode > RESPONSIVE_MODES.MEDIUM,
                })}
            >
                <i className="icon-rec" />
            </div>
        );
    } else return null;
}

const mapStateToProps = (state) => {
    let isRecording = false;
    if (
        state.landingPage.publicServiceInfo.webRtcMode ===
            WEBRTC_MODES.LIVESWITCH_P2P ||
        state.landingPage.publicServiceInfo.webRtcMode === WEBRTC_MODES.ADIA_P2P
    ) {
        state.meetings.meetingParticipants.forEach((participant) => {
            if (participant.isRecording) {
                isRecording = true;
            }
        });
    } else {
        isRecording = state.meetings.meetingInfo
            ? state.meetings.meetingInfo.isRecording
            : false;
    }

    return {
        isRecording,
        controlsVisible: state.meetings.controlsVisible,
        responsiveMode: state.base.common.responsiveMode,
    };
};

export default connect(mapStateToProps, undefined)(RecordingIndicator);
