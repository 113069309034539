import {
    AL_APPLICATIONS,
    SA_APPLICATIONS,
} from '../../../../constants/constants';

export const french = {
    // General
    language: 'Langue',
    de: 'Allemand',
    en: 'Anglais',
    fr: 'Français',
    it: 'Italien',

    save: 'Sauvegarder',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    decline: 'Refuser',
    close: 'Fermer',
    refresh: 'Actualiser',
    back: 'Retour',
    signOut: 'Se déconnecter',

    reconnectingHeader: 'Connexion perdue',
    reconnectingMessage:
        'Connexion au serveur est perdue. La connexion sera rétablie ...',

    // Device Settings

    deviceSettings: 'Paramètres',
    cam: 'Caméra',
    mic: 'Microphone',
    resolution: 'Résolution',
    comModeNone: 'Seulement le chat',
    comModeAudio: 'Audio',
    comModeBoth: 'Audio + Vidéo',

    // Meeting

    agenda: 'Agenda',
    showboard: 'Showboard',
    minutes: 'Protocole',
    documents: 'Documents',
    chat: 'Chat',
    surveys: 'Sondages',
    show: 'Afficher',

    startFullscreen: 'Démarrer le mode plein écran',
    stopFullscreen: 'Quitter le mode plein écran',
    startScreenShare: "Partager l'écran",
    stopScreenShare: "Arrêter de partager l'écran",
    leaveMeeting: 'Quitter le meeting',
    muteMic: 'Rendre le microphone muet',
    unmuteMic: 'Rétablir le son du microphone',
    activateMic: 'Activer le microphone',
    startCam: 'Allumer la caméra',
    stopCam: 'Éteindre la caméra',
    activateCam: 'Activer la caméra',
    meetingInfo: 'Informations du meeting',
    sharedApplications: 'Shared Applications',
    menu: 'Menu',
    raiseHand: 'Lever la main',

    msgRemoteMute: 'Vous avez été mis en sourdine par un conseiller.',
    msgKick: 'Vous avez été retiré du meeting par un conseiller.',

    bitrate: 'Bitrate',
    framerate: 'Framerate',
    packetLoss: 'Packet loss',

    // Recording
    recConfirmTitle: "Confirmer l'enregistrement",
    recConfirmMessage:
        "Nous aimerions enregistrer cette réunion. Êtes-vous d'accord?",

    // Podium Player
    online: 'ONLINE',
    offline: 'OFFLINE',
    playerQuality: 'Qualité',
    playerVideoQuality: 'Qualité de vidéo',
    playerQualityAuto: 'Auto',
    posterTextPre: 'La diffusion débutera bientôt.',
    posterTextPost: "La diffusion est terminée. Merci d'avoir regardé!",
    overlayTextPlayerError:
        'Votre navigateur ou votre appareil ne prend pas en charge ce flux vidéo.\nVeuillez utiliser un autre navigateur ou un autre appareil.',
    volumeOff: 'Volume off',
    volumeOn: 'Volume on',

    // Errors

    connectionError: 'La connexion au serveur a échoué.',
    serviceNotFound: "Ce service n'existe pas.",
    captchaCode: 'Code captcha',

    alcErrorDefault: 'Erreur alc',
    alcErrorMeetingDoesNotExist: "Le meeting est déjà terminé ou n'existe pas.",
    alcErrorMeetingNeedsCaptchaCode:
        "La prochaine demande de réunion nécessite la saisie d'un code captcha.",
    alcErrorMeetingConnectionLost:
        'Connexion au serveur de meeting perdue. Veuillez réessayer.',
    alcErrorMeetingFull:
        'Le nombre maximum de participants au meeting a été dépassé.',
    alcErrorWebinarParticipantsFull:
        'Nombre maximum de participants est atteint pour ce webinaire.',
    alcErrorNeedsRecordingConfirmation:
        "Vous ne pouvez rejoindre cette réunion que si vous acceptez l'enregistrement.",
    alcErrorAdviserIdMismatch:
        "L'invitation que vous avez utilisée n'a pas été créée pour votre utilisateur.",
    alcErrorExclusiveNotInvited:
        "Vous n'avez pas été invité à rejoindre ce meeting exclusif",
    alcErrorNotAdmitted: "Vous n'avez pas été admis dans ce meeting",

    webRtcErrorDefault: 'Erreur WebRTC',
    webRtcErrorNotFound:
        'Aucune caméra / microphone détecté. Veuillez vérifier si votre caméra / microphone est branché et cliquez sur Actualiser.',
    webRtcErrorNotAllowed:
        "Veuillez autoriser l'accès à votre caméra / microphone dans les paramètres du navigateur. Activez ensuite Audio / Audio + Vidéo.",
    webRtcErrorDeviceInUse:
        'La caméra est utilisée par une autre application. Fermer cette application ou choisir une autre caméra.',
    webRtcErrorFailedAllocateSource: "Votre caméra n'est pas accessible.",
    webRtcErrorOverconstrained:
        "Votre caméra / microphone enregistré n'est pas disponible. Veuillez vérifier que votre caméra / microphone est branché et cliquer sur Actualiser ou sélectionner un autre périphérique.",
    webRtcErrorConnectionTimeout:
        "Délai d'attente lors de la connexion au serveur de meeting",

    webRtcBrowserNotSupported:
        "Votre navigateur n'est pas supporté. Veuillez utiliser une version récente de Chrome / Firefox ou Safari sur iOS.",
    webRtcBrowserOutOfDate:
        "Votre version de {{browser}} est obsolète et n'est plus prise en charge. Veuillez mettre à jour votre navigateur s'il vous plait.",
    webRtcBrowserNonSafariIos:
        "Malheureusement, {{browser}} n'est pas pris en charge sur iOS. Veuillez essayer une version récente de Safari.",
    webRtcBrowserLegacyEdge:
        "Vous utilisez Edge Legacy. Cette version est obsolète et n'est plus prise en charge. Nous vous suggérons d'utiliser le nouveau Edge que vous pouvez télécharger {{link}}.",
    webRtcBrowserIe:
        "Internet Explorer n'est pas pris en charge. Nous vous suggérons d'utiliser le nouveau navigateur Edge que vous pouvez télécharger {{link}}.",

    podiumSwitchError: "La permission de parler n'a pas pu être accordée.",

    // Communicator

    yourName: 'Votre nom',
    yourEmail: 'Votre adresse e-mail',
    invalidEmail: 'Adresse e-mail invalide.',
    enterNameAndEmail: "Entrez le nom et l'adresse e-mail.",
    continue: 'Suivant',

    meetingTitle: 'Meeting',
    meetingCompleted: 'Meeting terminé',
    yourMeetingId: 'Votre ID de meeting',
    yourMeeting: 'Votre meeting ',
    enterMeetingId: "Indiquez l'ID de meeting reçu.",
    meetingId: 'ID de meeting',
    joinMeeting: 'Participer maintenant',
    meetingNotReady:
        'Votre rendez-vous aura lieu le {{date}} à {{time}}. Veuillez patienter ou réessayer plus tard.',
    meetingExpired:
        "Le rendez-vous n'est plus valide. Veuillez prendre un nouveau rendez-vous.",

    webinarTitle: 'Webinaire',
    webinarCompleted: 'Webinaire terminé',
    yourWebinarId: 'Votre ID de webinaire',
    yourWebinar: 'Votre webinaire ',
    enterWebinarId: "Indiquez l'ID de webinaire reçu.",
    webinarId: 'ID de webinaire',
    joinWebinar: 'Joindre le webinaire',
    webinarNotReady:
        'Le webinaire aura lieu le {{date}} à {{time}}. Veuillez patienter ou réessayer plus tard.',
    webinarExpired: "Ce webinaire n'est plus valide.",

    consultationTitle: 'Consultation',
    consultationCompleted: 'Consultation terminée',
    yourConsultationId: 'Votre ID de consultation',
    yourConsultation: 'Votre consultation ',
    enterConsultationId: "Indiquez l'ID de consultation reçu.",
    consultationId: 'ID de consultation',
    joinConsultation: 'Participer maintenant',
    consultationNotReady:
        'La consultation aura lieu le {{date}} à {{time}}. Veuillez patienter ou réessayer plus tard.',
    consultationExpired: "Cette consultation n'est plus valide.",

    // Webinars
    participants: 'Participants',
    requestSpeakerPermission: 'Demander la permission de parler',
    cancelRequestSpeakerPermission:
        'Annuler la demande de permission de parler',
    endSpeakerPermission: 'Mettre fin à la permission de parler',
    speakerPermission: 'Permission de parler',
    speakerPermissionConfirmation:
        'Vous avez été autorisé à parler. Veuillez choisir la forme de communication souhaitée.',
    leaveWebinar: 'Quitter le webinaire',

    // Admittance
    awaitAdmittanceTitle: "Demande d'admission envoyée",
    awaitAdmittanceText:
        'Vous pourrez rejoindre ce meeting lorsque vous y serez autorisé',

    // Contact Form
    contactForm: 'Formulaire de contact',
    contactFormText: 'Ou envoyez-nous vos coordonnées',
    contactFormSuccess:
        'Merci de nous avoir contacté. Nous avons reçu vos coordonnées et nous vous contacterons.',
    send: 'Envoyer',
    name: 'Nom',
    email: 'E-mail',
    phone: 'Téléphone',
    message: 'Message',
    desiredDate: 'Date désirée',

    // Call Manager
    callManagerStartText: 'Faîtes-vous conseiller maintenant',
    connect: 'Connecter',
    waitingTitle: 'On cherche un conseiller libre',
    waitingLine1: 'Vous êtes sur position {{pos}} dans la queue d’attente.',
    waitingLine2: 'Merci d’être patient pour un instant.',
    connectingTitle: 'Et c’est parti!',
    connectingLine1:
        'Un moment, un conseiller est à votre disposition dans un instant.',
    noAdviserTitle: 'Aucun conseiller est disponible.',
    noAdviserLine1: 'Pour le moment, aucun conseiller es en ligne.',

    // Side Panel
    ['appTitle-' + SA_APPLICATIONS.DOCUMENTS]: 'Documents',
    ['appTitle-' + SA_APPLICATIONS.CHAT]: 'Chat',
    ['appTitle-' + SA_APPLICATIONS.STREAM_DOCUMENTS]: 'Documents',
    ['appTitle-' + SA_APPLICATIONS.STREAM_CHAT]: 'Chat',
    ['appTitle-' + SA_APPLICATIONS.STREAM_SURVEYS]: 'Sondages',
    ['appTitle-' + AL_APPLICATIONS.PARTICIPANTS]: 'Participants',
};
