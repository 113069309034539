import favicon from '../assets/favicons/favicon_swisscom.ico';
import { MEETING_TYPES } from '../constants/constants';
import('../css/themes/Theme_swisscom-academy.scss');

export const Config = {
    serviceUrl: 'https://al5-app10.adialive.ch',
    serviceId: 'Swisscom-Academy',
    documentTitle: 'Swisscom Academy',
    favicon,
    communicatorType: MEETING_TYPES.WEBINAR,

    languageOverrides: {
        fr: {
            webinarTitle: 'Cours en ligne',
            webinarCompleted: 'Cours terminé',
            yourWebinarId: 'Votre ID de cours',
            yourWebinar: 'Votre cours en ligne',
            enterWebinarId: 'Veuillez entrer votre ID de cours',
            webinarId: 'ID de cours',
            joinWebinar: 'Joindre le cours',
            webinarNotReady:
                'Le cours aura lieu le {{date}} à {{time}}. Veuillez patienter ou réessayer plus tard.',
            webinarExpired: "Ce cours n'est plus valide.",
        },
    },
};
