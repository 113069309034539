import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
    sharedApplicationsIFrameNewRef,
    sharedApplicationsIFrameLoad,
    switchOwnSharedApplication,
    leaveMeeting,
} from './actions';
import {
    KELDANO_APPLICATIONS,
    MEETING_TYPES,
    RESPONSIVE_MODES,
    RESPONSIVE_ORIENTATIONS,
    SA_APPLICATIONS,
} from '../../constants/constants';
import { getTranslatedString } from '../base/i18n/translations';
import { TabMenu } from 'primereact/tabmenu';
import { LoadingScreen } from '../base/loadingScreen/LoadingScreen';
import { Message } from 'primereact/message';
import { getActiveSharedApplications } from '../base/util/helpers';

export class SharedApplicationsPanel extends Component {
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
        this.iFrameRef = React.createRef();

        this.state = {
            reducedMenu: false,
        };

        this.handleResize = this.handleResize.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    componentDidMount() {
        this.props.sharedApplicationsIFrameNewRef(this.iFrameRef);

        /* content sizing */
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.sharedApplicationsShown !==
                this.props.sharedApplicationsShown ||
            prevProps.controlsVisible !== this.props.controlsVisible ||
            prevProps.saIFrameReady !== this.props.saIFrameReady ||
            prevProps.activeSidePanelApplicationId !==
                this.props.activeSidePanelApplicationId ||
            prevProps.meetingInfo.additionalMetadata !==
                this.props.meetingInfo.additionalMetadata
        ) {
            this.handleResize();
        }
    }

    componentWillUnmount() {
        this.props.sharedApplicationsIFrameNewRef(null);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        if (this.props.sharedApplicationsShown) {
            const mainContainerRect =
                this.containerRef.current.getBoundingClientRect();
            const reducedMenu = mainContainerRect.width < 380;
            this.setState({
                reducedMenu,
            });
        }
    }

    handleTabChange(e) {
        this.props.switchOwnSharedApplication(e.value.appId);
    }

    render() {
        let sharedApplicationsUrl =
            this.props.applicationsUrl +
            '/' +
            KELDANO_APPLICATIONS.SHARED_APPLICATIONS;

        if (!this.props.saAuthToken) {
            if (sharedApplicationsUrl.includes('?')) {
                sharedApplicationsUrl +=
                    '&nokeycloak=true&name=' + this.props.participantName;
            } else {
                sharedApplicationsUrl +=
                    '?nokeycloak=true&name=' + this.props.participantName;
            }
        } else {
            if (sharedApplicationsUrl.includes('?')) {
                sharedApplicationsUrl += '&jws=' + this.props.saAuthToken;
            } else {
                sharedApplicationsUrl += '?jws=' + this.props.saAuthToken;
            }
        }

        if (!this.props.standalone) {
            return this.renderStandardView(sharedApplicationsUrl);
        } else {
            return this.renderStandaloneView(sharedApplicationsUrl);
        }
    }

    renderStandardView(sharedApplicationsUrl) {
        let mobileVideosActive = false;
        if (
            this.props.sharedApplicationsShown &&
            this.props.responsiveMode === RESPONSIVE_MODES.SMALL
        ) {
            if (this.props.clientInfo.deviceInfo.video) {
                mobileVideosActive = true;
            } else {
                const userWithVideo = this.props.meetingParticipants.find(
                    (el) => el.deviceInfo.video
                );
                if (userWithVideo) {
                    mobileVideosActive = true;
                }
            }
        }

        return (
            <div
                className={classNames('sa-panel-container', {
                    hidden:
                        !this.props.sharedApplicationsShown ||
                        !this.props.saIFrameReady,
                    controlsVisible: this.props.controlsVisible,
                    standalone: this.props.standalone,
                    mobileVideosActive: mobileVideosActive,
                    landscape:
                        this.props.responsiveOrientation ===
                        RESPONSIVE_ORIENTATIONS.LANDSCAPE,
                    portrait:
                        this.props.responsiveOrientation ===
                        RESPONSIVE_ORIENTATIONS.PORTRAIT,
                })}
                ref={this.containerRef}
            >
                <div className="sa-panel">
                    <div className="sa-panel-body">
                        <iframe
                            className="shared-applications-iframe"
                            title="Shared Applications"
                            src={sharedApplicationsUrl}
                            ref={this.iFrameRef}
                            onLoad={this.props.sharedApplicationsIFrameLoad}
                            allow={'display-capture'}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderStandaloneView(sharedApplicationsUrl) {
        const documentsItem = {
            label: !this.state.reducedMenu
                ? getTranslatedString(this.props.language, 'documents')
                : '',
            icon: 'pi-md-insert-drive-file',
            appId:
                this.props.meetingInfo.type !== MEETING_TYPES.PODIUM
                    ? SA_APPLICATIONS.DOCUMENTS
                    : SA_APPLICATIONS.STREAM_DOCUMENTS,
        };

        const chatItem = {
            label: !this.state.reducedMenu
                ? getTranslatedString(this.props.language, 'chat')
                : '',
            icon: 'pi-md-speaker-notes',
            appId:
                this.props.meetingInfo.type !== MEETING_TYPES.PODIUM
                    ? SA_APPLICATIONS.CHAT
                    : SA_APPLICATIONS.STREAM_CHAT,
        };

        const surveysItem = {
            label: !this.state.reducedMenu
                ? getTranslatedString(this.props.language, 'surveys')
                : '',
            icon: 'pi-md-poll',
            appId: SA_APPLICATIONS.STREAM_SURVEYS,
        };

        const items = [];
        const activeSharedApplications = getActiveSharedApplications(
            this.props.meetingInfo,
            this.props.publicServiceInfo,
            this.props.standalone
        );
        activeSharedApplications.forEach((application) => {
            switch (application) {
                case SA_APPLICATIONS.DOCUMENTS:
                case SA_APPLICATIONS.STREAM_DOCUMENTS: {
                    items.push(documentsItem);
                    break;
                }
                case SA_APPLICATIONS.CHAT:
                case SA_APPLICATIONS.STREAM_CHAT: {
                    items.push(chatItem);
                    break;
                }
                case SA_APPLICATIONS.STREAM_SURVEYS: {
                    items.push(surveysItem);
                    break;
                }
                default: {
                    break;
                }
            }
        });

        const currentShownApp = this.props.ownApplicationId;
        let activeItem = null;

        switch (currentShownApp) {
            case SA_APPLICATIONS.DOCUMENTS:
            case SA_APPLICATIONS.STREAM_DOCUMENTS: {
                activeItem = documentsItem;
                break;
            }
            case SA_APPLICATIONS.CHAT:
            case SA_APPLICATIONS.STREAM_CHAT: {
                activeItem = chatItem;
                break;
            }
            case SA_APPLICATIONS.STREAM_SURVEYS: {
                activeItem = surveysItem;
                break;
            }
            default: {
                activeItem = chatItem;
                break;
            }
        }

        return (
            <React.Fragment>
                <div
                    className={classNames('sa-panel-container', {
                        hidden: !this.props.saIFrameReady || items.length === 0,
                        standalone: this.props.standalone,
                    })}
                    ref={this.containerRef}
                >
                    <div className="sa-panel">
                        <div className="sa-panel-header" ref={this.headerRef}>
                            <TabMenu
                                model={items}
                                activeItem={activeItem}
                                onTabChange={this.handleTabChange}
                            />
                            <div className="sa-panel-controls">
                                {this.props.standalone && (
                                    <i
                                        className="pi-md-exit-to-app clickable"
                                        onClick={this.props.leaveMeeting}
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            className={classNames('sa-panel-body', {
                                standalone: this.props.standalone,
                            })}
                        >
                            <iframe
                                className="shared-applications-iframe"
                                title="Shared Applications "
                                src={sharedApplicationsUrl}
                                ref={this.iFrameRef}
                                onLoad={this.props.sharedApplicationsIFrameLoad}
                                allow={'display-capture'}
                            />
                        </div>
                    </div>
                </div>
                {!this.props.saIFrameReady && items.length > 0 && (
                    <LoadingScreen />
                )}
                {items.length === 0 && (
                    <div className="p-col-12">
                        <Message
                            severity="warn"
                            text={'NO ACTIVE APPLICATION'}
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedApplicationsShown: state.meetings.sharedApplicationsShown,
        ownApplicationId: state.meetings.ownApplicationId,
        applicationsUrl:
            state.landingPage.publicServiceInfo.meetingsSettings
                .applicationsUrl,
        saAuthToken: state.meetings.saAuthToken,
        controlsVisible: state.meetings.controlsVisible,
        saIFrameReady: state.meetings.saIFrameReady,
        activeSidePanelApplicationId:
            state.meetings.activeSidePanelApplicationId,
        meetingInfo: state.meetings.meetingInfo,
        participantName: state.meetings.clientInfo
            ? state.meetings.clientInfo.info.name
            : '',
        disableCommunicatorFileTransfer:
            state.landingPage.publicServiceInfo.meetingsSettings
                .disableCommunicatorFileTransfer,
        meetingParticipants: state.meetings.meetingParticipants,
        clientInfo: state.meetings.clientInfo,
        language: state.base.i18n.language,
        responsiveMode: state.base.common.responsiveMode,
        responsiveOrientation: state.base.common.responsiveOrientation,
        publicServiceInfo: state.landingPage.publicServiceInfo,
    };
};

const mapDispatchToProps = {
    sharedApplicationsIFrameNewRef,
    sharedApplicationsIFrameLoad,
    switchOwnSharedApplication,
    leaveMeeting,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SharedApplicationsPanel);
