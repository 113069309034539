import { english } from './languages/english';
import { german } from './languages/german';
import { french } from './languages/french';
import { italian } from './languages/italian';

import { Config } from '../../../config/Config';

export const languages = {
    GERMAN: 'de',
    ENGLISH: 'en',
    FRENCH: 'fr',
    ITALIAN: 'it',
};

const translations = {
    [languages.ENGLISH]: Config.languageOverrides
        ? Object.assign(english, Config.languageOverrides.en)
        : english,
    [languages.GERMAN]: Config.languageOverrides
        ? Object.assign(german, Config.languageOverrides.de)
        : german,
    [languages.FRENCH]: Config.languageOverrides
        ? Object.assign(french, Config.languageOverrides.fr)
        : french,
    [languages.ITALIAN]: Config.languageOverrides
        ? Object.assign(italian, Config.languageOverrides.it)
        : italian,
};

export function hasLanguage(language) {
    return Object.values(languages).includes(language);
}

export function getTranslatedString(language, id) {
    return translations[language][id];
}
