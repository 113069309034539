import React from 'react';

export const disclaimerContent = {
    en: '',
    de: (
        <div className="p-col-12 p-xl-6 p-lg-8">
            <h4>Persönliche und sichere Beratung im Netz</h4>
            <h2>Online-Beratung mit erweiterten Möglichkeiten</h2>
            <div>
                <p>
                    Als zukunftsorientierte Bank bieten wir unseren Kundinnen
                    und Kunden eine optimierte Online-Beratung an, welche eine
                    noch bessere und persönlichere Beratung ohne Ihren Besuch
                    vor Ort ermöglicht. Mit unserer erweiterten Online-Beratung
                    können Sie unter anderem Dateien und Dokumente teilen, PDF
                    Files zusammen mit Ihrem Berater oder Ihrer Beraterin
                    bearbeiten oder den Bildschirm gegenseitig freigeben. Auf
                    Ihren Wunsch ist auch ein Gespräch über Videotelefonie
                    möglich. Nutzen Sie unsere Online-Beratung und melden Sie
                    sich bei Ihrer Beraterin oder Ihrem Berater oder unter:
                </p>
                <p>
                    Privatkunden{' '}
                    <span className="text-highlighted">+41 61 925 94 94</span>
                </p>
                <p>
                    Geschäftskunden{' '}
                    <span className="text-highlighted">+41 61 925 96 96</span>
                </p>
                <span style={{ lineHeight: '1.4em' }}>
                    Die Online-Beratung im Überblick
                </span>
                <ul>
                    <li>
                        Die Verbindung erfolgt verschlüsselt auf einem in der
                        Schweiz befindlichen Server
                    </li>
                    <li>
                        Es müssen keine Programme auf Ihrem Gerät installiert
                        werden
                    </li>
                    <li>
                        Für den Aufbau der Sitzung wird Ihnen ein
                        Sitzungsschlüssel (Code) von Ihrem Berater telefonisch
                        mitgeteilt
                    </li>
                    <li>
                        Der Berater sieht Ihren Bildschirm/Applikation/Tab nur,
                        wenn Sie diesen freischalten und kann die Kontrolle
                        darüber nicht übernehmen
                    </li>
                    <li>
                        Es ist nicht möglich, Daten von Ihrem Gerät zu entfernen
                    </li>
                    <li>Wir haben keinen Zugriff auf ihr Gerät</li>
                    <li>Sie können die Sitzung jederzeit verlassen</li>
                    <li>
                        Dateien und Dokumente, welche Sie teilen möchten, müssen
                        von Ihnen ausdrücklich «hochgeladen» werden
                    </li>
                    <li>
                        Es können Daten geteilt werden, welche unter das
                        Bankkundengeheimnis und das Datenschutzgesetz fallen
                    </li>
                </ul>
                <p>
                    Weitere Informationen zum Umgang der BLKB mit dem Thema
                    Datenschutz finden Sie in unserer Datenschutzerklärung unter{' '}
                    <a
                        href="https://www.blkb.ch/datenschutz"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        blkb.ch/datenschutz
                    </a>
                    .
                </p>
            </div>
        </div>
    ),
    fr: '',
    it: '',
};

disclaimerContent.en = disclaimerContent.de;
disclaimerContent.fr = disclaimerContent.de;
disclaimerContent.it = disclaimerContent.de;

export const disclaimerLabel = {
    en: '',
    de: 'Ich bin mit dem oben beschriebenen Vorgehen einverstanden',
    fr: '',
    it: '',
};

disclaimerLabel.en = disclaimerLabel.de;
disclaimerLabel.fr = disclaimerLabel.de;
disclaimerLabel.it = disclaimerLabel.de;
