import favicon from '../assets/favicons/favicon_unblumeet.ico';
import('../css/themes/Theme_default.scss');

export const Config = {
    serviceUrl: 'https://al5-app04.adia.tv',
    serviceIdFromUrl: true,
    documentTitle: 'FinFire Live',

    favicon,
};
