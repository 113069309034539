import msgFx from '../../../assets/audio/msg.mp3';
let msgPlayer = null;

const adiaLiveFx = {
    playMsg: () => {
        if (!msgPlayer) {
            msgPlayer = new Audio(msgFx);
            msgPlayer.volume = 0.2;
            msgPlayer.addEventListener('canplay', () => {
                msgPlayer.play();
            });
        } else {
            msgPlayer.currentTime = 0;
            msgPlayer.play();
        }
    },
};

export default adiaLiveFx;
