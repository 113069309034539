import {
    AL_APPLICATIONS,
    SA_APPLICATIONS,
} from '../../../../constants/constants';

export const german = {
    // General
    language: 'Sprache',
    de: 'Deutsch',
    en: 'Englisch',
    fr: 'French',
    it: 'Italian',

    save: 'Speichern',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    decline: 'Ablehnen',
    close: 'Schliessen',
    refresh: 'Aktualisieren',
    back: 'Zurück',
    signOut: 'Abmelden',

    reconnectingHeader: 'Verbindung verloren',
    reconnectingMessage:
        'Verbindung zum Server verloren. Verbindung wird wiederhergestellt...',

    // Device Settings

    deviceSettings: 'Einstellungen',
    cam: 'Kamera',
    mic: 'Mikrofon',
    resolution: 'Auflösung',
    comModeNone: 'Nur Chat',
    comModeAudio: 'Audio',
    comModeBoth: 'Audio + Video',

    // Meeting

    agenda: 'Agenda',
    showboard: 'Showboard',
    minutes: 'Protokoll',
    documents: 'Dokumente',
    chat: 'Chat',
    surveys: 'Umfragen',
    show: 'Zeigen',

    startFullscreen: 'Vollbildmodus starten',
    stopFullscreen: 'Vollbildmodus beenden',
    startScreenShare: 'Bildschirm teilen',
    stopScreenShare: 'Bildschirm teilen beenden',
    leaveMeeting: 'Meeting verlassen',
    muteMic: 'Mikrofon stummschalten',
    unmuteMic: 'Mikrofon lautschalten',
    activateMic: 'Mikrofon aktivieren',
    startCam: 'Kamera einschalten',
    stopCam: 'Kamera ausschalten',
    activateCam: 'Kamera aktivieren',
    meetingInfo: 'Meeting Info',
    sharedApplications: 'Shared Applications',
    menu: 'Menu',
    raiseHand: 'Hand heben',

    msgRemoteMute: 'Sie wurden durch einen Berater stummgeschaltet.',
    msgKick: 'Sie wurden durch einen Berater aus dem Meeting entfernt.',

    bitrate: 'Bitrate',
    framerate: 'Framerate',
    packetLoss: 'Packet loss',

    // Recording
    recConfirmTitle: 'Aufzeichnung bestätigen',
    recConfirmMessage:
        'Wir möchten dieses Meeting gerne aufzeichnen. Sind Sie damit einverstanden?',

    // Podium Player
    online: 'ONLINE',
    offline: 'OFFLINE',
    playerQuality: 'Qualität',
    playerVideoQuality: 'Videoqualität',
    playerQualityAuto: 'Automatisch',
    posterTextPre: 'Die Sendung wird in Kürze beginnen.',
    posterTextPost: 'Die Sendung wurde beendet. Vielen Dank fürs Zuschauen!',
    overlayTextPlayerError:
        'Das Abspielen dieser Sendung wird von Ihrem Browser oder Gerät nicht unterstützt.\nBitte verwenden Sie einen alternative Browser oder ein anderes Gerät.',
    volumeOff: 'Ton aus',
    volumeOn: 'Ton ein',

    // Errors
    connectionError: 'Verbindung zum Server ist fehlgeschlagen.',
    serviceNotFound: 'Dieser Service existiert nicht.',
    captchaCode: 'Captcha Code',

    alcErrorDefault: 'alc Fehler',
    alcErrorMeetingDoesNotExist:
        'Das Meeting wurde bereits abgeschlossen oder existiert nicht.',
    alcErrorMeetingNeedsCaptchaCode:
        'Nächste Meeting-Abfrage benötigt die Angabe eines Captcha Codes.',
    alcErrorMeetingConnectionLost:
        'Verbindung zum Meeting-Server verloren. Bitte versuchen Sie es erneut.',
    alcErrorMeetingFull:
        'Die maximale Anzahl Teilnehmer im Meeting ist überschritten.',
    alcErrorWebinarParticipantsFull:
        'Die maximale Anzahl Teilnehmer im Webinar ist überschritten.',
    alcErrorNeedsRecordingConfirmation:
        'Sie können an dieses Meeting nur betreten, wenn sie der Aufzeichnung zustimmen.',
    alcErrorAdviserIdMismatch:
        'Der verwendete Einladungslink wurde nicht für Ihren Benutzer ausgestellt.',
    alcErrorExclusiveNotInvited:
        'Sie wurden nicht für dieses exklusive Meeting eingeladen',
    alcErrorNotAdmitted: 'Ihre Anfrage das Meeting zu betreten wurde abgelehnt',

    webRtcErrorDefault: 'WebRTC Fehler',
    webRtcErrorNotFound:
        'Keine Kamera / kein Mikrofon gefunden. Bitte überprüfen Sie, dass Ihre Kamera / Ihr Mikrofon angeschlossen ist und klicken Sie Aktualisieren.',
    webRtcErrorNotAllowed:
        'Bitte erlauben Sie in den Browsereinstellungen den Zugriff auf Ihr Mikrofon / Ihre Kamera. Aktivieren Sie danach Audio / Audio + Video.',
    webRtcErrorDeviceInUse:
        'Ihre Kamera wird von einer anderen Applikation verwendet. Bitte schliessen Sie diese Applikation oder wählen Sie eine andere Kamera.',
    webRtcErrorFailedAllocateSource:
        'Es konnte nicht auf Ihre Kamera zugegriffen werden.',
    webRtcErrorOverconstrained:
        'Ihre gespeicherte Kamera / Ihr gespeichertes Mikrofon konnte nicht gefunden werden. Bitte überprüfen Sie, dass Ihre Kamera / Ihr Mikrofon angeschlossen ist und klicken Sie Aktualisieren oder wählen Sie ein anderes Gerät.',
    webRtcErrorConnectionTimeout:
        'Zeitüberschreitung beim Verbinden zum Meeting Server',

    webRtcBrowserNotSupported:
        'Ihr Browser wird nicht unterstützt. Bitte verwenden Sie eine aktuelle Version von Chrome oder Firefox.',
    webRtcBrowserOutOfDate:
        'Ihre Version von {{browser}} ist veraltet und wird nicht mehr unterstützt. Bitte aktualisieren Sie Ihren Browser.',
    webRtcBrowserNonSafariIos:
        '{{browser}} wird auf iOS leider nicht untersützt. Bitte verwenden Sie eine aktuelle Version von Safari.',
    webRtcBrowserLegacyEdge:
        'Sie verwenden Edge Legacy. Diese Version von Edge ist veraltet und wird nicht mehr unterstüzt. Wir empfehlen Ihnen den neuen Edge zu verwenden, welchen Sie {{link}} herunterladen können.',
    webRtcBrowserIe:
        'Internet Explorer wird nicht unterstützt. Wir empfehlen Ihnen den neuen Edge zu verwenden, welchen Sie {{link}} herunterladen können.',

    podiumSwitchError: 'Sprecherlaubnis konnte nicht erteilt werden.',

    // Communicator

    yourName: 'Ihr Name',
    yourEmail: 'Ihre E-Mail Adresse',
    invalidEmail: 'E-Mail Adresse ungültig.',
    enterNameAndEmail: 'Name und E-Mail Adresse angeben.',
    continue: 'Weiter',

    meetingTitle: 'Meeting',
    meetingCompleted: 'Meeting beendet',
    yourMeetingId: 'Ihre Meeting-ID',
    yourMeeting: 'Ihr Meeting',
    enterMeetingId: 'Bitte die erhaltene Meeting-ID eingeben.',
    meetingId: 'Meeting-ID',
    joinMeeting: 'Jetzt teilnehmen',
    meetingNotReady:
        'Ihr Termin findet erst am {{date}} um {{time}} statt. Bitte warten Sie oder versuchen Sie es später nochmals.',
    meetingExpired:
        'Der Termin ist nicht mehr gültig. Bitte vereinbaren Sie einen neuen Termin.',

    webinarTitle: 'Webinar',
    webinarCompleted: 'Webinar beendet',
    yourWebinarId: 'Ihre Webinar-ID',
    yourWebinar: 'Ihr Webinar',
    enterWebinarId: 'Bitte die erhaltene Webinar-ID eingeben.',
    webinarId: 'Webinar-ID',
    joinWebinar: 'Jetzt teilnehmen',
    webinarNotReady:
        'Das Webinar findet erst am {{date}} um {{time}} statt. Bitte warten Sie oder versuchen Sie es später nochmals.',
    webinarExpired: 'Der Termin ist nicht mehr gültig.',

    consultationTitle: 'Beratung',
    consultationCompleted: 'Beratung beendet',
    yourConsultationId: 'Ihre Beratungs-ID',
    yourConsultation: 'Ihre Beratung',
    enterConsultationId: 'Bitte die erhaltene Beratungs-ID eingeben.',
    consultationId: 'Beratungs-ID',
    joinConsultation: 'Jetzt teilnehmen',
    consultationNotReady:
        'Die Beratung findet erst am {{date}} um {{time}} statt. Bitte warten Sie oder versuchen Sie es später nochmals.',
    consultationExpired: 'Der Termin ist nicht mehr gültig.',

    // Webinars
    participants: 'Teilnehmer',
    requestSpeakerPermission: 'Sprecherlaubnis anfordern',
    cancelRequestSpeakerPermission: 'Sprecherlaubnis nicht mehr anfordern',
    endSpeakerPermission: 'Sprechen beenden',
    speakerPermission: 'Sprecherlaubnis',
    speakerPermissionConfirmation:
        'Ihnen wurde die Sprecherlaubnis erteilt. Bitte wählen Sie die gewünschte Kommunikationsform.',
    leaveWebinar: 'Webinar verlassen',

    // Admittance
    awaitAdmittanceTitle: 'Anfrage gesendet',
    awaitAdmittanceText:
        'Sie werden den Meetingraum betreten, sobald Ihnen die Erlaubnis erteilt wird',

    // Contact Form
    contactForm: 'Kontaktanfrage',
    contactFormText: 'Oder senden Sie uns eine Kontaktanfrage',
    contactFormSuccess:
        'Vielen Dank für Ihre Kontaktanfrage. Wir werden uns mit Ihnen in Verbindung setzen.',
    send: 'Senden',
    name: 'Name',
    email: 'E-Mail',
    phone: 'Telefonnummer',
    message: 'Nachricht',
    desiredDate: 'Gewünschter Termin',

    // Call Manager
    callManagerStartText: 'Jetzt direkt beraten lassen',
    connect: 'Verbinden',
    waitingTitle: 'Es wird ein freier Berater gesucht.',
    waitingLine1: 'Sie befinden sich auf Position {{pos}} der Warteschlange.',
    waitingLine2: 'Bitte haben Sie noch einen Moment Geduld.',
    connectingTitle: 'Es geht gleich los!',
    connectingLine1:
        'Einen Moment, Sie werden nun mit einem Berater verbunden.',
    noAdviserTitle: 'Keine Berater verfügbar.',
    noAdviserLine1: 'Im Moment sind keine Berater online.',

    // Side Panel
    ['appTitle-' + SA_APPLICATIONS.DOCUMENTS]: 'Dokumente',
    ['appTitle-' + SA_APPLICATIONS.CHAT]: 'Chat',
    ['appTitle-' + SA_APPLICATIONS.STREAM_DOCUMENTS]: 'Dokumente',
    ['appTitle-' + SA_APPLICATIONS.STREAM_CHAT]: 'Chat',
    ['appTitle-' + SA_APPLICATIONS.STREAM_SURVEYS]: 'Umfragen',
    ['appTitle-' + AL_APPLICATIONS.PARTICIPANTS]: 'Teilnehmer',
};
