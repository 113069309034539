import React from 'react';
import { connect } from 'react-redux';
import { Panel } from 'primereact/panel';
import TranslatedString from '../base/i18n/TranslatedString';
import { ScrollPanel } from 'primereact/scrollpanel';

export function ParticipantList(props) {
    let participantItems = [];
    props.meetingParticipants.forEach((participant) => {
        // all participants
        if (!participant.isAdviser && !participant.standalone) {
            participantItems.push(
                <div className="p-col-12" key={participant.id}>
                    {participant.info.name}
                </div>
            );
        }
    });

    return (
        <div className="participant-tab">
            <ScrollPanel className="participant-tab-scroll-panel">
                {participantItems.length > 0 && (
                    <Panel
                        header={
                            <span>
                                <TranslatedString id={'participants'} />
                                {' (' + participantItems.length + ')'}
                            </span>
                        }
                        className={'participant-list-panel'}
                    >
                        <div className="p-grid">{participantItems}</div>
                    </Panel>
                )}
            </ScrollPanel>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        meetingParticipants: state.meetings.meetingParticipants,
        clientInfo: state.meetings.clientInfo,
    };
};

export default connect(mapStateToProps, undefined)(ParticipantList);
