import { log } from './helpers';
import {
    AUTH_MIN_TOKEN_VALIDITY,
    AUTH_REFRESH_TOKEN_INTERVAL,
} from '../../../constants/constants';

//private fields
let kc;
let keycloakSettings;
let logoutHandler;
let refreshTokenInterval;

//private methods:
/**
 * login using keycloak
 * @param serviceId
 * @param loginSuccess - callback if login with keycloak succeeds
 * @param loginFailure - callback if login with keycloak fails
 */
function keycloakLogin(serviceId, loginSuccess, loginFailure) {
    kc.init({
        onLoad: 'login-required',
        promiseType: 'native',
        /*checkLoginIframe: false*/
    })
        .then((authenticated) => {
            log.debug(kc.tokenParsed);

            if (authenticated) {
                const user = {
                    _id: kc.subject,
                    roles: kc.tokenParsed.realm_access.roles,
                    username: kc.tokenParsed.preferred_username,
                    name: kc.tokenParsed.family_name,
                    firstName: kc.tokenParsed.given_name,
                    email: kc.tokenParsed.email,
                    locale: kc.tokenParsed.locale,
                    ldap: !!(
                        kc.tokenParsed.adiaLive &&
                        kc.tokenParsed.adiaLive.ldapId
                    ),
                };
                loginSuccess(user, serviceId);
            } else {
                loginFailure();
            }
        })
        .catch(() => {
            loginFailure();
        });
}

class AuthHelper {
    /**
     * login
     * @param serviceId
     * @param settings - keycloakSettings
     * @param onLogin - code to execute for login - defined in login thunk
     * @param onLogout - code to execute for logout - defined in login thunk
     */
    login(serviceId, settings, onLogin, onLogout) {
        import('keycloak-js').then((module) => {
            const Keycloak = module.default;
            keycloakSettings = settings;
            kc = new Keycloak(keycloakSettings);

            // set logoutHandler for future keycloak logout
            logoutHandler = onLogout(kc);

            // login with keycloak
            keycloakLogin.call(
                this,
                serviceId,
                onLogin.success,
                onLogin.failure
            );
        });
    }

    /**
     * logout with logout handler
     */
    logout() {
        logoutHandler();
    }

    /**
     * get keycloak/default token
     * @param minimal - if minimal is true only return token without resettingInactivityTimer or refreshToken
     * @returns {*|string}
     */
    getToken(minimal = false) {
        if (!minimal) {
            this.refreshToken();
        }
        return kc.token || '';
    }

    /**
     * get logged in user
     * @returns {{roles: string[], username: *}}
     */
    getUser() {
        return {
            _id: kc.subject,
            roles: kc.tokenParsed.realm_access.roles,
            username: kc.tokenParsed.preferred_username,
            locale: kc.tokenParsed.locale,
        };
    }

    /**
     * refresh the token if the remaining validity time is less than AUTH_MIN_TOKEN_VALIDITY
     */
    refreshToken() {
        kc.updateToken(AUTH_MIN_TOKEN_VALIDITY).catch((err) => {
            log.error(
                `Promise returned error in authHelper.refreshToken using keycloak: ${err}`
            );
            this.logout();
        });
    }

    /**
     * initialize refreshTokenInterval to keep user logged in
     */
    startTokenRefreshInterval() {
        if (refreshTokenInterval) {
            clearInterval(refreshTokenInterval);
            refreshTokenInterval = null;
        }

        refreshTokenInterval = setInterval(() => {
            log.debug('[refreshTokenInterval] try token refresh');
            this.refreshToken();
        }, AUTH_REFRESH_TOKEN_INTERVAL * 1000);
    }
}

const authHelper = new AuthHelper();
export default authHelper;
