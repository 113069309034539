import { defaultConfig } from './Config_default';

/*
import Config_CONFIGNAME.js
if no CONFIGNAME is defined, use Config_local.js
  */

let config = null;

// TODO: better way of matching urls?
if (window.location.href.includes('dev-meet.adia.tv')) {
    config = require('./Config_al5-dev01_DEFAULT').Config;
} else if (window.location.href.includes('dev02-meet.adia.tv')) {
    config = require('./Config_al5-dev02_DEFAULT').Config;
} else if (window.location.href.includes('int-meet.adia.tv')) {
    config = require('./Config_al5-int01_DEFAULT').Config;
} else if (window.location.href.includes('pod-meet.adia.tv')) {
    config = require('./Config_al5-pod_DEFAULT').Config;
} else if (window.location.href.includes('meet.finfire.de')) {
    config = require('./Config_al5-app04_Finfire').Config;
} else if (window.location.href.includes('css-meet.adialive.ch')) {
    config = require('./Config_al5-app10_CSS_Versicherung').Config;
} else if (window.location.href.includes('onlineberatungpro.de')) {
    config = require('./Config_al5-app07_OnlineberatungPro').Config;
} else if (window.location.href.includes('live.blkb.ch')) {
    config = require('./Config_al5-app08_BLKB-01_meet').Config;
} else if (window.location.href.includes('live-telefon.blkb.ch')) {
    config = require('./Config_al5-app08_BLKB-01_phone').Config;
} else if (window.location.href.includes('meet.css.ch')) {
    config = require('./Config_al5-app08_CSS').Config;
} else if (window.location.href.includes('live.bekb.ch')) {
    config = require('./Config_al5-app08_BEKB').Config;
} else if (window.location.href.includes('guest.adialive.ch')) {
    config = require('./Config_al5-app10_DEFAULT').Config;
} else if (window.location.href.includes('swisscomacademy.adialive.ch')) {
    config = require('./Config_al5-app10_Swisscom-Academy').Config;
} else if (window.location.href.includes('guest-del.adialive.ch')) {
    config = require('./Config_al5-app10-del_DEFAULT').Config;
} else if (window.location.href.includes('guest-uc.adialive.ch')) {
    config = require('./Config_al5-app10-uc_DEFAULT').Config;
} else if (window.location.href.includes('guest-ipeak.adialive.ch')) {
    config = require('./Config_al5-app10-ipeak_DEFAULT').Config;
} else if (
    window.location.href.includes('energie-und-management.adialive.ch')
) {
    config = require('./Config_al5-app10-uc_E_M').Config;
} else if (window.location.href.includes('guest.adiacom.live/esb')) {
    config = require('./Config_al5-app11-prod_esb').Config;
} else if (window.location.href.includes('guest.adiacom.live/gwtelfs')) {
    config = require('./Config_al5-app11-prod_gwtelfs').Config;
} else if (window.location.href.includes('guest.adiacom.live/swr-ag')) {
    config = require('./Config_al5-app11-prod_swr').Config;
} else if (window.location.href.includes('guest.adiacom.live')) {
    config = require('./Config_al5-app11-prod_DEFAULT').Config;
} else if (window.location.href.includes('meet.pos-live.com')) {
    config = require('./Config_al5-app11-pos_DEFAULT').Config;
} else if (window.location.href.includes('webinar.enbw.com/event')) {
    config = require('./Config_al5-app11-enbw_event').Config;
} else if (window.location.href.includes('webinar.enbw.com')) {
    config = require('./Config_al5-app11-enbw_DEFAULT').Config;
} else if (window.location.href.includes('guest-km.adialive.ch')) {
    config = require('./Config_al5-app12-km_DEFAULT').Config;
} else if (window.location.href.includes('live-beratung.vema-eg.de')) {
    config = require('./Config_al5-app14_VEMA').Config;
} else if (
    window.location.href.includes('videoberatung.vzch.com') ||
    window.location.href.includes('conseil-video.vzch.com') ||
    window.location.href.includes('videoconsulenza.vzch.com') ||
    window.location.href.includes('videoconsulting.vzch.com')
) {
    config = require('./Config_VZ-PROD_VZ-CH-01').Config;
} else if (
    window.location.href.includes('videoberatung.vzde.com') ||
    window.location.href.includes('videoconsulting.vzde.com')
) {
    config = require('./Config_VZ-PROD_VZ-DE-01').Config;
} else if (
    window.location.href.includes('videoberatung-int.vzch.com') ||
    window.location.href.includes('conseil-video-int.vzch.com') ||
    window.location.href.includes('videoconsulenza-int.vzch.com') ||
    window.location.href.includes('videoconsulting-int.vzch.com')
) {
    config = require('./Config_VZ-INT_VZ-CH-01').Config;
} else if (
    window.location.href.includes('videoberatung-int.vzde.com') ||
    window.location.href.includes('videoconsulting-int.vzde.com')
) {
    config = require('./Config_VZ-INT_VZ-DE-01').Config;

} else if (window.location.href.includes('domain-test.adiacom.live')) {
    config = require('./Config_al5-app10-uc_TEST').Config;
} else if (process.env.NODE_ENV === 'development') {
    try {
        config = require('./Config_local').Config;
    } catch (e) {
        throw new Error("Can't find config file Config_local.js!");
    }
} else {
    throw new Error('No Config matched!');
}

export const Config = Object.assign(defaultConfig, config);
