import React, { Component } from 'react';
import { Menu } from 'primereact/menu';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import classNames from 'classnames';
import { ReactComponent as SideBarIcon } from '../../assets/icons/icon_sidebar.svg';

import {
    hideMenu,
    leaveMeeting,
    showMenu,
    webRtcStartScreenShare,
    webRtcStopScreenShare,
    fullscreenToggle,
    alcRequestSpeakerPermission,
    revokeSpeakerPermission,
    alcSetHandRaised,
    showSidePanelMenu,
    hideSidePanelMenu,
} from './actions';
import {
    hideSettings,
    showSettings,
    webRtcMuteAudio,
    webRtcMuteVideo,
} from '../deviceSettings/actions';
import { connect } from 'react-redux';
import TranslatedString from '../base/i18n/TranslatedString';
import {
    COMMUNICATION_MODES,
    MEETING_TYPES,
    RESPONSIVE_MODES,
    SPEAKER_PERMISSION_STATE,
    TOOLTIP_TOUCH_DELAY,
} from '../../constants/constants';
import { fullscreenEnabled } from '../base/util/helpers';
import { Config } from '../../config/Config';
import SidePanelTabBar from './SidePanelTabBar';
import MeetingToolbarButton from './MeetingToolbarButton';
import MeetingTime from './MeetingTime';

export class MeetingToolbar extends Component {
    constructor(props) {
        super(props);

        this.menuBtnRef = React.createRef();
        this.toolBarRef = React.createRef();

        this.handleActivateCamera = this.handleActivateCamera.bind(this);
        this.handleActivateMic = this.handleActivateMic.bind(this);
        this.handleSpeakerPermission = this.handleSpeakerPermission.bind(this);
    }

    handleActivateCamera() {
        if (
            this.props.communicationMode === COMMUNICATION_MODES.AUDIO ||
            this.props.communicationMode === COMMUNICATION_MODES.NONE
        ) {
            this.props.showSettings();
        } else {
            this.props.webRtcMuteVideo(false);
        }
    }

    handleActivateMic() {
        if (this.props.communicationMode === COMMUNICATION_MODES.NONE) {
            this.props.showSettings();
        } else {
            this.props.webRtcMuteAudio(false);
        }
    }

    handleSpeakerPermission() {
        if (
            this.props.speakerPermissionState ===
            SPEAKER_PERMISSION_STATE.GRANTED
        ) {
            this.props.revokeSpeakerPermission();
        } else if (
            this.props.speakerPermissionState ===
            SPEAKER_PERMISSION_STATE.REQUESTING
        ) {
            this.props.alcRequestSpeakerPermission(false);
        } else if (
            this.props.speakerPermissionState !==
            SPEAKER_PERMISSION_STATE.GRANTED_PREPARING
        ) {
            this.props.alcRequestSpeakerPermission(true);
        }
    }

    render() {
        /*const responsiveMenuActive =
            this.props.responsiveMode < RESPONSIVE_MODES.MEDIUM;*/
        const responsiveMenuActive = true;

        const micInactive =
            this.props.communicationMode === COMMUNICATION_MODES.NONE;

        const isWebinar = this.props.meetingInfo.type === MEETING_TYPES.WEBINAR;

        //const mobileLayout = this.props.responsiveMode < RESPONSIVE_MODES.FULL;
        //const mobileLayout = false; // todo: is only quick fix for Mobile-View

        let audioMuteButton = null;
        if (!this.props.audioVideoDisabled && (!isWebinar || !micInactive)) {
            if (this.props.audioMuted || micInactive) {
                audioMuteButton = (
                    <MeetingToolbarButton
                        label={
                            micInactive ? (
                                <TranslatedString id="activateMic" />
                            ) : (
                                <TranslatedString id="unmuteMic" />
                            )
                        }
                        icon="icon-mic-disabled"
                        toggled={false}
                        onToggle={() => this.handleActivateMic()}
                    />
                );
            } else {
                audioMuteButton = (
                    <MeetingToolbarButton
                        label={<TranslatedString id="muteMic" />}
                        icon="icon-microphone"
                        toggled={false}
                        onToggle={() => this.props.webRtcMuteAudio(true)}
                    />
                );
            }
        }

        const leaveMeetingButton = (
            <div aria-label="Leave the call" className="toolbox-button">
                <div className="toolbox-icon">
                    <Tippy
                        content={
                            <TranslatedString
                                id={isWebinar ? 'leaveWebinar' : 'leaveMeeting'}
                            />
                        }
                        arrow
                        touch={['hold', TOOLTIP_TOUCH_DELAY]}
                    >
                        <i
                            className="icon-hangup"
                            onClick={
                                this.props.meetingInfo.type ===
                                MEETING_TYPES.PODIUM
                                    ? this.handleSpeakerPermission
                                    : this.props.leaveMeeting
                            }
                        />
                    </Tippy>
                </div>
            </div>
        );

        const cameraInactive =
            this.props.communicationMode === COMMUNICATION_MODES.AUDIO ||
            this.props.communicationMode === COMMUNICATION_MODES.NONE;

        let videoMuteButton = null;
        if (!this.props.audioVideoDisabled && (!isWebinar || !cameraInactive)) {
            if (this.props.videoMuted || cameraInactive) {
                videoMuteButton = (
                    <MeetingToolbarButton
                        label={
                            cameraInactive ? (
                                <TranslatedString id="activateCam" />
                            ) : (
                                <TranslatedString id="startCam" />
                            )
                        }
                        icon="icon-camera-disabled"
                        toggled={false}
                        onToggle={() => this.handleActivateCamera()}
                    />
                );
            } else {
                videoMuteButton = (
                    <MeetingToolbarButton
                        label={<TranslatedString id="stopCam" />}
                        icon="icon-camera"
                        toggled={false}
                        onToggle={() => this.props.webRtcMuteVideo(true)}
                    />
                );
            }
        }

        let menuItems = [];

        if (responsiveMenuActive) {
            if (fullscreenEnabled) {
                if (this.props.fullscreenActive) {
                    menuItems.push({
                        label: <TranslatedString id="stopFullscreen" />,
                        icon: 'pi pi-fw pi-md-fullscreen-exit',
                        command: () => {
                            this.props.fullscreenToggle();
                            this.props.hideMenu();
                        },
                    });
                } else {
                    menuItems.push({
                        label: <TranslatedString id="startFullscreen" />,
                        icon: 'pi pi-fw pi-md-fullscreen',
                        command: () => {
                            this.props.fullscreenToggle();
                            this.props.hideMenu();
                        },
                    });
                }
            }
            if (
                !this.props.audioVideoDisabled &&
                (!isWebinar || !micInactive)
            ) {
                menuItems.push({
                    label: <TranslatedString id="deviceSettings" />,
                    icon: 'pi pi-fw pi-md-settings',
                    command: () => {
                        this.props.showSettings();
                        this.props.hideMenu();
                    },
                });
            }
        }

        const screenShareButton = this.props.screenSharingEnabled ? (
            <div aria-label="Toggle screenshare" className="toolbox-button">
                <div className="toolbox-icon">
                    {this.props.ownScreenShareView ? (
                        <Tippy
                            content={<TranslatedString id="stopScreenShare" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-share-desktop toggled "
                                onClick={this.props.webRtcStopScreenShare}
                            />
                        </Tippy>
                    ) : (
                        <Tippy
                            content={<TranslatedString id="startScreenShare" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-share-desktop"
                                onClick={this.props.webRtcStartScreenShare}
                            />
                        </Tippy>
                    )}
                </div>
            </div>
        ) : null;

        const menuButton =
            responsiveMenuActive && menuItems.length > 0 ? (
                <div
                    aria-label="More"
                    className="toolbox-button"
                    ref={this.menuBtnRef}
                >
                    <div className="toolbox-icon">
                        {this.props.menuVisible ? (
                            <i
                                className="icon-thumb-menu toggled"
                                onClick={this.props.hideMenu}
                            />
                        ) : (
                            <Tippy
                                content={<TranslatedString id="menu" />}
                                arrow
                                touch={['hold', TOOLTIP_TOUCH_DELAY]}
                            >
                                <i
                                    className="icon-thumb-menu"
                                    onClick={this.props.showMenu}
                                />
                            </Tippy>
                        )}
                    </div>
                </div>
            ) : null;

        let speakerPermissionTextId = 'requestSpeakerPermission';
        if (
            this.props.speakerPermissionState ===
            SPEAKER_PERMISSION_STATE.GRANTED
        ) {
            speakerPermissionTextId = 'endSpeakerPermission';
        } else if (
            this.props.speakerPermissionState ===
            SPEAKER_PERMISSION_STATE.REQUESTING
        ) {
            speakerPermissionTextId = 'cancelRequestSpeakerPermission';
        }

        const speakerPermissionButton = isWebinar ? (
            <div aria-label="Raise Hand" className="toolbox-button">
                <div className="toolbox-icon">
                    <Tippy
                        content={
                            <TranslatedString id={speakerPermissionTextId} />
                        }
                        arrow
                        touch={['hold', TOOLTIP_TOUCH_DELAY]}
                    >
                        <i
                            className={classNames('icon-raised-hand', {
                                toggled:
                                    this.props.speakerPermissionState ===
                                        SPEAKER_PERMISSION_STATE.GRANTED ||
                                    this.props.speakerPermissionState ===
                                        SPEAKER_PERMISSION_STATE.REQUESTING,
                            })}
                            onClick={this.handleSpeakerPermission}
                        />
                    </Tippy>
                </div>
            </div>
        ) : null;

        const raiseHandButton =
            this.props.meetingInfo.type === MEETING_TYPES.MEETING &&
            this.props.enableRaiseHand ? (
                <div aria-label="Raise Hand" className="toolbox-button">
                    <div className="toolbox-icon">
                        <Tippy
                            content={<TranslatedString id={'raiseHand'} />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className={classNames('icon-raised-hand', {
                                    toggled: this.props.handRaised,
                                })}
                                onClick={() => {
                                    this.props.alcSetHandRaised(
                                        this.props.clientInfo.id,
                                        !this.props.handRaised
                                    );
                                }}
                            />
                        </Tippy>
                    </div>
                </div>
            ) : null;

        let menuRightPos = 0;
        if (
            this.props.menuVisible &&
            this.menuBtnRef.current &&
            this.toolBarRef.current
        ) {
            const menuButtonRightPos =
                this.menuBtnRef.current.getBoundingClientRect().right;
            const containerWidth =
                this.toolBarRef.current.getBoundingClientRect().width;

            menuRightPos = Math.min(
                containerWidth - menuButtonRightPos - 100,
                containerWidth - 205
            );
        }

        const showTitle = this.props.responsiveMode > RESPONSIVE_MODES.MEDIUM;
        const putMainControlsLeft =
            this.props.responsiveMode < RESPONSIVE_MODES.MEDIUM;
        const sidePanelButtonInMenu =
            this.props.responsiveMode < RESPONSIVE_MODES.MEDIUM;

        return (
            <div
                className={classNames('meeting-toolbar', {
                    visible: this.props.controlsVisible,
                    'responsive-mode-small':
                        this.props.responsiveMode === RESPONSIVE_MODES.SMALL,
                })}
                ref={this.toolBarRef}
            >
                <div className="toolbox-background" />
                <div
                    className="toolbox-menu-container"
                    style={{ right: menuRightPos }}
                >
                    {this.props.menuVisible && <Menu model={menuItems} />}
                </div>
                {this.props.sidePanelMenuVisible && (
                    <div
                        className="toolbox-menu-container"
                        style={{ right: 10 }}
                    >
                        <SidePanelTabBar asMenu={true} />
                    </div>
                )}
                <div className="toolbox-content">
                    {!putMainControlsLeft && (
                        <React.Fragment>
                            <div className="button-group-left">
                                <div className="title-time">
                                    {this.props.meetingInfo.startTime && (
                                        <MeetingTime
                                            startTime={
                                                this.props.meetingInfo.startTime
                                            }
                                        />
                                    )}
                                    {this.props.meetingInfo.startTime &&
                                        showTitle && (
                                            <React.Fragment>
                                                &nbsp;|&nbsp;
                                            </React.Fragment>
                                        )}
                                    {showTitle && (
                                        <span className="toolbar-title">
                                            {this.props.meetingInfo.title}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="button-group-center">
                                {audioMuteButton}
                                {videoMuteButton}
                                {screenShareButton}
                                {menuButton}
                                {leaveMeetingButton}
                                {speakerPermissionButton}
                                {raiseHandButton}
                            </div>
                        </React.Fragment>
                    )}

                    {putMainControlsLeft && (
                        <React.Fragment>
                            <div className="button-group-left">
                                {audioMuteButton}
                                {videoMuteButton}
                                {screenShareButton}
                                {menuButton}
                                {leaveMeetingButton}
                                {speakerPermissionButton}
                                {raiseHandButton}
                            </div>
                            <div className="button-group-center" />
                        </React.Fragment>
                    )}

                    <div className="button-group-right">
                        {sidePanelButtonInMenu && (
                            <MeetingToolbarButton
                                toggled={this.props.sidePanelMenuVisible}
                                label={<TranslatedString id="menu" />}
                                icon={<SideBarIcon />}
                                appId="sidePanelMenu"
                                onToggle={(appId) => {
                                    if (appId) {
                                        this.props.showSidePanelMenu();
                                    } else {
                                        this.props.hideSidePanelMenu();
                                    }
                                }}
                                badge={this.props.showSidePanelBadge ? 'N' : ''}
                            />
                        )}
                        {!sidePanelButtonInMenu && <SidePanelTabBar />}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let screenSharingEnabled = state.meetings.webRtcFlags.hasDisplayMedia;
    let audioVideoDisabled = false;
    if (
        state.meetings.meetingInfo &&
        state.meetings.meetingInfo.type === MEETING_TYPES.PHONE_CONSULTING
    ) {
        if (Config.phoneConsultingScreenSharingDisabled) {
            screenSharingEnabled = false;
        }
        if (Config.phoneConsultingAudioVideoDisabled) {
            audioVideoDisabled = true;
        }
    } else if (
        state.meetings.meetingInfo &&
        state.meetings.meetingInfo.type === MEETING_TYPES.WEBINAR
    ) {
        screenSharingEnabled = !!(
            screenSharingEnabled &&
            state.meetings.clientInfo &&
            state.meetings.clientInfo.speakerPermissionState ===
                SPEAKER_PERMISSION_STATE.GRANTED
        );
    }
    if (Config.screenSharingDisabled) {
        screenSharingEnabled = false;
    }

    const showSidePanelBadge =
        state.meetings.streamChatMessagePending ||
        state.meetings.streamSurveyPending ||
        state.meetings.streamDocumentPending ||
        state.meetings.chatMessagePending ||
        state.meetings.documentPending;

    return {
        language: state.base.i18n.language,
        clientInfo: state.meetings.clientInfo,
        meetingInfo: state.meetings.meetingInfo,
        meetingParticipants: state.meetings.meetingParticipants,
        audioMuted: state.deviceSettings.currentSettings.audioMuted,
        videoMuted: state.deviceSettings.currentSettings.videoMuted,
        ownScreenShareView: state.meetings.ownScreenShareView,
        documentsPanelShown: state.meetings.documentsPanelShown,
        chatPanelShown: state.meetings.chatPanelShown,
        chatMessagePending: state.meetings.chatMessagePending,
        documentPending: state.meetings.documentPending,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
        controlsVisible: state.meetings.controlsVisible,
        menuVisible: state.meetings.menuVisible,
        communicationMode: state.deviceSettings.communicationMode,
        applicationsUrl:
            state.landingPage.publicServiceInfo.meetingsSettings
                .applicationsUrl,
        activeSidePanelApplicationId:
            state.meetings.activeSidePanelApplicationId,
        screenSharingEnabled,
        audioVideoDisabled,
        responsiveMode: state.base.common.responsiveMode,
        fullscreenActive: state.meetings.fullscreenActive,
        sidePanelIFrameReady: state.meetings.sidePanelIFrameReady,
        speakerPermissionState:
            state.meetings.clientInfo.speakerPermissionState,
        handRaised: state.meetings.clientInfo.handRaised,
        screenSharingMode:
            state.meetings.clientInfo &&
            state.meetings.clientInfo.userMode &&
            state.meetings.clientInfo.userMode.screenSharing,
        enableRaiseHand:
            state.landingPage.publicServiceInfo.meetingsSettings
                .enableRaiseHand,
        showSidePanelBadge,
        sidePanelMenuVisible: state.meetings.sidePanelMenuVisible,
    };
};

const mapDispatchToProps = {
    hideSettings,
    showSettings,
    hideMenu,
    leaveMeeting,
    showMenu,
    webRtcMuteAudio,
    webRtcMuteVideo,
    webRtcStartScreenShare,
    webRtcStopScreenShare,
    fullscreenToggle,
    alcRequestSpeakerPermission,
    revokeSpeakerPermission,
    alcSetHandRaised,
    showSidePanelMenu,
    hideSidePanelMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingToolbar);
