import React from 'react';
import { connect } from 'react-redux';
import { sendContactForm, initApp } from './actions';
import { InputText } from 'primereact/inputtext';
import TranslatedString from '../base/i18n/TranslatedString';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Config } from '../../config/Config';
import { Button } from 'primereact/button';
import { getTranslatedString } from '../base/i18n/translations';
import { Message } from 'primereact/message';

// TODO: field validation
export class ContactForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            desiredDate: '',
        };

        this.handleSendClick = this.handleSendClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleSendClick() {
        const dateMs = this.state.desiredDate
            ? this.state.desiredDate.getTime()
            : undefined;
        this.props.sendContactForm(
            this.state.name,
            this.state.email,
            this.state.phone,
            this.state.message,
            dateMs
        );
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        let content;
        if (this.props.contactFormSuccess) {
            content = (
                <div className="msgText">
                    <TranslatedString id="contactFormSuccess" />
                </div>
            );
        } else {
            content = (
                <div className="p-grid form-group">
                    {this.props.contactFormErrorId && (
                        <div className="p-col-12">
                            <Message
                                severity="warn"
                                text={getTranslatedString(
                                    this.props.language,
                                    this.props.contactFormErrorId
                                )}
                            />
                        </div>
                    )}
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={this.state.name}
                                name="name"
                                onChange={this.handleInputChange}
                                disabled={this.props.isLoading}
                            />
                            <label>
                                <TranslatedString id="name" />*
                            </label>
                        </span>
                    </div>
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={this.state.email}
                                name="email"
                                onChange={this.handleInputChange}
                                disabled={this.props.isLoading}
                            />
                            <label>
                                <TranslatedString id="email" />*
                            </label>
                        </span>
                    </div>
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={this.state.phone}
                                name="phone"
                                onChange={this.handleInputChange}
                                disabled={this.props.isLoading}
                            />
                            <label>
                                <TranslatedString id="phone" />
                            </label>
                        </span>
                    </div>
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputTextarea
                                className="form-input"
                                rows={5}
                                maxLength={1000}
                                value={this.state.message}
                                placeholder={getTranslatedString(
                                    this.props.language,
                                    'message'
                                )}
                                name="message"
                                onChange={this.handleInputChange}
                                disabled={this.props.isLoading}
                            />
                        </span>
                        {this.state.message.length >= 900 && (
                            <div>
                                {this.state.message.length}
                                {'/1000'}
                            </div>
                        )}
                    </div>
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Calendar
                                className="form-input"
                                dateFormat="dd.mm.yy"
                                showTime={true}
                                hourFormat="24"
                                name="desiredDate"
                                value={this.state.desiredDate}
                                onChange={this.handleInputChange}
                                disabled={this.props.isLoading}
                            />
                            <label>
                                <TranslatedString id="desiredDate" />
                            </label>
                        </span>
                    </div>
                    <div className="p-col-12 inputButtons">
                        <Button
                            label={getTranslatedString(
                                this.props.language,
                                'send'
                            )}
                            className="p-highlight"
                            onClick={this.handleSendClick}
                            disabled={
                                !this.state.name ||
                                !this.state.email ||
                                this.props.isLoading
                            }
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className="msgBox">
                <div className="contentPart">
                    <img
                        className="msgIcon"
                        src={Config.landingPageImage}
                        alt={'icon'}
                    />
                    <div className="msgTitle">
                        <TranslatedString id="contactForm" />
                    </div>
                    {content}
                    <br />
                    <Button
                        label={getTranslatedString(this.props.language, 'back')}
                        onClick={() => this.props.initApp()}
                        disabled={this.props.isLoading}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.landingPage.isLoading,
        contactFormSuccess: state.landingPage.contactFormSuccess,
        contactFormErrorId: state.landingPage.contactFormErrorId,
        language: state.base.i18n.language,
    };
};

const mapDispatchToPops = {
    sendContactForm,
    initApp,
};

export default connect(mapStateToProps, mapDispatchToPops)(ContactForm);
