import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    playerDestroy,
    playerInit,
    playerLoad,
    playerPause,
    playerPlay,
    playerReset,
    playerSetQualityLevel,
    playerSetVolume,
    playerStop,
    playerToggleFullscreen,
} from '../../libs/@adiacast/player/src/view/redux/actions';
import { toggleChat } from './actions';

import throttle from 'lodash/throttle';

import PlayerTopBar from '../../libs/@adiacast/player/src/view/components/PlayerTopBar';
import PlayerOverlays from '../../libs/@adiacast/player/src/view/components/PlayerOverlays';
import { PlayerControlButton } from '../../libs/@adiacast/player/src/view/components/PlayerControls';
import PlayerSettings from '../../libs/@adiacast/player/src/view/components/PlayerSettings';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

class Player extends Component {
    constructor(props) {
        super(props);

        this.state = {
            topBarVisible: false,
            controlsVisible: false,
            showSettings: false,
        };

        this.hideTopBarTimeout = null;
        this.hideControlsTimeout = null;

        this.playerRef = React.createRef();
        this.videoContainerRef = React.createRef();

        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleTogglePlay = this.handleTogglePlay.bind(this);
        this.handleToggleMute = this.handleToggleMute.bind(this);
        this.handleVolumeChange = this.handleVolumeChange.bind(this);
        this.handleToggleSettings = this.handleToggleSettings.bind(this);
        this.handleHideSettings = this.handleHideSettings.bind(this);
        this.handleToggleFullscreen = this.handleToggleFullscreen.bind(this);
        this.handleQualityChange = this.handleQualityChange.bind(this);

        this.handleMouseMoveThrottled = throttle(this.handleMouseMove, 1000);
    }

    initialize(options) {
        this.props.playerInit(options, null);
    }

    reset(options) {
        this.props.playerReset(options, null);
    }

    componentDidMount() {
        this.props.playerLoad(
            this.playerRef.current,
            this.videoContainerRef.current
        );
    }

    componentWillUnmount() {
        if (this.hideTopBarTimeout !== null) {
            clearTimeout(this.hideTopBarTimeout);
        }
        if (this.hideControlsTimeout !== null) {
            clearTimeout(this.hideControlsTimeout);
        }
        this.props.playerDestroy();
    }

    handleMouseMove() {
        const newState = {};
        if (this.props.topBarAutoHideDuration !== -1) {
            if (!this.state.topBarVisible) {
                newState.topBarVisible = true;
            }

            if (this.hideTopBarTimeout !== null) {
                clearTimeout(this.hideTopBarTimeout);
            }

            this.hideTopBarTimeout = setTimeout(() => {
                this.hideTopBarTimeout = null;
                if (this.playerRef.current !== null) {
                    this.setState({ topBarVisible: false });
                }
            }, this.props.topBarAutoHideDuration);
        }

        if (this.props.controlsAutoHideDuration !== -1) {
            if (!this.state.controlsVisible) {
                newState.controlsVisible = true;
            }

            if (this.hideControlsTimeout !== null) {
                clearTimeout(this.hideControlsTimeout);
            }

            this.hideControlsTimeout = setTimeout(() => {
                this.hideControlsTimeout = null;
                // only hide controls if settings are not shown
                if (
                    !this.state.showSettings &&
                    this.playerRef.current !== null
                ) {
                    this.setState({ controlsVisible: false });
                }
            }, this.props.controlsAutoHideDuration);
        }

        if (this.playerRef.current !== null) {
            this.setState(newState);
        }
    }

    handleTogglePlay() {
        if (!(this.props.initialized && this.props.streamUrl)) {
            return;
        }

        if (this.props.isPlaying) {
            this.props.playerPause();
        } else {
            this.props.playerPlay();
        }
    }

    handleToggleMute() {
        const { prevVolume, volumeMuted } = this.props;
        if (volumeMuted) {
            this.props.playerSetVolume(prevVolume < 0.1 ? 0.1 : prevVolume);
        } else {
            this.props.playerSetVolume(0);
        }
    }

    handleVolumeChange(volume) {
        if (volume !== this.props.volume) {
            this.props.playerSetVolume(volume);
        }
    }

    handleToggleSettings() {
        this.setState({ showSettings: !this.state.showSettings });
    }

    handleHideSettings() {
        this.setState({ showSettings: false });
    }

    handleToggleFullscreen() {
        this.props.playerToggleFullscreen();
    }

    handleQualityChange(level) {
        this.props.playerSetQualityLevel(level);
    }

    render() {
        const {
            language,
            isPopout,
            showTopBar,
            topBarClassName,
            topBarContent,
            topBarAutoHideDuration,
            showOverlay,
            overlayClassName,
            overlayContent,
        } = this.props;
        return (
            <div
                className="player"
                ref={this.playerRef}
                onMouseMove={this.handleMouseMoveThrottled}
                onTouchMove={this.handleMouseMoveThrottled}
                onTouchStart={this.handleMouseMoveThrottled}
            >
                <div className="video-container" ref={this.videoContainerRef} />

                {showTopBar && (
                    <PlayerTopBar
                        className={topBarClassName}
                        visible={
                            this.state.topBarVisible ||
                            topBarAutoHideDuration === -1
                        }
                        content={topBarContent}
                    />
                )}

                {this.props.playbackNotAllowed && (
                    <PlayerOverlays
                        className="playback-not-allowed"
                        content={
                            <div
                                className="playback-not-allowed-overlay"
                                onClick={this.handleTogglePlay}
                                onTouchStart={this.handleTogglePlay}
                            >
                                <PlayerControlButton
                                    icon={<FontAwesomeIcon icon={faPlay} />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.handleTogglePlay();
                                    }}
                                />
                            </div>
                        }
                    />
                )}

                {showOverlay && (
                    <PlayerOverlays
                        className={overlayClassName}
                        content={overlayContent}
                    />
                )}

                {this.state.showSettings && (
                    <PlayerSettings
                        language={language}
                        showPopoutOption={isPopout}
                        onPopout={this.props.onPopout}
                        qualities={this.props.qualities}
                        qualityLevel={this.props.qualityLevel}
                        resolution={this.props.resolution}
                        onHide={this.handleHideSettings}
                        onQualityChange={this.handleQualityChange}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        loaded: state.player.loaded,
        initialized: state.player.initialized,
        playbackNotAllowed: state.player.playbackNotAllowed,
        isPlaying: state.player.isPlaying,
        qualities: state.player.qualities,
        qualityLevel: state.player.qualityLevel,
        resolution: state.player.resolution,
        volumeSupported: state.player.volumeSupported,
        volume: state.player.volume,
        prevVolume: state.player.prevVolume,
        volumeMuted: state.player.volumeMuted,
        fullscreenSupported: state.player.fullscreenSupported,
        fullscreen: state.player.fullscreen,
        streamUrl: state.player.streamUrl,
        playerError: state.player.error,

        showTopBar: props.showTopBar || false,
        topBarClassName: props.topBarClassName || '',
        topBarContent: props.topBarContent || null,
        topBarAutoHideDuration: props.topBarAutoHideDuration || 5000,
        showOverlay: props.showOverlay || false,
        overlayClassName: props.overlayClassName || '',
        overlayContent: props.overlayContent || null,
        controlsAutoHideDuration: props.controlsAutoHideDuration || 5000,

        startTime: props.startTime,
        endTime: props.endTime,
        isPopout: props.isPopout,

        // other
        language: state.base.i18n.language,
    };
};

const mapDispatchToProps = {
    playerLoad,
    playerInit,
    playerPlay,
    playerPause,
    playerStop,
    playerSetVolume,
    playerToggleFullscreen,
    playerSetQualityLevel,
    playerReset,
    playerDestroy,
    toggleChat,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(Player);
