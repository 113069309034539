import React from "react";
import classNames from "classnames";

function PlayerOverlays({ content, className }) {
    return <div className={classNames("player-overlays", className)}>{content}</div>;
}

export default PlayerOverlays;
/* PlayerOverlays props: (TODO: use props types package?)
    content,
    className
*/
