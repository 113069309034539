import React, { Component } from 'react';
import { connect } from 'react-redux';

import { LoadingScreen } from '../base/loadingScreen/LoadingScreen';
import SharedApplicationsPanel from './SharedApplicationsPanel';

export class MeetingStandalone extends Component {
    render() {
        const singleApp = new URLSearchParams(window.location.search).get(
            'singleApp'
        );
        if (this.props.alcMeetingJoined) {
            return (
                <div className="meeting-wrapper">
                    <SharedApplicationsPanel
                        standalone={true}
                        singleApp={singleApp}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <LoadingScreen />
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        alcMeetingJoined: state.meetings.alcMeetingJoined,
    };
};

export default connect(mapStateToProps, undefined)(MeetingStandalone);
