import React, { Component } from "react";
import classNames from "classnames";
import TranslatedString from "./i18n/TranslatedString";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

class PlayerSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeMenu: "main",
        };

        this.settingsRef = React.createRef();
    }

    handleClickOutside = (e) => {
        if (this.settingsRef.current && !this.settingsRef.current.contains(e.target)) {
            this.handleClose();
        }
    };

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }

    handleClose = () => {
        const { onHide } = this.props;

        this.setState({ activeMenu: "main" });
        onHide();
    };

    handleMenuButtonClick = (menu) => (e) => {
        e.nativeEvent.stopImmediatePropagation();
        this.setState({ activeMenu: menu });
    };

    render() {
        const {
            className,
            showPopoutOption,
            disabledRefreshOption,
            qualities,
            qualityLevel,
            resolution,
            onPopout,
            onRefresh,
            onQualityChange,
            language,
        } = this.props;

        let content;
        if (this.state.activeMenu === "main") {
            content = (
                <PlayerSettingsMain
                    showPopoutOption={showPopoutOption}
                    disabledRefreshOption={disabledRefreshOption}
                    onPopout={onPopout}
                    onRefresh={onRefresh}
                    onClose={this.handleClose}
                    onMenuButtonClick={this.handleMenuButtonClick}
                    language={language}
                />
            );
        } else if (this.state.activeMenu === "quality") {
            content = (
                <PlayerSettingsQualities
                    qualities={qualities}
                    qualityLevel={qualityLevel}
                    resolution={resolution}
                    onMenuButtonClick={this.handleMenuButtonClick}
                    onQualityButtonClick={onQualityChange}
                    language={language}
                />
            );
        }

        return (
            <div className={classNames("player-settings", className)} ref={this.settingsRef}>
                {content}
            </div>
        );
    }
}

function PlayerSettingsMain(props) {
    const {
        showPopoutOption,
        disabledRefreshOption,
        onClose,
        onMenuButtonClick,
        onPopout,
        onRefresh,
        language,
    } = props;
    return (
        <React.Fragment>
            <div className="settings-button" onClick={onClose}>
                <TranslatedString language={language} id={"playerSettingsClose"} />
            </div>
            <hr />
            <div className="settings-button end-icon" onClick={onMenuButtonClick("quality")}>
                <TranslatedString language={language} id={"playerSettingsQuality"} />
                <span className="icon">
                    <FontAwesomeIcon icon={faChevronRight} />
                </span>
            </div>

            <div
                className={classNames("settings-button", { disabled: disabledRefreshOption })}
                onClick={() => {
                    if (!disabledRefreshOption) {
                        onRefresh();
                    }
                }}
            >
                <TranslatedString language={language} id={"playerSettingsRefreshPlayer"} />
            </div>

            {showPopoutOption && (
                <div className="settings-button" onClick={onPopout}>
                    <TranslatedString language={language} id={"playerSettingsPopout"} />
                </div>
            )}
        </React.Fragment>
    );
}

function PlayerSettingsQualities(props) {
    const { qualities, qualityLevel, resolution, onQualityButtonClick, onMenuButtonClick, language } = props;
    const handleQualityChange = (level) => () => {
        if (qualityLevel === level) return;
        onQualityButtonClick(level);
    };

    let qualityButtons = [
        <div
            className={classNames("settings-button quality auto", {
                selected: qualityLevel === -1,
                active: qualityLevel === -1,
            })}
            key={-1}
            onClick={handleQualityChange(-1)}
        >
            <TranslatedString language={language} id={"playerSettingsQualityAuto"} />
            {qualityLevel === -1 && resolution && resolution.label && <span>{`(${resolution.label})`}</span>}
        </div>,
    ];
    if (qualities.length > 1) {
        qualityButtons = [
            ...qualityButtons,
            ...qualities.map((quality, index) => (
                <div
                    className={classNames("settings-button quality", {
                        selected: qualityLevel === quality.level,
                        active: qualityLevel === quality.level && resolution.level === quality.level,
                    })}
                    key={index}
                    onClick={handleQualityChange(quality.level)}
                >
                    <span>{quality.label}</span>
                </div>
            )),
        ];
    }

    return (
        <React.Fragment>
            <div className="settings-button start-icon" onClick={onMenuButtonClick("main")}>
                <span className="icon">
                    <FontAwesomeIcon icon={faChevronLeft} />
                </span>
                <TranslatedString language={language} id={"playerSettingVideoQuality"} />
            </div>
            <hr />
            {qualityButtons}
        </React.Fragment>
    );
}

export default PlayerSettings;
/* PlayerSettings props: (TODO: use props types package?)
    className,
    showPopoutOption,
    disabledRefreshOption,
    qualities,
    qualityLevel,
    resolution,
    onPopout,
    onRefresh,
    onQualityChange,
    onHide,
    language
*/
