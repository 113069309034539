import React, { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import { Dialog } from 'primereact/dialog';
import { Helmet } from 'react-helmet';

import './serenity/ripple';

import {
    APP_STATE,
    RESPONSIVE_MODES,
    RESPONSIVE_ORIENTATIONS,
} from './constants/constants';
import { LoadingScreen } from './features/base/loadingScreen/LoadingScreen';
import LandingPage from './features/landingPage/LandingPage';
import Meeting from './features/meetings/Meeting';
import DeviceSettings from './features/deviceSettings/DeviceSettings';
import CustomGrowl from './features/base/messages/CustomGrowl';
import TranslatedString from './features/base/i18n/TranslatedString';
import { initApp } from './features/landingPage/actions';
import { changeResponsiveMode } from './features/base/common/actions';
import { Config } from './config/Config';
import LivePlayer from './features/livePlayer/LivePlayer';
import MeetingStandalone from './features/meetings/MeetingStandalone';

class Communicator extends Component {
    constructor(props) {
        super(props);

        this.handleWindowResizeEvent = this.handleWindowResizeEvent.bind(this);
        this.handleWindowResizeEventThrottled = throttle(
            this.handleWindowResizeEvent,
            100
        );
    }

    componentDidMount() {
        this.props.initApp();

        window.addEventListener(
            'resize',
            this.handleWindowResizeEventThrottled
        );
        this.handleWindowResizeEvent();
    }

    componentWillUnmount() {
        window.removeEventListener(
            'resize',
            this.handleWindowResizeEventThrottled
        );
    }

    handleWindowResizeEvent() {
        const width = document.body.clientWidth;
        const height = document.body.clientHeight;
        let newResponsiveMode = 0;
        if (width < RESPONSIVE_MODES.MEDIUM) {
            newResponsiveMode = RESPONSIVE_MODES.SMALL;
        } else if (width < RESPONSIVE_MODES.FULL) {
            newResponsiveMode = RESPONSIVE_MODES.MEDIUM;
        } else {
            newResponsiveMode = RESPONSIVE_MODES.FULL;
        }
        const newResponsiveOrientation =
            width > height
                ? RESPONSIVE_ORIENTATIONS.LANDSCAPE
                : RESPONSIVE_ORIENTATIONS.PORTRAIT;

        if (
            this.props.responsiveMode !== newResponsiveMode ||
            this.props.responsiveOrientation !== newResponsiveOrientation
        ) {
            this.props.changeResponsiveMode(
                newResponsiveMode,
                newResponsiveOrientation
            );
        }
    }

    render() {
        const reconnectingDialog = this.props.alcReconnecting ? (
            !this.props.isRecorder ? (
                <Dialog
                    header={<TranslatedString id="reconnectingHeader" />}
                    visible={this.props.alcReconnecting}
                    closable={false}
                    onHide={() => {}}
                    baseZIndex={999999}
                >
                    <div className="p-grid">
                        <div className="p-col-12">
                            <TranslatedString id="reconnectingMessage" />
                        </div>
                        <div className="p-col-12 reconnecting-spinner-container">
                            <LoadingScreen />
                        </div>
                    </div>
                </Dialog>
            ) : (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        backgroundColor: 'lightgrey',
                        position: 'absolute',
                        zIndex: 9999999,
                    }}
                />
            )
        ) : null;

        let documentTitle = Config.documentTitle;
        if (
            Config.documentTitleLocales &&
            Config.documentTitleLocales[this.props.language]
        ) {
            documentTitle = Config.documentTitleLocales[this.props.language];
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{documentTitle}</title>
                    {Config.favicon && (
                        <link rel="shortcut icon" href={Config.favicon} />
                    )}
                </Helmet>
                {this.renderContent()}
                {this.props.settingsPanelShown && <DeviceSettings />}
                {reconnectingDialog}
            </React.Fragment>
        );
    }

    renderContent() {
        switch (this.props.appState) {
            case APP_STATE.LOADING: {
                return <LoadingScreen />;
            }
            case APP_STATE.ENTER_MEETING_ID:
            case APP_STATE.DISPLAY_MEETING_INFO:
            case APP_STATE.LEFT_MEETING:
            case APP_STATE.INIT_FAILURE:
            case APP_STATE.DISCLAIMER:
            case APP_STATE.CONTACT_FORM:
            case APP_STATE.CM_ENTER_INFO:
            case APP_STATE.CM_CONNECTING:
            case APP_STATE.AWAIT_ADMITTANCE: {
                return <LandingPage />;
            }
            case APP_STATE.JOINING_MEETING:
            case APP_STATE.IN_MEETING:
            case APP_STATE.RECORDING_CONFIRMATION: {
                return (
                    <React.Fragment>
                        <Meeting />
                        <CustomGrowl topbarHidden />
                    </React.Fragment>
                );
            }
            case APP_STATE.IN_MEETING_STANDALONE: {
                return <MeetingStandalone />;
            }
            case APP_STATE.PODIUM_STREAM: {
                return (
                    <React.Fragment>
                        <LivePlayer />
                        <CustomGrowl topbarHidden />
                    </React.Fragment>
                );
            }
            default: {
                return <div>UNKNOWN APP STATE</div>;
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        appState: state.landingPage.appState,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
        alcReconnecting: state.meetings.alcReconnecting,
        meetingType:
            state.meetings.meetingInfo && state.meetings.meetingInfo.type,
        responsiveMode: state.base.common.responsiveMode,
        responsiveOrientation: state.base.common.responsiveOrientation,
        isRecorder:
            state.meetings.clientInfo && state.meetings.clientInfo.recorder,
        language: state.base.i18n.language,
    };
};

const mapDispatchToProps = {
    initApp,
    changeResponsiveMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Communicator);
