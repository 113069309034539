import React, { Component, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';

import TranslatedString from '../base/i18n/TranslatedString';
import Player from './Player';
import { Config } from '../../config/Config';

function PlayerPanel(props) {
    const playerRef = useRef(null);

    useEffect(() => {
        if (!props.isOnAir) return;

        const useLiveLowLatency = props.streamInfo.isLowLatency;
        if (props.playerInitialized) {
            if (
                props.playerType &&
                ((props.playerType === 'ivs' && !useLiveLowLatency) ||
                    (props.playerType !== 'ivs' && useLiveLowLatency))
            ) {
                playerRef.current.reset({ useLiveLowLatency });
            }
        } else {
            playerRef.current.initialize({ useLiveLowLatency });
        }
    }, [
        props.playerInitialized,
        props.playerType,
        props.isOnAir,
        props.streamInfo,
    ]);

    return (
        <div
            className={classNames('player-panel', {
                'chat-open': props.chatOpen,
                mobile: props.mobileLayout,
            })}
        >
            <Player
                mobileLayout={props.mobileLayout}
                showTopBar={true}
                topBarContent={
                    <PlayerTopBarContent
                        meetingInfo={props.meetingInfo}
                        podiumInfo={props.podiumInfo}
                        displayStream={props.displayStream}
                    />
                }
                showOverlay={true}
                overlayContent={
                    <PlayerOverlayContent
                        podiumInfo={props.podiumInfo}
                        displayStream={props.displayStream}
                        wasOnAir={props.wasOnAir}
                    />
                }
                ref={playerRef}
            />
        </div>
    );
}

function PlayerTopBarContent(props) {
    const [logoError, setLogoError] = useState(false);
    let logo, title;

    const handleLogoError = () => {
        if (!logoError) setLogoError(true);
    };

    if (props.podiumInfo) {
        logo = !logoError && props.podiumInfo.logo; //TODO: which logo should be used?
        title = props.meetingInfo.title;

        return (
            <React.Fragment>
                {!Config.podiumPlayerLogoDisabled && logo && (
                    <div className="logo">
                        <img
                            src={logo || undefined}
                            alt=""
                            onError={handleLogoError}
                        />
                    </div>
                )}

                <div className="title">{title}</div>

                <div className="stream-state">
                    {props.displayStream ? (
                        <span className="stream-state-icon online">
                            <TranslatedString id={'online'} />
                        </span>
                    ) : (
                        <span className="stream-state-icon offline">
                            <TranslatedString id={'offline'} />
                        </span>
                    )}
                </div>
            </React.Fragment>
        );
    }
    return null;
}

class PlayerOverlayContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            posterPreError: false,
            posterPostError: false,
        };
    }

    handlePosterError = (posterType) => () => {
        if (posterType === 'posterPre' && !this.state.posterPreError) {
            this.setState({ posterPreError: true });
        } else if (posterType === 'posterPost' && !this.state.posterPostError) {
            this.setState({ posterPostError: true });
        }
    };

    renderOfflineOverlay() {
        const { podiumInfo, displayStream, wasOnAir } = this.props;
        let poster, posterType;
        if (podiumInfo) {
            if (wasOnAir) {
                poster = podiumInfo.posterPost;
                posterType = 'posterPost';
            } else {
                poster = podiumInfo.posterPre;
                posterType = 'posterPre';
            }
        }
        //poster = 'https://images2.alphacoders.com/103/1039991.jpg';

        return (
            <div
                className={classNames('offline-overlay', {
                    hidden: displayStream,
                })}
            >
                {poster &&
                !(
                    (posterType === 'posterPost' &&
                        this.state.posterPostError) ||
                    (posterType === 'posterPre' && this.state.posterPreError)
                ) ? (
                    <img
                        className="poster-image"
                        src={poster}
                        alt=""
                        onError={this.handlePosterError(posterType)}
                    />
                ) : (
                    <div className="poster">
                        <div className="poster-content">
                            <TranslatedString
                                id={
                                    wasOnAir
                                        ? 'posterTextPost'
                                        : 'posterTextPre'
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    render() {
        return <React.Fragment>{this.renderOfflineOverlay()}</React.Fragment>;
    }
}

const mapStateToProps = (state, props) => {
    const publicServiceInfo = state.landingPage.publicServiceInfo;
    const meetingInfo = state.meetings.meetingInfo;
    const podiumInfo = state.meetings.podiumInfo;
    const streamInfo = state.meetings.streamInfo;

    let logo;
    if (Config.logoOverride) {
        logo = Config.logoOverride;
    } else if (podiumInfo.logo) {
        logo = podiumInfo.logo;
    } else if (publicServiceInfo.logo) {
        logo = publicServiceInfo.logo;
    }
    podiumInfo.logo = logo || null;

    return {
        displayStream: state.livePlayer.displayStream,

        meetingInfo,
        podiumInfo,
        streamInfo,
        isOnAir: state.meetings.isOnAir,
        wasOnAir: state.meetings.wasOnAir,

        playerInitialized: state.player.initialized,

        chatOpen: props.chatOpen,
        mobileLayout: props.mobileLayout || false,
    };
};

export default connect(mapStateToProps, null)(PlayerPanel);
