export const INIT_APP = 'INIT_APP';
export const CHANGE_APP_STATE = 'CHANGE_APP_STATE';
export const EDIT_PARTICIPANT = 'EDIT_PARTICIPANT';
export const NEW_ERROR = 'NEW_ERROR';
export const SET_WEBRTC_FATAL_ERROR = 'SET_WEBRTC_FATAL_ERROR';
export const GET_MEETING_INFO = 'GET_MEETING_INFO';
export const CONFIRM_DISCLAIMER = 'CONFIRM_DISCLAIMER';
export const PARTICIPANT_INFO_UPDATE = 'PARTICIPANT_INFO_UPDATE';

export const PUBLIC_SERVICE_INFO_REQUEST = 'PUBLIC_SERVICE_INFO_REQUEST';
export const PUBLIC_SERVICE_INFO_SUCCESS = 'PUBLIC_SERVICE_INFO_SUCCESS';
export const PUBLIC_SERVICE_INFO_FAILURE = 'PUBLIC_SERVICE_INFO_FAILURE';

export const CONTACT_FORM_REQUEST = 'CONTACT_FORM_REQUEST';
export const CONTACT_FORM_REQUEST_SUCCESS = 'CONTACT_FORM_REQUEST_SUCCESS';
export const CONTACT_FORM_REQUEST_FAILURE = 'CONTACT_FORM_REQUEST_FAILURE';

export const ALC_GET_MEETING_INFO = 'ALC_GET_MEETING_INFO';
export const ALC_GET_MEETING_INFO_SUCCESS = 'ALC_GET_MEETING_INFO_SUCCESS';
export const ALC_SERVICE_STATE_UPDATE = 'ALC_SERVICE_STATE_UPDATE';
export const ALC_START_CALL = 'ALC_START_CALL';

export const INITIATE_CALL = 'INITIATE_CALL';
export const SET_TOPIC_ID = 'SET_TOPIC_ID';

export const ALC_PARTICIPANT_AUTH = 'ALC_PARTICIPANT_AUTH';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
