import * as types from './actionTypes';
import {
    ALC_ERROR,
    JOIN_MEETING,
    JOIN_MEETING_STANDALONE_SUCCESS,
    JOIN_MEETING_SUCCESS,
    JOIN_PODIUM,
    JOIN_PODIUM_SUCCESS,
} from '../meetings/actionTypes';
import { APP_STATE } from '../../constants/constants';
import {
    CONTACT_FORM_REQUEST,
    CONTACT_FORM_REQUEST_FAILURE,
    CONTACT_FORM_REQUEST_SUCCESS,
} from './actionTypes';

export const initialState = {
    appState: APP_STATE.LOADING,

    isLoading: false,

    publicServiceInfo: undefined,

    meetingId: undefined,
    meetingInfo: undefined,
    participantInfo: undefined,
    authenticated: false,
    serverTimeDiff: 0,

    errorId: undefined,
    errorNo: undefined,
    errorData: undefined,
    webRtcSupport: true,
    webRtcFatalError: null,

    contactFormSuccess: false,
    contactFormErrorId: undefined,

    serviceState: undefined,
    topicId: null,
};

export default function landingPage(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_APP_STATE: {
            return {
                ...state,
                appState: action.appState,
                errorId: undefined,
                errorNo: undefined,
                errorData: undefined,
                isLoading: false,
            };
        }
        case types.EDIT_PARTICIPANT: {
            return {
                ...state,
                participantInfo: undefined,
                meetingId: state.meetingInfo._id,
            };
        }
        case types.PARTICIPANT_INFO_UPDATE: {
            return {
                ...state,
                participantInfo: action.participantInfo,
            };
        }
        case types.SET_WEBRTC_FATAL_ERROR: {
            return {
                ...state,
                webRtcSupport: false,
                webRtcFatalError: {
                    errorId: action.errorId,
                    errorNo: action.errorNo,
                    errorData: action.errorData,
                },
            };
        }
        case types.NEW_ERROR: {
            return {
                ...state,
                errorId: action.errorId,
                errorNo: action.errorNo,
                errorData: action.errorData,
            };
        }
        case types.PUBLIC_SERVICE_INFO_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case types.PUBLIC_SERVICE_INFO_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                publicServiceInfo: action.publicServiceInfo,
            };
        }
        case types.PUBLIC_SERVICE_INFO_FAILURE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case types.ALC_GET_MEETING_INFO: {
            return {
                ...state,
                isLoading: true,
                errorId: undefined,
                errorNo: undefined,
                errorData: undefined,
                meetingInfoErrorMessage: undefined,
            };
        }
        case types.ALC_GET_MEETING_INFO_SUCCESS: {
            const meetingId = action.participantInfo
                ? action.participantInfo._id
                : action.meetingInfo._id;

            return {
                ...state,
                isLoading: false,
                meetingInfo: action.meetingInfo,
                participantInfo: action.participantInfo,
                serverTimeDiff: action.serverTime
                    ? action.serverTime - Date.now()
                    : 0,
                meetingId,
            };
        }
        case JOIN_MEETING: {
            return {
                ...state,
                appState: APP_STATE.JOINING_MEETING,
                isLoading: true,
            };
        }
        case JOIN_PODIUM: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ALC_ERROR: {
            if (
                action.context === 'joinMeeting' &&
                action.error.errorNo === 40104
            ) {
                return {
                    ...state,
                    appState: APP_STATE.RECORDING_CONFIRMATION,
                };
            } else {
                return state;
            }
        }
        case JOIN_MEETING_SUCCESS: {
            return {
                ...state,
                appState: APP_STATE.IN_MEETING,
                isLoading: false,
            };
        }
        case JOIN_MEETING_STANDALONE_SUCCESS: {
            return {
                ...state,
                appState: APP_STATE.IN_MEETING_STANDALONE,
                isLoading: false,
            };
        }
        case JOIN_PODIUM_SUCCESS: {
            return {
                ...state,
                appState: APP_STATE.PODIUM_STREAM,
                isLoading: false,
            };
        }
        case CONTACT_FORM_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CONTACT_FORM_REQUEST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                contactFormSuccess: true,
            };
        }
        case CONTACT_FORM_REQUEST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                contactFormSuccess: false,
                contactFormErrorId: action.errorId,
            };
        }
        case types.ALC_SERVICE_STATE_UPDATE: {
            return {
                ...state,
                serviceState: action.serviceState,
            };
        }
        case types.SET_TOPIC_ID: {
            return {
                ...state,
                topicId: action.topicId,
            };
        }
        case types.LOGIN_SUCCESS: {
            return {
                ...state,
                authenticated: true,
            };
        }
        default: {
            return state;
        }
    }
}
