import React, { Component } from 'react';

export default class VideoContainer extends Component {
    constructor(props) {
        super(props);
        this.videoContainerRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.videoView && this.props.videoView.view) {
            this.videoContainerRef.current.appendChild(
                this.props.videoView.view
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.videoView !== this.props.videoView) {
            if (prevProps.videoView) {
                if (
                    prevProps.videoView.view &&
                    this.videoContainerRef.current.contains(
                        prevProps.videoView.view
                    )
                ) {
                    this.videoContainerRef.current.removeChild(
                        prevProps.videoView.view
                    );
                }
            }
            if (this.props.videoView && this.props.videoView.view) {
                this.videoContainerRef.current.appendChild(
                    this.props.videoView.view
                );
            }
        }
    }

    componentWillUnmount() {
        if (
            this.props.videoView &&
            this.props.videoView.view &&
            this.videoContainerRef.current.contains(this.props.videoView.view)
        ) {
            this.videoContainerRef.current.removeChild(
                this.props.videoView.view
            );
        }
    }

    render() {
        return <div className="video-container" ref={this.videoContainerRef} />;
    }
}
