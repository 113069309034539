import favicon from '../assets/favicons/favicon_swr.png';
import React from 'react';
import('../css/themes/Theme_default.scss');

export const Config = {
    serviceUrl: 'https://al5-app11.adiacom.live',
    serviceIdFromUrl: true,
    favicon,
    documentTitle: 'Stadtwerke Rostock | Video-Beratung',

    preJoinDisclaimerEnabled: true,
    preJoinDisclaimerCheckbox: true,
    preJoinDisclaimerContent: {
        de: '',
        en: '',
        fr: '',
        it: '',
    },

    preJoinDisclaimerLabel: {
        de: (
            <span>
                Durch den Start der Videoberatung werden personenbezogene Daten
                verarbeitet. Weitere Informationen erhalten Sie in unserer{' '}
                <a
                    href="https://www.swrag.de/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutzinformation
                </a>
                .
            </span>
        ),
        en: (
            <span>
                Durch den Start der Videoberatung werden personenbezogene Daten
                verarbeitet. Weitere Informationen erhalten Sie in unserer{' '}
                <a
                    href="https://www.swrag.de/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutzinformation
                </a>
                .
            </span>
        ),
        fr: (
            <span>
                Durch den Start der Videoberatung werden personenbezogene Daten
                verarbeitet. Weitere Informationen erhalten Sie in unserer{' '}
                <a
                    href="https://www.swrag.de/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutzinformation
                </a>
                .
            </span>
        ),
        it: (
            <span>
                Durch den Start der Videoberatung werden personenbezogene Daten
                verarbeitet. Weitere Informationen erhalten Sie in unserer{' '}
                <a
                    href="https://www.swrag.de/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutzinformation
                </a>
                .
            </span>
        ),
    },
};
