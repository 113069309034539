import favicon from '../assets/favicons/favicon_bekb.ico';
import { MEETING_TYPES } from '../constants/constants';
import('../css/themes/Theme_bekb.scss');

export const Config = {
    serviceUrl: 'https://al5-bekb.adia.tv',
    serviceId: 'BEKB',
    documentTitle: 'BEKB | BCBE',

    favicon,

    communicatorType: MEETING_TYPES.WEBINAR,

    hideTitleResponsiveSmall: true,
    hideEmailInput: true,
    nameInputOptional: true,
};
