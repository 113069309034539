import React from 'react';

import favicon from '../assets/favicons/favicon_davisio.ico';
import('../css/themes/Theme_enbw.scss');

export const Config = {
    serviceUrl: 'https://al5-netze-bw.adia.tv',
    serviceIdFromUrl: true,

    documentTitle: 'davisio Meet',
    favicon,

    preJoinDisclaimerEnabled: true,
    preJoinDisclaimerCheckbox: false,
    preJoinDisclaimerContent: {
        de: '',
        en: '',
        fr: '',
        it: '',
    },
    preJoinDisclaimerLabel: {
        en: (
            <div>
                <a
                    href={'https://davisio.enbw.com/datenschutz'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Privacy Protection
                </a>
                {' | '}
                <a
                    href={'https://davisio.enbw.com/impressum'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Legal Notice
                </a>
            </div>
        ),
        de: (
            <div>
                <a
                    href={'https://davisio.enbw.com/datenschutz'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutz
                </a>
                {' | '}
                <a
                    href={'https://davisio.enbw.com/impressum'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Impressum
                </a>
            </div>
        ),
        fr: (
            <div>
                <a
                    href={'https://davisio.enbwn.com/datenschutz'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Protection des données
                </a>
                {' | '}
                <a
                    href={'https://davisio.enbw.com/Impressum'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Mentions légales
                </a>
            </div>
        ),
        it: (
            <div>
                <a
                    href={'https://davisio.enbwn.com/datenschutz'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Tutela della privacy
                </a>
                {' | '}
                <a
                    href={'https://davisio.enbw.com/Impressum'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Informazioni legali
                </a>
            </div>
        ),
    },
};
