import 'react-app-polyfill/ie11';
import 'core-js/features/string/includes';
import 'core-js/features/array/find';
import 'core-js/features/url-search-params';
import 'core-js/features/string/repeat';
import 'core-js/features/object/values';
import 'core-js/features/array/includes';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import { createAlcMiddleware } from './middleware/alcMiddleware';
import { createWebRtcLayerMiddleware } from './middleware/webRtcLayerMiddleware';
import { createIFrameMiddleware } from './middleware/iFrameMiddleware';
import { createHelperMiddleware } from './middleware/helperMiddleware';

import './css/index.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';

import rootReducer from './rootReducer';
import al5ApiService from './services/al5ApiService';
import rootSaga from './rootSaga';

import Communicator from './Communicator';

import { getPreloadLanguage } from './features/base/util/helpers';

import { createPlayerMiddleware } from './middleware/playerMiddleware';

const alcMiddleware = createAlcMiddleware();
const webRtcLayerMiddleware = createWebRtcLayerMiddleware();
const iFrameMiddleware = createIFrameMiddleware();
const helperMiddleware = createHelperMiddleware();
const sagaMiddleware = createSagaMiddleware();
const playerMiddleware = createPlayerMiddleware();

const language = getPreloadLanguage();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    {
        base: {
            i18n: {
                language: language,
            },
        },
    },
    composeEnhancers(
        applyMiddleware(
            thunk.withExtraArgument(al5ApiService),
            alcMiddleware,
            webRtcLayerMiddleware,
            playerMiddleware,
            iFrameMiddleware,
            helperMiddleware,
            sagaMiddleware
        )
    )
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <Communicator />
    </Provider>,
    document.getElementById('root')
);
