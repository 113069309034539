import React, { Component } from 'react';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';

import classNames from 'classnames';

import { Config } from '../../config/Config';
import {
    joinMeeting,
    joinMeetingStandalone,
    joinPodium,
} from '../meetings/actions';
import { showSettings, hideSettings } from '../deviceSettings/actions';
import {
    confirmDisclaimer,
    changeAppState,
    initiateCall,
    logout,
} from './actions';
import {
    APP_STATE,
    MEETING_TYPES,
    RESPONSIVE_MODES,
} from '../../constants/constants';
import { getMeetingInfo } from './actions';
import { getTranslatedString } from '../base/i18n/translations';
import TranslatedString from '../base/i18n/TranslatedString';
import { getBrowserCheckMessage } from '../base/util/helpers';
import ContactForm from './ContactForm';
import { adiaLiveSettings } from '../base/util/adiaLiveSettings';
import WaitingPage from './WaitingPage';

export class LandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            draftMeetingId: '',
            draftCaptchaCode: '',
            draftParticipantName: '',
            draftEmail: '',
            joinErrorId: null,
            disclaimerConfirmed: false,
            preJoinDisclaimerConfirmed: false,
        };

        this.handleDraftMeetingIdChange =
            this.handleDraftMeetingIdChange.bind(this);
        this.handleDraftCaptchaCodeChange =
            this.handleDraftCaptchaCodeChange.bind(this);
        this.handleDraftParticipantNameChange =
            this.handleDraftParticipantNameChange.bind(this);
        this.handleDraftEmailChange = this.handleDraftEmailChange.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handleJoinClick = this.handleJoinClick.bind(this);
        this.handleContactFormClick = this.handleContactFormClick.bind(this);
        this.handleStartCMClick = this.handleStartCMClick.bind(this);
        this.handleConnectClick = this.handleConnectClick.bind(this);
        this._recheckTimeout = null;
    }

    componentWillUnmount() {
        this._clearRecheckTimeout();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isLoading && !this.props.isLoading) {
            this.setState({
                draftCaptchaCode: '',
            });
        }
    }

    handleDraftMeetingIdChange(e) {
        this.setState({ draftMeetingId: e.target.value.trim() });
    }
    handleDraftCaptchaCodeChange(e) {
        this.setState({ draftCaptchaCode: e.target.value.trim() });
    }

    handleDraftParticipantNameChange(e) {
        this.setState({ draftParticipantName: e.target.value });
    }

    handleDraftEmailChange(e) {
        this.setState({ draftEmail: e.target.value });
    }

    handleNextClick(e) {
        e.preventDefault();
        this.props.getMeetingInfo(
            this.state.draftMeetingId,
            this.state.draftCaptchaCode
        );
    }

    handleContactFormClick(e) {
        e.preventDefault();
        this.props.changeAppState(APP_STATE.CONTACT_FORM);
    }

    handleStartCMClick(e) {
        e.preventDefault();
        this.props.changeAppState(APP_STATE.CM_ENTER_INFO);
    }

    handleConnectClick(e) {
        e.preventDefault();
        let name = this.state.draftParticipantName;
        let email = this.state.draftEmail;
        if (this.props.participantInfo) {
            this.props.initiateCall(this.props.topicId);
        } else {
            if (
                (!name && !Config.nameInputOptional) ||
                (!email && !Config.hideEmailInput)
            ) {
                this.setState({
                    joinErrorId: 'enterNameAndEmail',
                });
            } else if (!Config.hideEmailInput && !this.validateEmail(email)) {
                this.setState({ joinErrorId: 'invalidEmail' });
            } else {
                if (!name) {
                    // TODO implement better
                    name = 'Gast';
                }
                this.props.initiateCall(this.props.topicId, name, email);
            }
        }
    }

    validateEmail(email) {
        var re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    handleJoinClick(e) {
        e.preventDefault();
        const params = new URLSearchParams(window.location.search);
        const standalone = params.get('standalone');
        const singleApp = params.get('singleApp');
        let name = this.state.draftParticipantName;
        let email = this.state.draftEmail;
        if (this.props.participantInfo) {
            if (standalone || singleApp) {
                this.props.joinMeetingStandalone(this.props.meetingId);
            } else if (this.props.meetingInfo.type !== MEETING_TYPES.PODIUM) {
                this.props.joinMeeting(this.props.meetingId);
            } else {
                this.props.joinPodium(this.props.meetingId);
            }
        } else {
            if (
                (!name && !Config.nameInputOptional) ||
                (!email && !Config.hideEmailInput)
            ) {
                this.setState({
                    joinErrorId: 'enterNameAndEmail',
                });
            } else if (!Config.hideEmailInput && !this.validateEmail(email)) {
                this.setState({ joinErrorId: 'invalidEmail' });
            } else {
                if (!name) {
                    // TODO implement better
                    name = 'Gast';
                }
                if (standalone || singleApp) {
                    this.props.joinMeetingStandalone(
                        this.props.meetingInfo._id,
                        name,
                        email
                    );
                } else if (
                    this.props.meetingInfo.type !== MEETING_TYPES.PODIUM
                ) {
                    this.props.joinMeeting(
                        this.props.meetingInfo._id,
                        name,
                        email
                    );
                } else {
                    this.props.joinPodium(
                        this.props.meetingInfo._id,
                        name,
                        email
                    );
                }
            }
        }
    }

    _startRecheckTimeout(time) {
        this._clearRecheckTimeout();
        this._recheckTimeout = setTimeout(() => {
            this._clearRecheckTimeout();
            this.forceUpdate();
        }, time);
    }

    _clearRecheckTimeout() {
        if (this._recheckTimeout) {
            clearTimeout(this._recheckTimeout);
            this._recheckTimeout = null;
        }
    }

    render() {
        let content = null;
        this._clearRecheckTimeout();

        let errorText;
        if (this.props.errorId) {
            if (this.props.errorNo === 51) {
                errorText = getBrowserCheckMessage(
                    this.props.language,
                    this.props.errorData
                );
            } else {
                errorText = getTranslatedString(
                    this.props.language,
                    this.props.errorId
                );
                if (
                    (this.props.errorId === 'webRtcErrorDefault' ||
                        this.props.errorId === 'alcErrorDefault') &&
                    this.props.errorNo
                ) {
                    errorText += ' (' + this.props.errorNo + ')';
                }
            }
        }

        if (
            this.props.webRtcFatalError &&
            this.props.type !== MEETING_TYPES.NOT_SET &&
            this.props.type !== MEETING_TYPES.PODIUM
        ) {
            errorText = getBrowserCheckMessage(
                this.props.language,
                this.props.webRtcFatalError.errorData
            );
        }

        let translationIdTitle = 'meetingTitle';
        let translationIdMeetingId = 'meetingId';
        let translationIdYourMeeting = 'yourMeeting';
        let translationIdJoin = 'joinMeeting';
        let translationIdYourId = 'yourMeetingId';
        let translationIdEnterId = 'enterMeetingId';
        let translationIdNotReady = 'meetingNotReady';
        let translationIdExpired = 'meetingExpired';
        let translationIdCompleted = 'meetingCompleted';

        if (
            this.props.type === MEETING_TYPES.WEBINAR ||
            this.props.type === MEETING_TYPES.PODIUM
        ) {
            translationIdTitle = 'webinarTitle';
            translationIdMeetingId = 'webinarId';
            translationIdYourMeeting = 'yourWebinar';
            translationIdJoin = 'joinWebinar';
            translationIdYourId = 'yourWebinarId';
            translationIdEnterId = 'enterWebinarId';
            translationIdNotReady = 'webinarNotReady';
            translationIdExpired = 'webinarExpired';
            translationIdCompleted = 'webinarCompleted';
        }
        if (
            this.props.type === MEETING_TYPES.PHONE_CONSULTING ||
            this.props.appState === APP_STATE.CM_ENTER_INFO
        ) {
            translationIdTitle = 'consultationTitle';
            translationIdMeetingId = 'consultationId';
            translationIdYourMeeting = 'yourConsultation';
            translationIdJoin = 'joinConsultation';
            translationIdYourId = 'yourConsultationId';
            translationIdEnterId = 'enterConsultationId';
            translationIdNotReady = 'consultationNotReady';
            translationIdExpired = 'consultationExpired';
            translationIdCompleted = 'consultationCompleted';
        }

        if (
            (this.props.appState === APP_STATE.INIT_FAILURE ||
                !this.props.webRtcSupport) &&
            errorText
        ) {
            content = (
                <div className="msgBox">
                    <div className="contentPart">
                        <img
                            className="msgIcon"
                            src={Config.landingPageImage}
                            alt={'icon'}
                        />
                        {errorText && (
                            <div className="p-col errorMsg">
                                <Message severity="warn" text={errorText} />
                            </div>
                        )}
                    </div>
                </div>
            );
        } else {
            if (this.props.appState === APP_STATE.ENTER_MEETING_ID) {
                content = (
                    <div className="msgBox">
                        <div className="contentPart">
                            <img
                                className="msgIcon"
                                src={Config.landingPageImage}
                                alt={'icon'}
                            />
                            <div className="msgTitle">
                                <TranslatedString id={translationIdYourId} />
                            </div>
                            <div className="msgText">
                                <TranslatedString id={translationIdEnterId} />
                            </div>
                            <form
                                className="userInput"
                                onSubmit={this.handleNextClick}
                            >
                                {errorText && (
                                    <div className="p-col errorMsg">
                                        <Message
                                            severity="warn"
                                            text={errorText}
                                        />
                                    </div>
                                )}
                                {this.props.errorId ===
                                    'alcErrorMeetingNeedsCaptchaCode' && (
                                    <div className="p-col inputFields">
                                        <div
                                            className="captchaCodeWrapper"
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.errorData
                                                    .captcha,
                                            }}
                                        />
                                        <span
                                            className="p-float-label"
                                            style={{
                                                marginTop: 12,
                                                marginBottom: 12,
                                            }}
                                        >
                                            <InputText
                                                id="captchaCode"
                                                value={
                                                    this.state.draftCaptchaCode
                                                }
                                                onChange={
                                                    this
                                                        .handleDraftCaptchaCodeChange
                                                }
                                                disabled={this.props.isLoading}
                                            />
                                            <label htmlFor="captchaCode">
                                                <TranslatedString id="captchaCode" />
                                            </label>
                                        </span>
                                    </div>
                                )}
                                <div className="p-col inputFields">
                                    <span className="p-float-label">
                                        <InputText
                                            id="meetingId"
                                            value={this.state.draftMeetingId}
                                            onChange={
                                                this.handleDraftMeetingIdChange
                                            }
                                            disabled={this.props.isLoading}
                                        />
                                        <label htmlFor="meetingId">
                                            <TranslatedString
                                                id={translationIdMeetingId}
                                            />
                                        </label>
                                    </span>
                                </div>
                                <div className="p-col inputButtons">
                                    <Button
                                        label={getTranslatedString(
                                            this.props.language,
                                            'continue'
                                        )}
                                        onClick={this.handleNextClick}
                                        disabled={
                                            !this.state.draftMeetingId ||
                                            this.props.isLoading
                                        }
                                    />
                                </div>
                                {this.props.callManagerActive &&
                                    this.props.adviserReady && (
                                        <React.Fragment>
                                            <div className="msgText">
                                                <TranslatedString id="callManagerStartText" />
                                            </div>
                                            <div className="p-col inputButtons">
                                                <Button
                                                    label={getTranslatedString(
                                                        this.props.language,
                                                        'connect'
                                                    )}
                                                    onClick={
                                                        this.handleStartCMClick
                                                    }
                                                    disabled={
                                                        this.props.isLoading
                                                    }
                                                />
                                            </div>
                                        </React.Fragment>
                                    )}
                                {this.props.enableCommunicatorContactForm && (
                                    <React.Fragment>
                                        <div className="msgText">
                                            <TranslatedString id="contactFormText" />
                                        </div>
                                        <div className="p-col inputButtons">
                                            <Button
                                                label={getTranslatedString(
                                                    this.props.language,
                                                    'contactForm'
                                                )}
                                                onClick={
                                                    this.handleContactFormClick
                                                }
                                                disabled={this.props.isLoading}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </form>
                        </div>
                    </div>
                );
            } else if (this.props.appState === APP_STATE.DISPLAY_MEETING_INFO) {
                const date = this.props.meetingInfo.date
                    ? new Date(this.props.meetingInfo.date)
                    : null;
                const dateMin = this.props.meetingInfo.date - 15 * 60 * 1000; // 15 minutes before start
                const dateMax =
                    this.props.meetingInfo.date +
                    this.props.meetingInfo.durationInMinutes * 60 * 1000 +
                    60 * 60 * 1000; // 60 Minutes after planed end
                const now = Date.now() + this.props.serverTimeDiff;

                // too early
                if (
                    this.props.meetingInfo.type !==
                        MEETING_TYPES.OUTLOOK_MEETING &&
                    now < dateMin
                ) {
                    content = (
                        <div className="msgBox">
                            <div className="contentPart">
                                <img
                                    className="msgIcon"
                                    src={Config.landingPageImage}
                                    alt={'icon'}
                                />
                                <div className="msgTitle">
                                    <TranslatedString
                                        id={translationIdYourMeeting}
                                    />
                                    : {this.props.meetingInfo.title}
                                </div>
                                <div className="msgText">
                                    {getTranslatedString(
                                        this.props.language,
                                        translationIdNotReady
                                    )
                                        .replace(
                                            '{{date}}',
                                            dateFormat(date, 'dd.mm.yyyy')
                                        )
                                        .replace(
                                            '{{time}}',
                                            dateFormat(date, 'HH:MM')
                                        )}
                                </div>
                            </div>
                        </div>
                    );
                    const timeCheck = dateMin - now + 1000;
                    this._startRecheckTimeout(timeCheck); // todo: use an other approach here.. this is kind of ugly

                    // too late
                } else if (
                    this.props.meetingInfo.type !==
                        MEETING_TYPES.OUTLOOK_MEETING &&
                    now > dateMax
                ) {
                    content = (
                        <div className="msgBox">
                            <div className="contentPart">
                                <img
                                    className="msgIcon"
                                    src={Config.landingPageImage}
                                    alt={'icon'}
                                />
                                <div className="msgTitle">
                                    <TranslatedString
                                        id={translationIdYourMeeting}
                                    />
                                    : {this.props.meetingInfo.title}
                                </div>
                                <div className="msgText">
                                    {dateFormat(date, 'dd.mm.yyyy, HH:MM')}
                                    <br />
                                    <TranslatedString
                                        id={translationIdExpired}
                                    />
                                </div>
                            </div>
                        </div>
                    );

                    // no date error
                } else if (
                    !date &&
                    this.props.meetingInfo.type !==
                        MEETING_TYPES.OUTLOOK_MEETING
                ) {
                    content = (
                        <div className="msgBox">
                            <div className="contentPart">
                                <div className="p-col errorMsg">
                                    <Message
                                        severity="warn"
                                        text={getTranslatedString(
                                            this.props.language,
                                            'alcErrorMeetingDoesNotExist'
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                    // ok
                } else {
                    content = (
                        <div className="msgBox">
                            <div className="contentPart">
                                <img
                                    className="msgIcon"
                                    src={Config.landingPageImage}
                                    alt={'icon'}
                                />
                                <div className="msgTitle">
                                    <TranslatedString
                                        id={translationIdYourMeeting}
                                    />
                                    : {this.props.meetingInfo.title}
                                </div>
                                {date && (
                                    <div className="msgText">
                                        {dateFormat(date, 'dd.mm.yyyy, HH:MM')}
                                    </div>
                                )}
                                <form
                                    className="userInput"
                                    onSubmit={this.handleJoinClick}
                                >
                                    {this.state.joinErrorId && !errorText && (
                                        <div className="p-col errorMsg">
                                            <Message
                                                severity="warn"
                                                text={getTranslatedString(
                                                    this.props.language,
                                                    this.state.joinErrorId
                                                )}
                                            />
                                        </div>
                                    )}
                                    {errorText && (
                                        <div className="p-col errorMsg">
                                            <Message
                                                severity="warn"
                                                text={errorText}
                                            />
                                        </div>
                                    )}
                                    {!this.props.participantInfo && (
                                        <div className="p-col inputFields">
                                            <span className="p-float-label">
                                                <InputText
                                                    id="participantName"
                                                    value={
                                                        this.state
                                                            .draftParticipantName
                                                    }
                                                    onChange={
                                                        this
                                                            .handleDraftParticipantNameChange
                                                    }
                                                    disabled={
                                                        this.props.isLoading
                                                    }
                                                />
                                                <label htmlFor="participantName">
                                                    <TranslatedString id="yourName" />
                                                </label>
                                            </span>
                                        </div>
                                    )}
                                    {!this.props.participantInfo &&
                                        !Config.hideEmailInput && (
                                            <div
                                                className="p-col inputFields"
                                                style={{ marginTop: 8 }}
                                            >
                                                <span className="p-float-label">
                                                    <InputText
                                                        id="email"
                                                        value={
                                                            this.state
                                                                .draftEmail
                                                        }
                                                        onChange={
                                                            this
                                                                .handleDraftEmailChange
                                                        }
                                                        disabled={
                                                            this.props.isLoading
                                                        }
                                                    />
                                                    <label htmlFor="email">
                                                        <TranslatedString id="yourEmail" />
                                                    </label>
                                                </span>
                                            </div>
                                        )}
                                    {Config.preJoinDisclaimerEnabled && (
                                        <div className="preJoinDisclaimerBox">
                                            <div className="p-col">
                                                {Config.preJoinDisclaimerContent &&
                                                    Config
                                                        .preJoinDisclaimerContent[
                                                        this.props.language
                                                    ]}
                                            </div>
                                            <div className="p-col">
                                                {Config.preJoinDisclaimerCheckbox && (
                                                    <Checkbox
                                                        inputId="disclaimer"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                preJoinDisclaimerConfirmed:
                                                                    e.checked,
                                                            })
                                                        }
                                                        checked={
                                                            this.state
                                                                .preJoinDisclaimerConfirmed
                                                        }
                                                    />
                                                )}
                                                <label
                                                    htmlFor="disclaimer"
                                                    className="p-checkbox-label"
                                                >
                                                    {Config.preJoinDisclaimerLabel &&
                                                        Config
                                                            .preJoinDisclaimerLabel[
                                                            this.props.language
                                                        ]}
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                    <div className="p-col inputButtons">
                                        <Button
                                            label={getTranslatedString(
                                                this.props.language,
                                                translationIdJoin
                                            )}
                                            disabled={
                                                (Config.preJoinDisclaimerEnabled &&
                                                    Config.preJoinDisclaimerCheckbox &&
                                                    !this.state
                                                        .preJoinDisclaimerConfirmed) ||
                                                this.props.isLoading
                                            }
                                            onClick={this.handleJoinClick}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    );
                }
            } else if (this.props.appState === APP_STATE.CM_ENTER_INFO) {
                if (this.props.adviserAvailable) {
                    content = (
                        <div className="msgBox">
                            <div className="contentPart">
                                <img
                                    className="msgIcon"
                                    src={Config.landingPageImage}
                                    alt={'icon'}
                                />
                                <div className="msgTitle">
                                    <TranslatedString
                                        id={translationIdYourMeeting}
                                    />
                                </div>
                                <form
                                    className="userInput"
                                    onSubmit={this.handleJoinClick}
                                >
                                    {this.state.joinErrorId && !errorText && (
                                        <div className="p-col errorMsg">
                                            <Message
                                                severity="warn"
                                                text={getTranslatedString(
                                                    this.props.language,
                                                    this.state.joinErrorId
                                                )}
                                            />
                                        </div>
                                    )}
                                    {errorText && (
                                        <div className="p-col errorMsg">
                                            <Message
                                                severity="warn"
                                                text={errorText}
                                            />
                                        </div>
                                    )}
                                    {!this.props.participantInfo && (
                                        <div className="p-col inputFields">
                                            <span className="p-float-label">
                                                <InputText
                                                    id="participantName"
                                                    value={
                                                        this.state
                                                            .draftParticipantName
                                                    }
                                                    onChange={
                                                        this
                                                            .handleDraftParticipantNameChange
                                                    }
                                                    disabled={
                                                        this.props.isLoading
                                                    }
                                                />
                                                <label htmlFor="participantName">
                                                    <TranslatedString id="yourName" />
                                                </label>
                                            </span>
                                        </div>
                                    )}
                                    {!this.props.participantInfo &&
                                        !Config.hideEmailInput && (
                                            <div
                                                className="p-col inputFields"
                                                style={{ marginTop: 8 }}
                                            >
                                                <span className="p-float-label">
                                                    <InputText
                                                        id="email"
                                                        value={
                                                            this.state
                                                                .draftEmail
                                                        }
                                                        onChange={
                                                            this
                                                                .handleDraftEmailChange
                                                        }
                                                        disabled={
                                                            this.props.isLoading
                                                        }
                                                    />
                                                    <label htmlFor="email">
                                                        <TranslatedString id="yourEmail" />
                                                    </label>
                                                </span>
                                            </div>
                                        )}
                                    {Config.preJoinDisclaimerEnabled && (
                                        <div className="preJoinDisclaimerBox">
                                            <div className="p-col">
                                                {Config.preJoinDisclaimerContent &&
                                                    Config
                                                        .preJoinDisclaimerContent[
                                                        this.props.language
                                                    ]}
                                            </div>
                                            <div className="p-col">
                                                <Checkbox
                                                    inputId="disclaimer"
                                                    onChange={(e) =>
                                                        this.setState({
                                                            preJoinDisclaimerConfirmed:
                                                                e.checked,
                                                        })
                                                    }
                                                    checked={
                                                        this.state
                                                            .preJoinDisclaimerConfirmed
                                                    }
                                                />
                                                <label
                                                    htmlFor="disclaimer"
                                                    className="p-checkbox-label"
                                                >
                                                    {Config.preJoinDisclaimerLabel &&
                                                        Config
                                                            .preJoinDisclaimerLabel[
                                                            this.props.language
                                                        ]}
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                    <div className="p-col inputButtons">
                                        <Button
                                            label={getTranslatedString(
                                                this.props.language,
                                                'connect'
                                            )}
                                            disabled={
                                                (Config.preJoinDisclaimerEnabled &&
                                                    Config.preJoinDisclaimerCheckbox &&
                                                    !this.state
                                                        .preJoinDisclaimerConfirmed) ||
                                                this.props.isLoading
                                            }
                                            onClick={this.handleConnectClick}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    );
                } else {
                    content = (
                        <div className="msgBox">
                            <div className="contentPart">
                                <img
                                    className="msgIcon"
                                    src={Config.landingPageImage}
                                    alt={'icon'}
                                />
                                <div className="msgTitle">
                                    <TranslatedString id="noAdviserTitle" />
                                </div>
                                <div className="msgText">
                                    <TranslatedString id="noAdviserLine1" />
                                </div>
                            </div>
                            {this.props.enableCommunicatorContactForm && (
                                <React.Fragment>
                                    <br />
                                    <div className="msgText">
                                        <TranslatedString id="contactFormText" />
                                    </div>
                                    <div className="p-col inputButtons">
                                        <Button
                                            label={getTranslatedString(
                                                this.props.language,
                                                'contactForm'
                                            )}
                                            onClick={
                                                this.handleContactFormClick
                                            }
                                            disabled={this.props.isLoading}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    );
                }
            } else if (this.props.appState === APP_STATE.CM_CONNECTING) {
                content = <WaitingPage />;
            } else if (this.props.appState === APP_STATE.AWAIT_ADMITTANCE) {
                content = (
                    <div className="msgBox">
                        <div className="contentPart">
                            <img
                                className="msgIcon"
                                src={Config.landingPageImage}
                                alt={'icon'}
                            />
                            <div className="msgTitle">
                                <TranslatedString id={'awaitAdmittanceTitle'} />
                            </div>
                            <div className="msgText">
                                <TranslatedString id={'awaitAdmittanceText'} />
                            </div>
                            <ProgressSpinner className={'landingPageSpinner'} />
                        </div>
                    </div>
                );
            } else if (this.props.appState === APP_STATE.LEFT_MEETING) {
                if (Config.postMeetingIFrameUrl) {
                    content = (
                        <iframe
                            className="post-meeting-iframe"
                            title="Meeting Survey"
                            src={Config.postMeetingIFrameUrl}
                        />
                    );
                } else {
                    content = (
                        <div className="msgBox">
                            <div className="contentPart">
                                <img
                                    className="msgIcon"
                                    src={Config.landingPageImage}
                                    alt={'icon'}
                                />
                                <div className="msgTitle">
                                    <TranslatedString
                                        id={translationIdCompleted}
                                    />
                                </div>
                                {errorText && (
                                    <div className="p-col errorMsg">
                                        <Message
                                            severity="warn"
                                            text={errorText}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                }
            } else if (this.props.appState === APP_STATE.DISCLAIMER) {
                content = (
                    <div className="disclaimerBox">
                        <div className="p-col-12">
                            {Config.disclaimerContent &&
                                Config.disclaimerContent[this.props.language]}
                        </div>
                        <div className="p-col-12">
                            <Checkbox
                                inputId="disclaimer"
                                onChange={(e) =>
                                    this.setState({
                                        disclaimerConfirmed: e.checked,
                                    })
                                }
                                checked={this.state.disclaimerConfirmed}
                            />
                            <label
                                htmlFor="disclaimer"
                                className="p-checkbox-label"
                            >
                                {Config.disclaimerLabel &&
                                    Config.disclaimerLabel[this.props.language]}
                            </label>
                        </div>
                        <div className="p-col-12">
                            <Button
                                label={getTranslatedString(
                                    this.props.language,
                                    'continue'
                                )}
                                disabled={!this.state.disclaimerConfirmed}
                                onClick={this.props.confirmDisclaimer}
                            />
                        </div>
                    </div>
                );
            } else if (this.props.appState === APP_STATE.CONTACT_FORM) {
                content = <ContactForm />;
            }
        }

        const settingsButtonDisplayed =
            this.props.webRtcSupport &&
            this.props.appState !== APP_STATE.LEFT_MEETING &&
            this.props.appState !== APP_STATE.INIT_FAILURE &&
            this.props.appState !== APP_STATE.DISCLAIMER;

        const settingsButton =
            settingsButtonDisplayed &&
            !Config.phoneConsultingAudioVideoDisabled ? (
                <div className="landingIcon" aria-label="Settings">
                    {this.props.settingsPanelShown ? (
                        <i
                            className="icon-settings toggled"
                            onClick={this.props.hideSettings}
                        />
                    ) : (
                        <i
                            className="icon-settings"
                            onClick={() => this.props.showSettings()}
                        />
                    )}
                </div>
            ) : null;

        const adviserLink =
            this.props.meetingInfo &&
            this.props.meetingInfo.type === MEETING_TYPES.OUTLOOK_MEETING &&
            this.props.publicServiceInfo.meetingsSettings &&
            this.props.meetingInfo._id
                ? this.props.publicServiceInfo.meetingsSettings.adviserUrl.replace(
                      '{{id}}',
                      this.props.meetingInfo._id
                  )
                : null;
        const adviserLinkButton = adviserLink ? (
            <div className="landingIcon second" aria-label="Adviser Link">
                <a href={adviserLink} target="_blank" rel="noopener noreferrer">
                    <i className="icon-open_in_new" />
                </a>
            </div>
        ) : null;

        const logoutButton = this.props.authenticated ? (
            <div
                className={classNames('landingIcon', {
                    second: settingsButtonDisplayed,
                })}
            >
                <span className="landingName">
                    {this.props.participantInfo
                        ? this.props.participantInfo.name
                        : ''}
                </span>
                <i className="pi-md-exit-to-app" onClick={this.props.logout} />
            </div>
        ) : null;

        const footerEnabled =
            Config.footerEnabled &&
            this.props.appState !== APP_STATE.DISCLAIMER;

        return (
            <div className="page">
                <div className="pageHeader">
                    {this.props.logo && (
                        <img
                            src={this.props.logo}
                            className="landingLogo"
                            alt="Logo"
                        />
                    )}
                    {(this.props.responsiveMode > RESPONSIVE_MODES.SMALL ||
                        (!Config.hideTitleResponsiveSmall &&
                            !this.props.authenticated &&
                            this.props.responsiveMode <
                                RESPONSIVE_MODES.MEDIUM)) && (
                        <div className="headerTitle">
                            <TranslatedString id={translationIdTitle} />
                        </div>
                    )}
                    {adviserLinkButton}
                    {settingsButton}
                    {logoutButton}
                </div>
                <div
                    className={
                        footerEnabled
                            ? 'msgContainer footerEnabled'
                            : 'msgContainer'
                    }
                >
                    {content}
                    {footerEnabled && Config.footerContent[this.props.language]}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let type = MEETING_TYPES.NOT_SET;
    if (state.landingPage.meetingInfo && state.landingPage.meetingInfo.type) {
        type = state.landingPage.meetingInfo.type;
    } else if (Config.communicatorType) {
        type = Config.communicatorType;
    }

    let logo;
    if (Config.logoOverride) {
        logo = Config.logoOverride;
    } else if (state.landingPage.publicServiceInfo) {
        if (state.landingPage.publicServiceInfo.landingPageLogo) {
            logo = state.landingPage.publicServiceInfo.landingPageLogo;
        } else {
            logo = state.landingPage.publicServiceInfo.logo;
        }
    }

    const flags =
        state.landingPage.publicServiceInfo &&
        state.landingPage.publicServiceInfo.flags;
    const callManagerActive = flags
        ? adiaLiveSettings.flagsToSettings(flags).callManager
        : false;

    let adviserAvailable = false;
    let adviserReady = false;
    if (callManagerActive) {
        if (state.landingPage.serviceState) {
            if (state.landingPage.topicId) {
                const topicServiceState =
                    state.landingPage.serviceState.topics.find(
                        (el) => el.topicId === state.landingPage.topicId
                    );
                adviserAvailable =
                    topicServiceState &&
                    topicServiceState.connectedAdvisers > 0;
                adviserReady =
                    topicServiceState &&
                    topicServiceState.readyTextAudioVideo > 0;
            } else {
                const allServiceState =
                    state.landingPage.serviceState.topics.find(
                        (el) => el.topicId === 'all'
                    );
                adviserAvailable =
                    allServiceState && allServiceState.connectedAdvisers > 0;
                adviserReady =
                    allServiceState && allServiceState.readyTextAudioVideo > 0;
            }
        }
    }

    return {
        type,
        isLoading: state.landingPage.isLoading,
        meetingId: state.landingPage.meetingId,
        appState: state.landingPage.appState,
        meetingInfo: state.landingPage.meetingInfo,
        serverTimeDiff: state.landingPage.serverTimeDiff,
        participantInfo: state.landingPage.participantInfo,
        errorId: state.landingPage.errorId,
        errorNo: state.landingPage.errorNo,
        webRtcSupport: state.landingPage.webRtcSupport,
        webRtcFatalError: state.landingPage.webRtcFatalError,
        errorData: state.landingPage.errorData,
        logo,
        publicServiceInfo: state.landingPage.publicServiceInfo,
        enableCommunicatorContactForm:
            state.landingPage.publicServiceInfo &&
            state.landingPage.publicServiceInfo.meetingsSettings
                ? state.landingPage.publicServiceInfo.meetingsSettings
                      .enableCommunicatorContactForm
                : false,
        callManagerActive,
        adviserAvailable,
        adviserReady,
        topicId: state.landingPage.topicId,
        language: state.base.i18n.language,
        responsiveMode: state.base.common.responsiveMode,
        authenticated: state.landingPage.authenticated,
    };
};

const mapDispatchToProps = {
    joinMeeting,
    joinMeetingStandalone,
    joinPodium,
    getMeetingInfo,
    showSettings,
    hideSettings,
    confirmDisclaimer,
    changeAppState,
    initiateCall,
    logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
