import {
    SA_IFRAME_NEW_REF,
    SA_IFRAME_LOAD,
    SA_SHOW,
    SA_SWITCH_OWN,
    SA_HIDE,
    SA_IFRAME_READY,
    ALC_JOIN_MEETING_SUCCESS,
    ALC_MEETING_INFO_UPDATE,
    ALC_CLIENT_INFO_UPDATE,
    SA_IFRAME_INIT_CONFIRMED,
    ALC_CHANGE_SPEAKER_PERMISSION,
    SIDE_PANEL_IFRAME_READY,
    SIDE_PANEL_IFRAME_NEW_REF,
    SIDE_PANEL_IFRAME_INIT_CONFIRMED,
    SIDE_PANEL_IFRAME_LOAD,
    SIDE_PANEL_SHOW_APPLICATION,
} from '../features/meetings/actionTypes';
import { CHANGE_LANGUAGE } from '../features/base/i18n/actionTypes';
import {
    sharedApplicationsIFrameReady,
    documentsNewUpload,
    chatNewMessage,
    sharedApplicationsIFrameInitConfirmed,
    sidePanelIFrameInitConfirmed,
    sidePanelFrameReady,
    streamChatNewMessage,
    streamChatNewDocument,
    streamChatNewSurvey,
} from '../features/meetings/actions';
import {
    SA_NAMESPACE,
    SA_IDENTIFIERS,
    SA_APPLICATIONS,
    IFRAME_COMMANDS,
    IFRAME_INIT_INTERVAL,
    AL_APPLICATIONS,
} from '../constants/constants';
import { log } from '../features/base/util/helpers';

export const createIFrameMiddleware = () => {
    let saIFrame = null;
    let sidePanelIFrame = null;

    let saAuth = null;

    let saInitInterval = null;
    let sidePanelInitInterval = null;

    function sendMessageToSAIFrame(msg) {
        if (saIFrame && saIFrame.contentWindow) {
            msg.namespace = SA_NAMESPACE;
            msg.identifier = SA_IDENTIFIERS.SHARED_APPLICATIONS;
            saIFrame.contentWindow.postMessage(msg, '*');
            log.debug(msg);
        }
    }

    function sendSaInitMessage(language, recorder, meetingInfo) {
        if (saAuth) {
            sendMessageToSAIFrame({
                cmd: IFRAME_COMMANDS.INIT,
                language: language,
                participantInfo: saAuth.participantInfo,
                meetingInfo: saAuth.meetingInfo,
                token: saAuth.token,
                recorder,
            });
        } else {
            sendMessageToSAIFrame({
                cmd: IFRAME_COMMANDS.INIT,
                language: language,
                meetingInfo,
            });
        }
    }

    function sendMessageToSidePanelIFrame(msg) {
        if (sidePanelIFrame && sidePanelIFrame.contentWindow) {
            msg.namespace = SA_NAMESPACE;
            msg.identifier = SA_IDENTIFIERS.SIDE_PANEL;
            sidePanelIFrame.contentWindow.postMessage(msg, '*');
            log.debug(msg);
        }
    }

    function sendSidePanelInitMessage(language, meetingInfo) {
        if (saAuth) {
            sendMessageToSidePanelIFrame({
                cmd: IFRAME_COMMANDS.INIT,
                language: language,
                participantInfo: saAuth.participantInfo,
                meetingInfo: saAuth.meetingInfo,
                token: saAuth.token,
            });
        } else {
            sendMessageToSidePanelIFrame({
                cmd: IFRAME_COMMANDS.INIT,
                language: language,
                meetingInfo,
            });
        }
    }

    const saMessageEventHandler = (store) => (event) => {
        if (
            event.data &&
            event.data.namespace === SA_NAMESPACE &&
            event.data.identifier === SA_IDENTIFIERS.SHARED_APPLICATIONS
        ) {
            log.debug(event.data);
            switch (event.data.cmd) {
                case IFRAME_COMMANDS.INIT_CONFIRMED: {
                    store.dispatch(sharedApplicationsIFrameInitConfirmed());
                    break;
                }
                case IFRAME_COMMANDS.READY: {
                    store.dispatch(sharedApplicationsIFrameReady());
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    const sidePanelMessageEventHandler = (store) => (event) => {
        if (
            event.data &&
            event.data.namespace === SA_NAMESPACE &&
            event.data.identifier === SA_IDENTIFIERS.SIDE_PANEL
        ) {
            log.debug(event.data);
            switch (event.data.cmd) {
                case IFRAME_COMMANDS.INIT_CONFIRMED: {
                    store.dispatch(sidePanelIFrameInitConfirmed());
                    break;
                }
                case IFRAME_COMMANDS.READY: {
                    store.dispatch(sidePanelFrameReady());
                    break;
                }
                case IFRAME_COMMANDS.CHAT_NEW_MESSAGE: {
                    store.dispatch(streamChatNewMessage());
                    store.dispatch(chatNewMessage());
                    break;
                }
                case IFRAME_COMMANDS.DOCUMENTS_NEW_UPLOAD: {
                    store.dispatch(streamChatNewDocument());
                    store.dispatch(documentsNewUpload());
                    break;
                }
                case IFRAME_COMMANDS.NEW_SURVEY: {
                    store.dispatch(streamChatNewSurvey());
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    const hasEditMode = function (state, applicationId) {
        return (
            state.meetings.clientInfo.isAdviser ||
            (state.meetings.clientInfo.editPermissions &&
                state.meetings.clientInfo.editPermissions.includes(
                    applicationId
                ))
        );
    };

    let saEventHandlerRef;
    let sidePanelEventHandlerRef;

    return (store) => (next) => (action) => {
        const state = store.getState();

        switch (action.type) {
            /* Shared Applications main iFrame */
            case SA_IFRAME_NEW_REF:
                if (action.iFrameRef && action.iFrameRef.current) {
                    saIFrame = action.iFrameRef.current;
                } else {
                    saIFrame = null;
                }
                if (saInitInterval) {
                    clearInterval(saInitInterval);
                    saInitInterval = null;
                }
                if (saEventHandlerRef) {
                    window.removeEventListener('message', saEventHandlerRef);
                    saEventHandlerRef = null;
                }
                return next(action);
            case SA_IFRAME_LOAD:
                if (saEventHandlerRef) {
                    window.removeEventListener('message', saEventHandlerRef);
                }
                saEventHandlerRef = saMessageEventHandler(store);
                window.addEventListener('message', saEventHandlerRef);
                if (saInitInterval) {
                    clearInterval(saInitInterval);
                }
                saInitInterval = setInterval(
                    () =>
                        sendSaInitMessage(
                            state.base.i18n.language,
                            !!state.meetings.clientInfo.recorder,
                            state.meetings.meetingInfo
                        ),
                    IFRAME_INIT_INTERVAL
                );
                return next(action);
            case SA_IFRAME_INIT_CONFIRMED: {
                clearInterval(saInitInterval);
                saInitInterval = null;
                return next(action);
            }
            case SA_IFRAME_READY: {
                if (state.meetings.ownApplicationId) {
                    sendMessageToSAIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application: state.meetings.ownApplicationId,
                        editMode: hasEditMode(
                            state,
                            state.meetings.ownApplicationId
                        ),
                        language: state.base.i18n.language,
                    });
                } else if (state.meetings.meetingInfo.currentApp) {
                    sendMessageToSAIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application: state.meetings.meetingInfo.currentApp,
                        editMode: hasEditMode(
                            state,
                            state.meetings.meetingInfo.currentApp
                        ),
                        language: state.base.i18n.language,
                    });
                }
                sendMessageToSAIFrame({
                    cmd: IFRAME_COMMANDS.CURRENT_APP,
                    currentApp: state.meetings.meetingInfo.currentApp,
                });
                return next(action);
            }
            case ALC_JOIN_MEETING_SUCCESS: {
                saAuth = action.saAuth;
                return next(action);
            }
            case ALC_CHANGE_SPEAKER_PERMISSION: {
                if (action.saAuth) {
                    saAuth = action.saAuth;
                }
                return next(action);
            }
            case ALC_MEETING_INFO_UPDATE: {
                if (state.meetings.saIFrameReady) {
                    if (
                        action.meetingInfo.currentApp &&
                        !state.meetings.ownApplicationId
                    ) {
                        sendMessageToSAIFrame({
                            cmd: IFRAME_COMMANDS.SHOW,
                            application: action.meetingInfo.currentApp,
                            editMode: hasEditMode(
                                state,
                                action.meetingInfo.currentApp
                            ),
                            language: state.base.i18n.language,
                        });
                    } else if (
                        state.meetings.meetingInfo.currentApp &&
                        !action.meetingInfo.currentApp
                    ) {
                        sendMessageToSAIFrame({
                            cmd: IFRAME_COMMANDS.HIDE,
                        });
                    }
                    if (
                        action.meetingInfo.currentApp !==
                        state.meetings.meetingInfo.currentApp
                    ) {
                        sendMessageToSAIFrame({
                            cmd: IFRAME_COMMANDS.CURRENT_APP,
                            currentApp: action.meetingInfo.currentApp,
                        });
                    }
                }
                return next(action);
            }
            case ALC_CLIENT_INFO_UPDATE: {
                // if sa is active and editMode changed, send message to iFrame
                if (
                    state.meetings.saIFrameReady &&
                    state.meetings.meetingInfo &&
                    state.meetings.meetingInfo.currentApp
                ) {
                    const hadEditPermission = state.meetings.clientInfo
                        .editPermissions
                        ? state.meetings.clientInfo.editPermissions.includes(
                              state.meetings.meetingInfo.currentApp
                          )
                        : false;
                    const hasEditPermission = action.clientInfo.editPermissions
                        ? action.clientInfo.editPermissions.includes(
                              state.meetings.meetingInfo.currentApp
                          )
                        : false;
                    if (hadEditPermission !== hasEditPermission) {
                        sendMessageToSAIFrame({
                            cmd: IFRAME_COMMANDS.SHOW,
                            application: state.meetings.meetingInfo.currentApp,
                            editMode: hasEditPermission,
                            language: state.base.i18n.language,
                        });
                    }
                }
                return next(action);
            }
            case SA_SHOW: {
                sendMessageToSAIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    application: SA_APPLICATIONS.AGENDA,
                    editMode: hasEditMode(state, SA_APPLICATIONS.AGENDA),
                    language: state.base.i18n.language,
                });
                return next(action);
            }
            case SA_SWITCH_OWN: {
                sendMessageToSAIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    application: action.sharedApplicationId,
                    editMode: hasEditMode(state, action.sharedApplicationId),
                    language: state.base.i18n.language,
                });
                return next(action);
            }
            case SA_HIDE: {
                sendMessageToSAIFrame({
                    cmd: IFRAME_COMMANDS.HIDE,
                });
                return next(action);
            }

            /* SidePanel iFrame */
            case SIDE_PANEL_IFRAME_NEW_REF: {
                if (action.iFrameRef && action.iFrameRef.current) {
                    sidePanelIFrame = action.iFrameRef.current;
                } else {
                    sidePanelIFrame = null;
                }
                if (sidePanelInitInterval) {
                    clearInterval(sidePanelInitInterval);
                    sidePanelInitInterval = null;
                }
                if (sidePanelEventHandlerRef) {
                    window.removeEventListener(
                        'message',
                        sidePanelEventHandlerRef
                    );
                    sidePanelEventHandlerRef = null;
                }
                return next(action);
            }
            case SIDE_PANEL_IFRAME_LOAD: {
                if (sidePanelEventHandlerRef) {
                    window.removeEventListener(
                        'message',
                        sidePanelEventHandlerRef
                    );
                }
                sidePanelEventHandlerRef = sidePanelMessageEventHandler(store);
                window.addEventListener('message', sidePanelEventHandlerRef);
                if (sidePanelInitInterval) {
                    clearInterval(sidePanelInitInterval);
                }
                sidePanelInitInterval = setInterval(
                    () =>
                        sendSidePanelInitMessage(
                            state.base.i18n.language,
                            state.meetings.meetingInfo
                        ),
                    IFRAME_INIT_INTERVAL
                );
                return next(action);
            }
            case SIDE_PANEL_IFRAME_INIT_CONFIRMED: {
                clearInterval(sidePanelInitInterval);
                sidePanelInitInterval = null;
                return next(action);
            }

            /* single frame handling */
            case SIDE_PANEL_SHOW_APPLICATION: {
                if (
                    state.meetings.activeSidePanelApplicationId !==
                    action.applicationId
                ) {
                    if (
                        action.applicationId === AL_APPLICATIONS.PARTICIPANTS ||
                        action.applicationId === null
                    ) {
                        sendMessageToSidePanelIFrame({
                            cmd: IFRAME_COMMANDS.HIDE,
                        });
                    } else {
                        sendMessageToSidePanelIFrame({
                            cmd: IFRAME_COMMANDS.SHOW,
                            application: action.applicationId,
                            language: state.base.i18n.language,
                        });
                    }
                }
                return next(action);
            }
            case SIDE_PANEL_IFRAME_READY: {
                if (
                    state.meetings.activeSidePanelApplicationId &&
                    state.meetings.activeSidePanelApplicationId !==
                        AL_APPLICATIONS.PARTICIPANTS
                ) {
                    sendMessageToSidePanelIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application:
                            state.meetings.activeSidePanelApplicationId,
                        language: state.base.i18n.language,
                    });
                }

                return next(action);
            }

            /* General */
            case CHANGE_LANGUAGE: {
                // TODO: language switch during meeting disabled for now
                /*
                if (state.meetings.alcMeetingJoined) {
                    let applicationId = '';
                    if (state.meetings && state.meetings.ownApplicationId) {
                        applicationId = state.meetings.ownApplicationId;
                    } else if (state.meetings && state.meetings.meetingInfo && state.meetings.meetingInfo.currentApp) {
                        applicationId = state.meetings.meetingInfo.currentApp;
                    }
                    sendMessageToSAIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application: applicationId,
                        editMode: hasEditMode(state, applicationId),
                        language: action.language
                    });
                }
                 */

                return next(action);
            }
            default:
                return next(action);
        }
    };
};
