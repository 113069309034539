import React from 'react';

import { getTranslatedString, hasLanguage } from '../i18n/translations';

import { Config } from '../../../config/Config';
import {
    AL_APPLICATIONS,
    MEETING_TYPES,
    SA_APPLICATIONS,
} from '../../../constants/constants';

const Logger = require('loglevelnext/lib/LogLevel');

const urlSearchParams = new URLSearchParams(window.location.search);
export const logging = urlSearchParams.get('debug') === 'true';
export const webRtcLogging = urlSearchParams.get('debugWebRtc') === 'true';
export const webRtcDummyMediaSource =
    urlSearchParams.get('webRtcDummyMediaSource') === 'true'
        ? '/img/dummyDevice.mp4'
        : urlSearchParams.get('webRtcDummyMediaSource');

export const webRtcCommunicationModeNone =
    urlSearchParams.get('webRtcCommunicationModeNone') === 'true';

let logLevel = 'silent';
if (logging) {
    logLevel = 'debug';
}

export const log = new Logger({
    level: logLevel,
    prefix: '{{time}} {{level}}',
});

export const storeToLocalStorage = (field, data) => {
    try {
        localStorage.setItem(field, data);
        return true;
    } catch (e) {
        return false;
    }
};

export const readFromLocalStorage = (field) => {
    try {
        return localStorage.getItem(field);
    } catch (e) {
        return null;
    }
};

export const storeToSessionStorage = (field, data) => {
    try {
        sessionStorage.setItem(field, data);
        return true;
    } catch (e) {
        return false;
    }
};

export const readFromSessionStorage = (field) => {
    try {
        return sessionStorage.getItem(field);
    } catch (e) {
        return null;
    }
};

export const removeFromSessionStorage = (field) => {
    try {
        sessionStorage.removeItem(field);
        return true;
    } catch (e) {
        return false;
    }
};

/**
 *
 * @param serviceUrl
 * @returns {*|string}
 */
export const serviceUrlToAPIUrl = function (serviceUrl) {
    let apiUrl = '';
    if (serviceUrl.indexOf(':3000') > -1) {
        apiUrl = serviceUrl.replace(':3000', ':3001');
    } else if (serviceUrl.indexOf(':5000') > -1) {
        apiUrl = serviceUrl.replace(':5000', ':5001');
    } else {
        apiUrl = serviceUrl;
    }
    return apiUrl;
};

export const getPreloadLanguage = function () {
    let language;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const languageParameter = urlSearchParams.get('lang');
    if (languageParameter && hasLanguage(languageParameter)) {
        language = languageParameter;
    }

    if (!language) {
        let userSystemLanguage = navigator.language || navigator.userLanguage;
        if (userSystemLanguage) {
            userSystemLanguage = userSystemLanguage.slice(0, 2);
            if (
                hasLanguage(userSystemLanguage) &&
                (!Config.availableLanguages ||
                    Config.availableLanguages.includes(userSystemLanguage))
            ) {
                language = userSystemLanguage;
            }
        }
    }

    if (!language || !hasLanguage(language)) {
        // if languages are restricted in config, use first language as default
        if (Config.availableLanguages) {
            language = Config.availableLanguages[0];
        }
        // if language is still not set, use english as default
        if (!language || !hasLanguage(language)) {
            language = 'en';
        }
    }
    return language;
};

export const webRtcErrorCodeToErrorId = function (errorCode) {
    let errorId;
    switch (errorCode) {
        case 11:
        case 111:
        case 161:
        case 171:
            errorId = 'webRtcErrorNotFound';
            break;
        case 13:
        case 131:
        case 112:
            errorId = 'webRtcErrorNotAllowed';
            break;
        case 132:
            errorId = 'webRtcErrorFailedAllocateSource';
            break;
        case 113:
        case 162:
        case 172:
            errorId = 'webRtcErrorDeviceInUse';
            break;
        case 114:
            errorId = 'webRtcErrorOverconstrained';
            break;
        case 211:
            errorId = 'webRtcErrorConnectionTimeout';
            break;
        case 51:
            errorId = 'webRtcBrowserNotSupported';
            break;
        default:
            log.error('WebRtc Error ' + errorCode);
            errorId = 'webRtcErrorDefault';
    }
    return errorId;
};

export const alcErrorCodeToErrorId = function (errorCode) {
    let errorId;
    switch (errorCode) {
        case 40310:
            errorId = 'alcErrorMeetingNeedsCaptchaCode';
            break;
        case 40309:
        case 40499:
            errorId = 'alcErrorMeetingDoesNotExist';
            break;
        case 40101:
            errorId = 'alcErrorMeetingFull';
            break;
        case 40103:
            errorId = 'alcErrorWebinarParticipantsFull';
            break;
        case 40104:
            errorId = 'alcErrorNeedsRecordingConfirmation';
            break;
        case 40005:
            errorId = 'alcErrorAdviserIdMismatch';
            break;
        case 40311:
            errorId = 'alcErrorExclusiveNotInvited';
            break;
        default:
            log.error('ALC Error ' + errorCode);
            errorId = 'alcErrorDefault';
    }
    return errorId;
};

const edgeDownloadLinks = {
    en: (
        <a
            href="https://www.microsoft.com/en-us/edge"
            target="_blank"
            rel="noopener noreferrer"
        >
            here
        </a>
    ),
    de: (
        <a
            href="https://www.microsoft.com/de-de/edge"
            target="_blank"
            rel="noopener noreferrer"
        >
            hier
        </a>
    ),
    fr: (
        <a
            href="https://www.microsoft.com/fr-fr/edge"
            target="_blank"
            rel="noopener noreferrer"
        >
            ici
        </a>
    ),
    it: (
        <a
            href="https://www.microsoft.com/it-it/edge"
            target="_blank"
            rel="noopener noreferrer"
        >
            qui
        </a>
    ),
};

export const getBrowserCheckMessage = function (language, errorData) {
    if (errorData.os && errorData.os.includes('iOS')) {
        if (errorData.browserName.includes('Safari')) {
            return getTranslatedString(
                language,
                'webRtcBrowserOutOfDate'
            ).replace('{{browser}}', errorData.browserName);
        } else {
            return getTranslatedString(
                language,
                'webRtcBrowserNonSafariIos'
            ).replace('{{browser}}', errorData.browserName);
        }
    } else if (errorData.browserName.includes('IE')) {
        const stringParts = getTranslatedString(
            language,
            'webRtcBrowserIe'
        ).split('{{link}}');
        if (stringParts.length === 2) {
            return (
                <React.Fragment>
                    {stringParts[0]}
                    {edgeDownloadLinks[language]}
                    {stringParts[1]}
                </React.Fragment>
            );
        }
    } else if (
        errorData.browserName.includes('Edge') &&
        errorData.browserVersion < 70
    ) {
        const stringParts = getTranslatedString(
            language,
            'webRtcBrowserLegacyEdge'
        ).split('{{link}}');
        if (stringParts.length === 2) {
            return (
                <React.Fragment>
                    {stringParts[0]}
                    {edgeDownloadLinks[language]}
                    {stringParts[1]}
                </React.Fragment>
            );
        }
    } else if (
        errorData.browserName.includes('Chrome') ||
        errorData.browserName.includes('Firefox') ||
        errorData.browserName.includes('Samsung') ||
        errorData.browserName.includes('Edge')
    ) {
        return getTranslatedString(language, 'webRtcBrowserOutOfDate').replace(
            '{{browser}}',
            errorData.browserName
        );
    }
    return getTranslatedString(language, 'webRtcBrowserNotSupported');
};

export const fullscreenEnabled =
    document.fullscreenEnabled || document.webkitFullscreenEnabled;

export const isHttps = () => {
    if (!!Config.forceHttps) return true;
    else if (
        Config.name === 'local' ||
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1'
    )
        return false;
    return window.location.protocol === 'https:';
};

export const getActiveSharedApplications = (
    meetingInfo,
    publicServiceInfo,
    standalone
) => {
    let activeSharedApplications = [];
    if (!standalone) {
        activeSharedApplications = [
            SA_APPLICATIONS.SHOWBOARD,
            SA_APPLICATIONS.AGENDA,
            SA_APPLICATIONS.MINUTES,
        ];
    }
    if (standalone && meetingInfo) {
        const additionalMetadata = meetingInfo.additionalMetadata
            ? meetingInfo.additionalMetadata
            : {};

        if (meetingInfo.type !== MEETING_TYPES.PODIUM) {
            activeSharedApplications.push(SA_APPLICATIONS.CHAT);
            activeSharedApplications.push(SA_APPLICATIONS.DOCUMENTS);
            if (
                (meetingInfo.type === MEETING_TYPES.MEETING ||
                    meetingInfo.type === MEETING_TYPES.OUTLOOK_MEETING) &&
                (publicServiceInfo.meetingsSettings.enableSurveyInMeeting ||
                    publicServiceInfo.meetingsSettings.enableVoteInMeeting)
            ) {
                activeSharedApplications.push(SA_APPLICATIONS.STREAM_SURVEYS);
            }
        } else {
            if (!additionalMetadata.hideChatTab) {
                activeSharedApplications.push(SA_APPLICATIONS.STREAM_CHAT);
            }
            if (!additionalMetadata.hideDocumentsTab) {
                activeSharedApplications.push(SA_APPLICATIONS.STREAM_DOCUMENTS);
            }
            if (!additionalMetadata.hidePollsTab) {
                activeSharedApplications.push(SA_APPLICATIONS.STREAM_SURVEYS);
            }
        }
    }

    return activeSharedApplications;
};

export const getActiveSidePanelApplications = (
    meetingInfo,
    publicServiceInfo
) => {
    const activeSidePanelApplications = [];
    const disableCommunicatorFileTransfer =
        publicServiceInfo.meetingsSettings.disableCommunicatorFileTransfer;

    if (meetingInfo.type !== MEETING_TYPES.PODIUM) {
        activeSidePanelApplications.push(SA_APPLICATIONS.CHAT);
        if (!disableCommunicatorFileTransfer) {
            activeSidePanelApplications.push(SA_APPLICATIONS.DOCUMENTS);
        }
        if (
            (meetingInfo.type === MEETING_TYPES.MEETING ||
                meetingInfo.type === MEETING_TYPES.OUTLOOK_MEETING) &&
            (publicServiceInfo.meetingsSettings.enableSurveyInMeeting ||
                publicServiceInfo.meetingsSettings.enableVoteInMeeting)
        ) {
            activeSidePanelApplications.push(SA_APPLICATIONS.STREAM_SURVEYS);
        }
    } else {
        const additionalMetadata = meetingInfo.additionalMetadata
            ? meetingInfo.additionalMetadata
            : {};

        if (!additionalMetadata.hideChatTab) {
            activeSidePanelApplications.push(SA_APPLICATIONS.STREAM_CHAT);
        }
        if (
            !additionalMetadata.hideDocumentsTab &&
            !disableCommunicatorFileTransfer
        ) {
            activeSidePanelApplications.push(SA_APPLICATIONS.STREAM_DOCUMENTS);
        }
        if (!additionalMetadata.hidePollsTab) {
            activeSidePanelApplications.push(SA_APPLICATIONS.STREAM_SURVEYS);
        }
    }
    if (meetingInfo.type === MEETING_TYPES.WEBINAR) {
        activeSidePanelApplications.push(SA_APPLICATIONS.STREAM_SURVEYS);
        activeSidePanelApplications.push(AL_APPLICATIONS.PARTICIPANTS);
    }
    return activeSidePanelApplications;
};
