import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import MeetingMainVideo from './MeetingMainVideo';
import MeetingVideoList from './MeetingVideoList';
import { LoadingScreen } from '../base/loadingScreen/LoadingScreen';
import MeetingToolbar from './MeetingToolbar';
import RecordingIndicator from './RecordingIndicator';
import SharedApplicationsPanel from './SharedApplicationsPanel';
import SidePanel from './SidePanel';
import MeetingTopbar from './MeetingTopbar';

import { joinMeeting, alcConfirmRecording, fullscreenNewRef } from './actions';
import {
    APP_STATE,
    MEETING_TYPES,
    RECORDING_CONFIRMATION_STATE,
    RESPONSIVE_MODES,
    SPEAKER_PERMISSION_STATE,
} from '../../constants/constants';

import SpeakerConfirmationDialog from './SpeakerConfirmationDialog';
import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export class Meeting extends Component {
    constructor(props) {
        super(props);

        this.meetingRef = React.createRef();
    }

    componentDidMount() {
        this.props.fullscreenNewRef(this.meetingRef);
    }

    componentWillUnmount() {
        this.props.fullscreenNewRef(null);
    }

    renderConfirmRecordingDialog() {
        if (this.props.confirmRecordingDialogVisible) {
            const footer = (
                <div>
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'decline'
                        )}
                        className="p-button-secondary"
                        icon="pi pi-times"
                        onClick={() => this.props.alcConfirmRecording(false)}
                    />
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'confirm'
                        )}
                        className="p-button-primary"
                        icon="pi pi-check"
                        onClick={() => this.props.alcConfirmRecording(true)}
                    />
                </div>
            );

            return (
                <Dialog
                    header={<TranslatedString id={'recConfirmTitle'} />}
                    footer={footer}
                    visible={this.props.confirmRecordingDialogVisible}
                    onHide={() => {}}
                    closable={false}
                    baseZIndex={999999}
                    focusOnShow={false}
                >
                    <TranslatedString id="recConfirmMessage" />
                </Dialog>
            );
        }
        return null;
    }

    render() {
        const {
            alcMeetingJoined,
            webRtcMeetingJoined,
            isRecorder,
            responsiveMode,
        } = this.props;
        const confirmRecordingDialog = this.renderConfirmRecordingDialog();

        if (alcMeetingJoined && webRtcMeetingJoined) {
            //const mobileLayout = responsiveMode < RESPONSIVE_MODES.FULL;
            const mobileLayout = false; // todo: is only quick fix for Mobile-View
            const sidePanelOverlayed = responsiveMode < RESPONSIVE_MODES.MEDIUM;

            if (isRecorder) {
                return (
                    <div className="meeting-wrapper">
                        <div className="meeting-panel-wrapper">
                            <MeetingPanel
                                {...this.props}
                                confirmRecordingDialog={confirmRecordingDialog}
                            />
                        </div>
                    </div>
                );
            } else {
                return (
                    <div
                        className={classNames('meeting-wrapper', {
                            'own-screenshare': this.props.ownScreenShareActive,
                        })}
                        ref={this.meetingRef}
                    >
                        <div
                            className={classNames('meeting-panel-wrapper', {
                                'side-panel-active':
                                    !!this.props.activeSidePanelApplicationId,
                                mobile: mobileLayout,
                            })}
                        >
                            <MeetingPanel
                                {...this.props}
                                confirmRecordingDialog={confirmRecordingDialog}
                            />
                        </div>
                        <MeetingToolbar />
                        <div
                            className={classNames('side-panel-wrapper', {
                                hidden:
                                    !this.props.activeSidePanelApplicationId &&
                                    !mobileLayout,
                                mobile: mobileLayout,
                                overlayed: sidePanelOverlayed,
                            })}
                        >
                            <SidePanel />
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <React.Fragment>
                    <LoadingScreen />
                    {confirmRecordingDialog}
                </React.Fragment>
            );
        }
    }
}

class MeetingPanel extends Component {
    render() {
        return (
            <div
                className={classNames('podium-panel meeting', {
                    'is-recorder': this.props.isRecorder,
                    'videolist-active':
                        this.props.responsiveMode > RESPONSIVE_MODES.SMALL &&
                        this.props.visibleParticipantCount > 0,
                    'responsive-mode-small':
                        this.props.responsiveMode === RESPONSIVE_MODES.SMALL,
                    'responsive-mode-medium':
                        this.props.responsiveMode === RESPONSIVE_MODES.MEDIUM ||
                        (this.props.responsiveMode === RESPONSIVE_MODES.FULL &&
                            this.props.visibleParticipantCount > 7),
                    'responsive-mode-full':
                        this.props.responsiveMode === RESPONSIVE_MODES.FULL &&
                        this.props.visibleParticipantCount <= 7,
                })}
            >
                <MeetingMainVideo />
                <RecordingIndicator />
                {!this.props.isRecorder && <MeetingTopbar />}
                <MeetingVideoList />

                {this.props.applicationsUrl &&
                    (this.props.sidePanelIFrameInitialized ||
                        this.props.isRecorder) && (
                        <SharedApplicationsPanel standalone={false} />
                    )}

                <SpeakerConfirmationDialog />
                {this.props.confirmRecordingDialog}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let confirmRecordingDialogVisible = false;
    if (
        (state.meetings.alcMeetingJoined &&
            state.meetings.meetingInfo.recordingConfirmationInProgress &&
            state.meetings.clientInfo.recordingConfirmationState ===
                RECORDING_CONFIRMATION_STATE.PENDING) ||
        state.landingPage.appState === APP_STATE.RECORDING_CONFIRMATION
    ) {
        confirmRecordingDialogVisible = true;
    }

    const meetingType =
        state.meetings.meetingInfo && state.meetings.meetingInfo.type;

    let visibleParticipantCount = 0;
    if (
        state.meetings.meetingParticipants &&
        state.meetings.meetingParticipants.length > 0
    ) {
        visibleParticipantCount = state.meetings.meetingParticipants.reduce(
            (acc, participant) => {
                if (
                    !participant.standalone &&
                    !participant.recorder &&
                    ((meetingType !== MEETING_TYPES.WEBINAR &&
                        meetingType !== MEETING_TYPES.PODIUM) ||
                        participant.isAdviser ||
                        participant.speakerPermissionState ===
                            SPEAKER_PERMISSION_STATE.GRANTED) &&
                    (!state.meetings.clientInfo.recorder ||
                        participant.isVisible !== false)
                ) {
                    return acc + 1;
                } else {
                    return acc;
                }
            },
            0
        );
    }

    return {
        alcMeetingJoined: state.meetings.alcMeetingJoined,
        webRtcMeetingJoined: state.meetings.webRtcMeetingJoined,
        applicationsUrl:
            state.landingPage.publicServiceInfo.meetingsSettings
                .applicationsUrl,
        responsiveMode: state.base.common.responsiveMode,
        sidePanelIFrameInitialized: state.meetings.sidePanelIFrameInitialized,
        meetingType:
            state.meetings.meetingInfo && state.meetings.meetingInfo.type,
        ownScreenShareActive: !!(
            state.meetings.ownScreenShareView &&
            state.meetings.ownScreenShareView.view
        ),
        confirmRecordingDialogVisible,
        activeSidePanelApplicationId:
            state.meetings.activeSidePanelApplicationId,
        isRecorder:
            state.meetings.clientInfo && state.meetings.clientInfo.recorder,
        language: state.base.i18n.language,
        visibleParticipantCount,
    };
};

const mapDispatchToProps = {
    fullscreenNewRef,
    joinMeeting,
    alcConfirmRecording,
};

export default connect(mapStateToProps, mapDispatchToProps)(Meeting);
