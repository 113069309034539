import * as types from './actionTypes';
import {
    WEBRTC_INIT_SUCCESS,
    WEBRTC_START_LOCAL_MEDIA_SUCCESS,
    WEBRTC_JOIN_SUCCESS,
} from '../meetings/actionTypes';
import {
    COMMUNICATION_MODES,
    DEFAULT_RESOLUTION,
} from '../../constants/constants';

export const initialState = {
    settingsPanelShown: false,
    deviceLists: {
        micDeviceList: undefined,
        camDeviceList: undefined,
    },
    currentSettings: {
        cam: undefined,
        mic: undefined,
        res: undefined,
        audioMuted: false,
        videoMuted: false,
    },
    communicationMode: undefined,
    view: undefined,
    loading: false,
    errorId: undefined,
    recoveryMode: false,
    recoveryCommunicationMode: undefined,
    recoverySettings: {
        cam: undefined,
        mic: undefined,
        res: DEFAULT_RESOLUTION,
    },
};

export default function meetings(state = initialState, action) {
    switch (action.type) {
        case WEBRTC_INIT_SUCCESS: {
            return {
                ...state,
                communicationMode: action.communicationMode,
            };
        }
        case WEBRTC_START_LOCAL_MEDIA_SUCCESS: {
            return {
                ...state,
                communicationMode: action.communicationMode,
                currentSettings: action.currentSettings,
            };
        }
        case WEBRTC_JOIN_SUCCESS: {
            return {
                ...state,
                communicationMode: action.communicationMode,
                currentSettings: action.currentSettings,
            };
        }
        case types.SETTINGS_SHOW: {
            return {
                ...state,
                settingsPanelShown: true,
                loading: true,
            };
        }
        case types.WEBRTC_SETTINGS_START_SUCCESS: {
            return {
                ...state,
                deviceLists: {
                    micDeviceList: action.micDeviceList,
                    camDeviceList: action.camDeviceList,
                },
                currentSettings: action.currentSettings,
                communicationMode: action.communicationMode,
                view: action.view,
                loading: false,
            };
        }
        case types.WEBRTC_CHANGE_CAM:
        case types.WEBRTC_CHANGE_RES:
        case types.WEBRTC_CHANGE_MIC:
        case types.WEBRTC_CHANGE_COMMUNICATION_MODE:
        case types.WEBRTC_REFRESH_DEVICE_LISTS:
        case types.WEBRTC_APPLY_RECOVERY_SETTINGS: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.WEBRTC_CHANGE_CAM_SUCCESS: {
            return {
                ...state,
                currentSettings: action.newSettings,
                errorId: undefined,
                loading: false,
            };
        }
        case types.WEBRTC_CHANGE_MIC_SUCCESS: {
            return {
                ...state,
                currentSettings: action.newSettings,
                errorId: undefined,
                loading: false,
            };
        }
        case types.WEBRTC_CHANGE_RES_SUCCESS: {
            return {
                ...state,
                currentSettings: action.newSettings,
                errorId: undefined,
                loading: false,
            };
        }
        case types.WEBRTC_REFRESH_DEVICE_LISTS_SUCCESS: {
            if (state.recoveryMode) {
                let mic = state.recoverySettings.mic;
                let cam = state.recoverySettings.cam;
                let res = state.recoverySettings.res;

                if (!action.micDeviceList.includes(mic)) {
                    mic = undefined;
                }
                if (!action.camDeviceList.includes(cam)) {
                    cam = undefined;
                    res = DEFAULT_RESOLUTION;
                }
                return {
                    ...state,
                    recoverySettings: {
                        mic,
                        cam,
                        res,
                    },
                    deviceLists: {
                        micDeviceList: action.micDeviceList,
                        camDeviceList: action.camDeviceList,
                    },
                    errorId: action.errorId,
                    loading: false,
                };
            } else {
                return {
                    ...state,
                    deviceLists: {
                        micDeviceList: action.micDeviceList,
                        camDeviceList: action.camDeviceList,
                    },
                    errorId: action.errorId,
                    loading: false,
                };
            }
        }
        case types.WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS: {
            return {
                ...state,
                deviceLists: {
                    micDeviceList: action.micDeviceList,
                    camDeviceList: action.camDeviceList,
                },
                currentSettings: action.currentSettings,
                communicationMode: action.communicationMode,
                view: action.view,
                errorId: undefined,
                loading: false,
            };
        }
        case types.WEBRTC_SETTINGS_ERROR: {
            return {
                ...state,
                errorId: action.errorId,
                loading: false,
            };
        }
        case types.SETTINGS_HIDE: {
            return {
                ...state,
                settingsPanelShown: false,
                deviceLists: {
                    micDeviceList: undefined,
                    camDeviceList: undefined,
                },
                currentSettings: {
                    ...state.currentSettings,
                    cam: undefined,
                    mic: undefined,
                    res: undefined,
                },
                view: undefined,
                errorId: undefined,
                loading: false,
            };
        }
        case types.WEBRTC_MUTE_AUDIO: {
            return {
                ...state,
                currentSettings: {
                    ...state.currentSettings,
                    audioMuted: action.shouldMute,
                },
            };
        }
        case types.WEBRTC_MUTE_VIDEO: {
            return {
                ...state,
                currentSettings: {
                    ...state.currentSettings,
                    videoMuted: action.shouldMute,
                },
            };
        }
        case types.SETTINGS_RECOVERY_START: {
            return {
                ...state,
                recoveryMode: true,
                loading: true,
            };
        }
        case types.SETTINGS_RECOVERY_START_SUCCESS: {
            return {
                ...state,
                loading: false,
                settingsPanelShown: true,
                recoveryCommunicationMode: action.communicationMode,
                deviceLists: {
                    micDeviceList: action.micDeviceList,
                    camDeviceList: action.camDeviceList,
                },
                errorId: action.errorId,
            };
        }
        case types.WEBRTC_APPLY_RECOVERY_SETTINGS_SUCCESS: {
            return {
                ...state,
                deviceLists: {
                    micDeviceList: action.micDeviceList,
                    camDeviceList: action.camDeviceList,
                },
                currentSettings: action.currentSettings,
                communicationMode: action.communicationMode,
                view: action.view,
                errorId: undefined,
                loading: false,
                recoveryMode: false,
                recoveryCommunicationMode: undefined,
                recoverySettings: {
                    cam: undefined,
                    res: DEFAULT_RESOLUTION,
                    mic: undefined,
                },
            };
        }
        case types.SETTINGS_RECOVERY_CHANGE_CAM: {
            return {
                ...state,
                recoverySettings: {
                    ...state.recoverySettings,
                    cam: action.cam,
                },
            };
        }
        case types.SETTINGS_RECOVERY_CHANGE_MIC: {
            return {
                ...state,
                recoverySettings: {
                    ...state.recoverySettings,
                    mic: action.mic,
                },
            };
        }
        case types.SETTINGS_RECOVERY_CHANGE_RES: {
            return {
                ...state,
                recoverySettings: {
                    ...state.recoverySettings,
                    res: action.res,
                },
            };
        }
        case types.SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE_SUCCESS: {
            return {
                ...state,
                recoveryCommunicationMode: action.communicationMode,
                deviceLists: {
                    micDeviceList: action.micDeviceList,
                    camDeviceList: action.camDeviceList,
                },
                loading: false,
                errorId: undefined,
            };
        }
        case types.SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE_FAILURE: {
            return {
                ...state,
                recoveryCommunicationMode: COMMUNICATION_MODES.NONE,
                deviceLists: {
                    micDeviceList: [],
                    camDeviceList: [],
                },
                loading: false,
                errorId: action.errorId,
            };
        }

        default:
            return state;
    }
}
