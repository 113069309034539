import * as types from './actionTypes';
import { storeToSessionStorage } from '../base/util/helpers';
import { ERRORS } from '../../constants/constants';
import authHelper from '../base/util/authHelper';
import { hasLanguage } from '../base/i18n/translations';
import { changeLanguage } from '../base/i18n/actions';

export function initApp() {
    return { type: types.INIT_APP };
}

export function changeAppState(appState) {
    return { type: types.CHANGE_APP_STATE, appState };
}

export function editParticipant() {
    return { type: types.EDIT_PARTICIPANT };
}

export function newError(errorId, errorNo, errorData) {
    return { type: types.NEW_ERROR, errorId, errorNo, errorData };
}

export function setWebRtcFatalError(errorId, errorNo, errorData) {
    return { type: types.SET_WEBRTC_FATAL_ERROR, errorId, errorNo, errorData };
}

export function getMeetingInfo(meetingId, captchaCode) {
    return { type: types.GET_MEETING_INFO, meetingId, captchaCode };
}

export function confirmDisclaimer() {
    return { type: types.CONFIRM_DISCLAIMER };
}

export function participantInfoUpdate(participantInfo) {
    return { type: types.PARTICIPANT_INFO_UPDATE, participantInfo };
}

function getPublicServiceInfoRequest() {
    return { type: types.PUBLIC_SERVICE_INFO_REQUEST };
}

function getPublicServiceInfoSuccess(serviceId, publicServiceInfo) {
    return {
        type: types.PUBLIC_SERVICE_INFO_SUCCESS,
        serviceId,
        publicServiceInfo,
    };
}

function getPublicServiceInfoFailure(errorId) {
    return { type: types.PUBLIC_SERVICE_INFO_FAILURE, errorId };
}

/*
 * thunk
 */
export function requestPublicServiceInfo(serviceId) {
    return async (dispatch, getState, api) => {
        dispatch(getPublicServiceInfoRequest());

        const response = await api.getPublicServiceInfo(serviceId);
        if (!response.error) {
            dispatch(
                getPublicServiceInfoSuccess(
                    serviceId,
                    response.publicServiceInfo
                )
            );
        } else {
            const errorId =
                response.error.errorNo === 40400
                    ? 'serviceNotFound'
                    : 'connectionError';
            dispatch(getPublicServiceInfoFailure(errorId));
        }
    };
}

export function alcGetMeetingInfoSuccess(
    meetingInfo,
    participantInfo,
    serverTime
) {
    return {
        type: types.ALC_GET_MEETING_INFO_SUCCESS,
        meetingInfo,
        participantInfo,
        serverTime,
    };
}

export function alcGetMeetingInfo(meetingId, captchaCode) {
    return { type: types.ALC_GET_MEETING_INFO, meetingId, captchaCode };
}

function contactFormRequest() {
    return { type: types.CONTACT_FORM_REQUEST };
}

function contactFormRequestSuccess() {
    return { type: types.CONTACT_FORM_REQUEST_SUCCESS };
}

function contactFormRequestFailure(errorId) {
    return { type: types.CONTACT_FORM_REQUEST_FAILURE, errorId };
}

/*
 * thunk
 */
export function sendContactForm(name, email, phone, message, desiredDate) {
    return async (dispatch, getState, api) => {
        dispatch(contactFormRequest());

        const serviceId = getState().landingPage.publicServiceInfo
            ? getState().landingPage.publicServiceInfo._id
            : null;
        if (serviceId) {
            const contactRequestData = {
                name,
                email,
                phone,
                message,
                desiredDate,
            };

            const response = await api.createContactRequest(
                serviceId,
                contactRequestData
            );
            if (!response.error) {
                dispatch(contactFormRequestSuccess());
            } else {
                const errorId =
                    response.error.errorNo === 40400
                        ? 'serviceNotFound'
                        : 'connectionError';
                dispatch(contactFormRequestFailure(errorId));
            }
        } else {
            contactFormRequestFailure('serviceNotFound');
        }
    };
}

export function alcServiceStateUpdate(serviceState) {
    return { type: types.ALC_SERVICE_STATE_UPDATE, serviceState };
}

export function alcStartCall(
    topicId,
    participantName,
    email,
    communicationMode
) {
    return {
        type: types.ALC_START_CALL,
        topicId,
        participantName,
        email,
        communicationMode,
    };
}

export function setTopicId(topicId) {
    return { type: types.SET_TOPIC_ID, topicId };
}

export function initiateCall(topicId, participantName, email) {
    return { type: types.INITIATE_CALL, topicId, participantName, email };
}

export function alcParticipantAuth(authToken) {
    return { type: types.ALC_PARTICIPANT_AUTH, authToken };
}
export function login() {
    return async (dispatch, getState, api) => {
        dispatch(loginRequest());
        //login callbacks for different login methods
        const onLoginCallbacks = {
            // code to execute when the login with keycloak succeeds
            success: async (user, serviceId) => {
                storeToSessionStorage('user', JSON.stringify(user));
                if (user && user.roles && user.roles.includes('adviser')) {
                    dispatch(loginSuccess(user, serviceId));
                    if (hasLanguage(user.locale)) {
                        dispatch(changeLanguage(user.locale));
                    }
                } else {
                    dispatch(loginFailure(ERRORS.INVALID_ROLE_ERROR));
                    dispatch(logout());
                }
            },
            // code to execute when the login with keycloak fails
            failure: () => {
                dispatch(loginFailure('Keycloak Login failed'));
            },
        };

        //logoutHandler
        const onLogoutCallback = (keycloak) => () => {
            keycloak.logout({
                redirectUri: window.location.href,
            });
        };
        // login
        authHelper.login(
            getState().landingPage.publicServiceInfo.keycloakSettings.serviceId,
            getState().landingPage.publicServiceInfo.keycloakSettings,
            onLoginCallbacks,
            onLogoutCallback
        );
    };
}

export function loginRequest() {
    return { type: types.LOGIN_REQUEST };
}

export function loginSuccess(user, serviceId) {
    return { type: types.LOGIN_SUCCESS, user, serviceId };
}

export function loginFailure(errorMessage, captcha) {
    return { type: types.LOGIN_FAILURE, errorMessage, captcha };
}

/*
 * action creators
 */

export function logout() {
    return async (dispatch, getState, api) => {
        dispatch(logoutRequest());
        authHelper.logout();
    };
}

export function logoutRequest() {
    return { type: types.LOGOUT };
}

export function forceLogout(errorMessage) {
    return async (dispatch, getState, api) => {
        dispatch(forceLogoutRequest(errorMessage));
        authHelper.logout();
    };
}

export function forceLogoutRequest(errorMessage) {
    return { type: types.FORCE_LOGOUT, errorMessage };
}

export function logoutSuccess() {
    return { type: types.LOGOUT_SUCCESS };
}
