import React, { Component, useCallback, useMemo } from "react";
import throttle from "lodash/throttle";
import { Slider } from "primereact/slider";
import { formatTime } from "../util/helpers";

export class PlayerSeekBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            seeking: false,
            seekBarWidth: null,
            fillPercentage: 0,
        };

        this.seekBar = React.createRef();

        this.handleSeekBarClick = this.handleSeekBarClick.bind(this);
        this.handleSeekBarMouseDown = this.handleSeekBarMouseDown.bind(this);
        this.handleWindowResizeEvent = this.handleWindowResizeEvent.bind(this);
        this.handleWindowResizeEventThrottled = throttle(this.handleWindowResizeEvent, 100);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowResizeEventThrottled);
        this.setState({ seekBarWidth: this.seekBar.current.offsetWidth });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResizeEventThrottled);
    }

    handleWindowResizeEvent() {
        if (this.seekBar.current) {
            const seekBarWidth = this.seekBar.current.offsetWidth;
            if (this.state.seekBarWidth !== seekBarWidth) {
                this.setState({ seekBarWidth: seekBarWidth });
            }
        }
    }

    handleSeekBarClick(e) {
        e.preventDefault();
        e.stopPropagation();

        const { onSeekBarChange } = this.props;

        const seekBarWidth = this.state.seekBarWidth || this.seekBar.current.offsetWidth;
        const offsetLeft = e.target.getBoundingClientRect().left + window.pageXOffset;
        const clickPosition = e.pageX - offsetLeft;

        onSeekBarChange(clickPosition / seekBarWidth);
    }

    handleSeekBarMouseDown(e) {
        e.preventDefault();
        e.stopPropagation();

        const { isPlaying, fillPercentage, onTogglePlayClick, onSeekBarChange } = this.props;

        const seekBarWidth = this.state.seekBarWidth || this.seekBar.current.offsetWidth;
        const wasPlaying = isPlaying;

        let newPosition, oldPosition;
        let paused = false;

        const handleMouseMove = (e) => {
            if (this.state.seeking) {
                if (!paused && wasPlaying) {
                    paused = true;
                    onTogglePlayClick();
                }

                window.getSelection().removeAllRanges();

                const offsetLeft = this.seekBar.current.getBoundingClientRect().left + window.pageXOffset;
                const touchLocation = e.pageX;
                const x = touchLocation - offsetLeft;

                if (x >= seekBarWidth) {
                    newPosition = seekBarWidth;
                } else if (x <= 0) {
                    newPosition = 0;
                } else {
                    newPosition = x;
                }

                if (newPosition !== oldPosition) {
                    oldPosition = newPosition;
                    this.setState({
                        fillPercentage: newPosition / seekBarWidth,
                    });
                    onSeekBarChange(newPosition / seekBarWidth);
                }
            }
        };

        const handleMouseMoveThrottled = throttle(handleMouseMove, 50);

        const handleMouseUp = () => {
            if (this.state.seeking) {
                onSeekBarChange(newPosition / seekBarWidth);

                if (paused && wasPlaying) {
                    onTogglePlayClick();
                }
            }

            this.setState({ seeking: false });

            window.getSelection().removeAllRanges();
            window.removeEventListener("mousemove", handleMouseMoveThrottled);
            window.removeEventListener("mouseup", handleMouseUp);
        };

        window.addEventListener("mousemove", handleMouseMoveThrottled);
        window.addEventListener("mouseup", handleMouseUp);

        this.setState({
            seeking: true,
            fillPercentage: fillPercentage,
        });
    }

    render() {
        const { videoLoaded, time, duration, fillPercentage } = this.props;

        let seekBarFillWidth = null;
        if (videoLoaded && this.state.seekBarWidth !== null) {
            const seekBarWidth = this.state.seekBarWidth;
            seekBarFillWidth = this.state.seeking
                ? Math.min(this.state.fillPercentage * seekBarWidth, seekBarWidth)
                : Math.min(fillPercentage * seekBarWidth, seekBarWidth);
        }

        return (
            <React.Fragment>
                <div className="seek-bar-time-wrapper">
                    <span>{formatTime(time, true)}</span>
                    <span>{formatTime(duration, true)}</span>
                </div>
                <div className="seek-bar-wrapper" onClick={this.handleSeekBarClick}>
                    <div className="seek-bar" ref={this.seekBar}>
                        {seekBarFillWidth !== null && (
                            <div className="seek-bar-background">
                                <div className="seek-bar-fill" style={{ width: seekBarFillWidth }} />
                                <div
                                    className="seek-bar-handle"
                                    style={{ left: seekBarFillWidth }}
                                    onMouseDown={this.handleSeekBarMouseDown}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

/* PlayerSeekBar props: (TODO: use props types package?)
    videoLoaded,
    time,
    duration,
    fillPercentage,
    isPlaying,
    fillPercentage,
    onTogglePlayClick,
    onSeekBarChange
*/

export function VolumeSlider({ volume, onVolumeChange }) {
    const throttledVolumeChange = useMemo(() => throttle((value) => onVolumeChange(value > 0 ? value / 100 : 0), 75), [
        onVolumeChange,
    ]);
    const handleVolumeChangeThrottled = useCallback((e) => throttledVolumeChange(e.value), [throttledVolumeChange]);

    return (
        <div className="volume-slider-wrapper">
            <Slider
                className="volume-slider"
                value={volume * 100}
                min={0}
                max={100}
                step={5}
                onChange={handleVolumeChangeThrottled}
            />
        </div>
    );
}

/* VolumeSlider props: (TODO: use props types package?)
    volume,
    onVolumeChange
*/

export function PlayerControlButton({ icon, onClick }) {
    return (
        <div className="control-button" onClick={onClick}>
            <div className="icon">{icon}</div>
        </div>
    );
}

/* PlayerControlButton props: (TODO: use props types package?)
    icon,
    onClick
*/
