import favicon from '../assets/favicons/favicon_unblumeet.ico';
import landingPageImage from '../assets/images/icon-chat.svg';
import { MEETING_TYPES } from '../constants/constants';

export const defaultConfig = {
    favicon,
    /* set either documentTitle or documentTitle locales
        documentTitle: 'Unblu Meet',

        documentTitleLocales: {
            en: 'EN title',
            de: 'DE title',
            fr: 'FR title',
            it: 'IT title
     */
    documentTitle: 'Unblu Meet',
    landingPageImage,
    hideTitleResponsiveSmall: false,
    hideEmailInput: false,
    nameInputOptional: false,
    disclaimerEnabled: false,
    footerEnabled: false,
    serviceIdFromUrl: false,
    communicatorType: MEETING_TYPES.NOT_SET,
    screenSharingDisabled: false,
    phoneConsultingAudioVideoDisabled: false,
    phoneConsultingScreenSharingDisabled: false,
    logoOverride: undefined,
    postMeetingIFrameUrl: undefined,
    podiumPlayerLogoDisabled: false,
};
