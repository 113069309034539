import Connector from '@adialive/connector';
import { Config } from '../config/Config';

import {
    alcAdmittanceChanged,
    alcChangeSpeakerPermission,
    alcClientInfoUpdate,
    alcError,
    alcInitialized,
    alcJoinMeetingSuccess,
    alcKicked,
    alcLeaveMeetingSuccess,
    alcMeetingCompleted,
    alcMeetingInfoUpdate,
    alcMeetingParticipantsUpdate,
    alcPodiumInfoUpdate,
    alcReconnecting,
    alcSessionRestored,
    alcStreamInfoUpdate,
    alcTransferred,
    alcUpdateServerTimeDiff,
    hideSpeakerConfirmation,
    showSpeakerConfirmation,
    sidePanelShowApplication,
    switchOwnSharedApplication,
    webRtcStopScreenShare,
} from '../features/meetings/actions';
import {
    ALC_CHANGE_ACTIVE_USER,
    ALC_CLOSE,
    ALC_CONFIRM_RECORDING,
    ALC_INIT,
    ALC_JOIN_MEETING,
    ALC_LEAVE_MEETING,
    ALC_MUTE_PARTICIPANT,
    ALC_REQUEST_ADMITTANCE,
    ALC_REQUEST_SPEAKER_PERMISSION,
    ALC_SET_CURRENT_APP,
    ALC_SET_EDIT_PERMISSIONS,
    ALC_SET_HAND_RAISED,
    ALC_START_RECORDING,
    ALC_STOP_RECORDING,
    ALC_UPDATE_USER_MODE,
    REVOKE_SPEAKER_PERMISSION,
    WEBRTC_JOIN_SUCCESS,
    WEBRTC_START_SCREENSHARE_SUCCESS,
    WEBRTC_STOP_SCREENSHARE_SUCCESS,
} from '../features/meetings/actionTypes';
import {
    ALC_GET_MEETING_INFO,
    ALC_PARTICIPANT_AUTH,
    ALC_START_CALL,
} from '../features/landingPage/actionTypes';
import {
    WEBRTC_APPLY_RECOVERY_SETTINGS_SUCCESS,
    WEBRTC_CHANGE_CAM_SUCCESS,
    WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS,
    WEBRTC_CHANGE_MIC_SUCCESS,
    WEBRTC_CHANGE_RES_SUCCESS,
    WEBRTC_MUTE_AUDIO,
    WEBRTC_MUTE_VIDEO,
} from '../features/deviceSettings/actionTypes';
import {
    hideSettings,
    webRtcChangeCommunicationMode,
    webRtcMuteAudio,
} from '../features/deviceSettings/actions';
import {
    alcGetMeetingInfoSuccess,
    alcServiceStateUpdate,
} from '../features/landingPage/actions';
import {
    COMMUNICATION_MODES,
    MEETING_TYPES,
    SPEAKER_PERMISSION_STATE,
} from '../constants/constants';
import { showMessage } from '../features/base/messages/actions';
import {
    getActiveSharedApplications,
    getActiveSidePanelApplications,
    log,
} from '../features/base/util/helpers';
import { adiaLiveSettings } from '../features/base/util/adiaLiveSettings';

export const createAlcMiddleware = () => {
    function calculateDeviceInfo(
        communicationMode,
        audioMuted,
        videoMuted,
        screenShareActive
    ) {
        const deviceInfo = {
            audio: false,
            video: false,
            screen: false,
        };
        switch (communicationMode) {
            case COMMUNICATION_MODES.AUDIO: {
                deviceInfo.audio = true;
                break;
            }
            case COMMUNICATION_MODES.VIDEO: {
                deviceInfo.video = true;
                break;
            }
            case COMMUNICATION_MODES.BOTH: {
                deviceInfo.audio = true;
                deviceInfo.video = true;
                break;
            }
            default: {
                break;
            }
        }
        if (audioMuted) {
            deviceInfo.audio = false;
        }
        if (videoMuted) {
            deviceInfo.video = false;
        }
        if (screenShareActive) {
            deviceInfo.screen = true;
        }
        return deviceInfo;
    }

    let alc;

    return (store) => (next) => (action) => {
        const alcMeetingJoined = store.getState().meetings.alcMeetingJoined;

        switch (action.type) {
            case ALC_INIT: {
                if (alc) {
                    alc.close();
                }

                const serviceUrl = Config.serviceUrl;
                const serviceId = action.serviceId;

                const flags =
                    store.getState().landingPage.publicServiceInfo &&
                    store.getState().landingPage.publicServiceInfo.flags;
                const callManagerActive = flags
                    ? adiaLiveSettings.flagsToSettings(flags).callManager
                    : false;
                const subscriptions = callManagerActive
                    ? ['serviceStateUpdate']
                    : [];

                const notificationHandler = function (msg) {
                    log.debug(msg);
                    if (msg.hasOwnProperty('serverTime')) {
                        store.dispatch(
                            alcUpdateServerTimeDiff(msg.serverTime - Date.now())
                        );
                    }
                    switch (msg.type) {
                        case 'initialize':
                            store.dispatch(alcInitialized());
                            break;
                        case 'reconnecting':
                            store.dispatch(alcReconnecting());
                            break;
                        case 'sessionRestored':
                            store.dispatch(alcSessionRestored());
                            break;
                        case 'clientInfoUpdate':
                            store.dispatch(alcClientInfoUpdate(msg.data));
                            break;
                        case 'joinedMeeting':
                            store.dispatch(
                                alcJoinMeetingSuccess(
                                    msg.data.joinOptions,
                                    msg.data.meetingInfo,
                                    msg.data.saAuth,
                                    store.getState().base.common.responsiveMode
                                )
                            );
                            break;
                        case 'leftMeeting': {
                            switch (msg.data) {
                                case 'kicked':
                                    store.dispatch(alcKicked());
                                    break;
                                case 'completed':
                                    store.dispatch(alcMeetingCompleted());
                                    break;
                                case 'transferred':
                                    store.dispatch(alcTransferred());
                                    break;
                                default:
                                    store.dispatch(alcLeaveMeetingSuccess());
                                    break;
                            }
                            break;
                        }
                        case 'meetingInfo': {
                            store.dispatch(
                                alcGetMeetingInfoSuccess(
                                    msg.data.meetingInfo,
                                    msg.data.participantInfo,
                                    msg.serverTime
                                )
                            );
                            break;
                        }
                        case 'meetingInfoUpdate':
                            const state = store.getState();
                            // check if active sidePanelApplication was disabled
                            if (
                                state.meetings.activeSidePanelApplicationId !==
                                null
                            ) {
                                const activeSidePanelApplications =
                                    getActiveSidePanelApplications(
                                        msg.data,
                                        state.landingPage.publicServiceInfo
                                    );
                                if (
                                    !activeSidePanelApplications.includes(
                                        state.meetings
                                            .activeSidePanelApplicationId
                                    )
                                ) {
                                    const newApplicationId =
                                        activeSidePanelApplications.length > 0
                                            ? activeSidePanelApplications[0]
                                            : null;
                                    store.dispatch(
                                        sidePanelShowApplication(
                                            newApplicationId
                                        )
                                    );
                                }
                            }
                            // check if active standalone sharedApplication was disabled
                            if (state.meetings.clientInfo.standalone) {
                                const activeSharedApplications =
                                    getActiveSharedApplications(
                                        msg.data,
                                        state.landingPage.publicServiceInfo,
                                        state.meetings.clientInfo.standalone
                                    );
                                if (
                                    !activeSharedApplications.includes(
                                        state.meetings.ownApplicationId
                                    )
                                ) {
                                    const newApplicationId =
                                        activeSharedApplications.length > 0
                                            ? activeSharedApplications[0]
                                            : null;
                                    store.dispatch(
                                        switchOwnSharedApplication(
                                            newApplicationId
                                        )
                                    );
                                }
                            }
                            store.dispatch(alcMeetingInfoUpdate(msg.data));
                            break;
                        case 'podiumInfo':
                        case 'podiumInfoUpdate':
                            store.dispatch(
                                alcPodiumInfoUpdate(
                                    msg.data.podiumInfo,
                                    msg.data.changes
                                )
                            );
                            break;

                        case 'streamInfo':
                        case 'streamInfoUpdate':
                            store.dispatch(
                                alcStreamInfoUpdate(
                                    msg.data.streamInfo,
                                    msg.data.changes
                                )
                            );
                            break;

                        case 'meetingParticipantsUpdate':
                            store.dispatch(
                                alcMeetingParticipantsUpdate(msg.data)
                            );
                            break;
                        case 'changeDeviceUsage':
                            if (msg.data.audio === false) {
                                store.dispatch(webRtcMuteAudio(true));
                                store.dispatch(
                                    showMessage({
                                        contentId: 'msgRemoteMute',
                                        type: 'info',
                                    })
                                );
                            }
                            break;
                        case 'changeSpeakerPermission': {
                            store.dispatch(
                                alcChangeSpeakerPermission(
                                    msg.data.permission,
                                    msg.data.joinOptions,
                                    msg.data.saAuth
                                )
                            );
                            const state = store.getState();
                            if (
                                state.meetings.meetingInfo &&
                                state.meetings.meetingInfo.type !==
                                    MEETING_TYPES.PODIUM
                            ) {
                                if (
                                    msg.data.permission &&
                                    !(
                                        state.meetings.clientInfo
                                            .speakerPermissionState ===
                                        SPEAKER_PERMISSION_STATE.GRANTED
                                    )
                                ) {
                                    store.dispatch(showSpeakerConfirmation());
                                }
                                if (
                                    !msg.data.permission &&
                                    store.getState().deviceSettings !==
                                        COMMUNICATION_MODES.NONE
                                ) {
                                    if (
                                        state.meetings
                                            .speakerConfirmationVisible
                                    ) {
                                        store.dispatch(
                                            hideSpeakerConfirmation()
                                        );
                                    }
                                    store.dispatch(hideSettings());
                                    store.dispatch(
                                        webRtcChangeCommunicationMode(
                                            COMMUNICATION_MODES.NONE
                                        )
                                    );
                                }
                                if (
                                    !msg.data.permission &&
                                    store.getState().meetings.clientInfo &&
                                    store.getState().meetings.clientInfo
                                        .deviceInfo.screen
                                ) {
                                    store.dispatch(webRtcStopScreenShare());
                                }
                            }
                            break;
                        }
                        case 'serviceStateUpdate': {
                            store.dispatch(alcServiceStateUpdate(msg.data));
                            break;
                        }
                        case 'changeAdmittance': {
                            store.dispatch(
                                alcAdmittanceChanged(msg.data.admitted)
                            );
                            break;
                        }
                        case 'serviceError':
                            store.dispatch(
                                alcError(msg.data.context, msg.data.error)
                            );
                            break;
                        default:
                            break;
                    }
                };

                alc = new Connector({
                    serviceUrl,
                    serviceId,
                    notificationHandler,
                    subscriptions,
                });

                alc.init();

                return next(action);
            }
            case ALC_GET_MEETING_INFO: {
                alc.getMeetingInfo(action.meetingId, action.captchaCode);
                return next(action);
            }
            case ALC_JOIN_MEETING: {
                const deviceInfo = calculateDeviceInfo(
                    action.communicationMode,
                    false,
                    false,
                    false
                );
                let participantInfo;
                if (action.participantName) {
                    participantInfo = {
                        name: action.participantName,
                        email: action.email,
                    };
                }
                alc.joinMeeting(
                    action.meetingId,
                    participantInfo,
                    deviceInfo,
                    action.modes,
                    action.recordingConfirmed,
                    action.token
                );
                return next(action);
            }
            case ALC_LEAVE_MEETING: {
                if (alcMeetingJoined) {
                    alc.leaveMeeting();
                }
                return next(action);
            }
            case ALC_PARTICIPANT_AUTH: {
                alc.participantAuth(action.authToken);
                return next(action);
            }
            case ALC_SET_CURRENT_APP: {
                if (alcMeetingJoined) {
                    alc.setCurrentApp(action.sharedApplicationId);
                }
                return next(action);
            }
            case ALC_SET_EDIT_PERMISSIONS: {
                if (alcMeetingJoined) {
                    alc.setEditPermissions(
                        action.userId,
                        action.editPermissions
                    );
                }
                return next(action);
            }
            case ALC_CHANGE_ACTIVE_USER: {
                if (alcMeetingJoined) {
                    alc.setCurrentActiveUser(action.userId);
                }
                return next(action);
            }
            case ALC_START_RECORDING: {
                if (alcMeetingJoined) {
                    alc.startRecording();
                }
                return next(action);
            }
            case ALC_STOP_RECORDING: {
                if (alcMeetingJoined) {
                    alc.stopRecording();
                }
                return next(action);
            }
            case ALC_MUTE_PARTICIPANT: {
                if (alcMeetingJoined) {
                    alc.setDeviceUsage(action.userId, { audio: false });
                }
                return next(action);
            }
            case ALC_SET_HAND_RAISED: {
                if (alcMeetingJoined) {
                    alc.setHandRaised(action.clientId, action.handRaised);
                }
                return next(action);
            }
            case ALC_REQUEST_SPEAKER_PERMISSION: {
                if (alcMeetingJoined) {
                    alc.requestSpeakerPermission(action.isRequesting);
                }
                return next(action);
            }
            case ALC_UPDATE_USER_MODE: {
                if (alcMeetingJoined) {
                    alc.updateUserMode(action.mode);
                }
                return next(action);
            }
            case ALC_START_CALL: {
                const deviceInfo = calculateDeviceInfo(
                    action.communicationMode,
                    false,
                    false,
                    false
                );
                let participantInfo;
                if (action.participantName) {
                    participantInfo = {
                        name: action.participantName,
                        email: action.email,
                    };
                }
                if (action.topicId) {
                    participantInfo.topicId = action.topicId;
                }
                alc.doAdhocCall(participantInfo, deviceInfo);
                return next(action);
            }
            case ALC_REQUEST_ADMITTANCE: {
                if (alc) {
                    alc.requestAdmittance(
                        action.meetingId,
                        action.name,
                        action.isRequesting
                    );
                }
                return next(action);
            }
            case ALC_CLOSE: {
                if (alc) {
                    alc.close();
                }
                return next(action);
            }
            case WEBRTC_MUTE_AUDIO: {
                if (alcMeetingJoined) {
                    const meetingsState = store.getState().meetings;
                    const deviceSettingsState = store.getState().deviceSettings;
                    const deviceInfo = calculateDeviceInfo(
                        deviceSettingsState.communicationMode,
                        action.shouldMute,
                        deviceSettingsState.currentSettings.videoMuted,
                        !!meetingsState.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_MUTE_VIDEO: {
                if (alcMeetingJoined) {
                    const meetingsState = store.getState().meetings;
                    const deviceSettingsState = store.getState().deviceSettings;
                    const deviceInfo = calculateDeviceInfo(
                        deviceSettingsState.communicationMode,
                        deviceSettingsState.currentSettings.audioMuted,
                        action.shouldMute,
                        !!meetingsState.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_START_SCREENSHARE_SUCCESS: {
                if (alcMeetingJoined) {
                    const state = store.getState();
                    if (
                        state.meetings.meetingInfo.type ===
                            MEETING_TYPES.WEBINAR &&
                        state.meetings.clientInfo.speakerPermissionState !==
                            SPEAKER_PERMISSION_STATE.GRANTED
                    ) {
                        // speaker permission revoked, need to cancel screensharing
                        store.dispatch(webRtcStopScreenShare());
                    } else {
                        const deviceSettingsState =
                            store.getState().deviceSettings;
                        const deviceInfo = calculateDeviceInfo(
                            deviceSettingsState.communicationMode,
                            deviceSettingsState.currentSettings.audioMuted,
                            deviceSettingsState.currentSettings.videoMuted,
                            true
                        );
                        alc.updateDeviceInfo(deviceInfo);
                    }
                }
                return next(action);
            }
            case WEBRTC_STOP_SCREENSHARE_SUCCESS: {
                if (alcMeetingJoined) {
                    const deviceSettingsState = store.getState().deviceSettings;
                    const deviceInfo = calculateDeviceInfo(
                        deviceSettingsState.communicationMode,
                        deviceSettingsState.currentSettings.audioMuted,
                        deviceSettingsState.currentSettings.videoMuted,
                        false
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_CHANGE_CAM_SUCCESS:
            case WEBRTC_CHANGE_MIC_SUCCESS:
            case WEBRTC_CHANGE_RES_SUCCESS: {
                if (alcMeetingJoined) {
                    const deviceInfo = calculateDeviceInfo(
                        store.getState().deviceSettings.communicationMode,
                        action.newSettings.audioMuted,
                        action.newSettings.videoMuted,
                        !!store.getState().meetings.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS:
            case WEBRTC_APPLY_RECOVERY_SETTINGS_SUCCESS: {
                if (alcMeetingJoined) {
                    const deviceInfo = calculateDeviceInfo(
                        action.communicationMode,
                        action.currentSettings.audioMuted,
                        action.currentSettings.videoMuted,
                        !!store.getState().meetings.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            // TODO: worarkound, implement properly
            case WEBRTC_JOIN_SUCCESS: {
                if (
                    store.getState().meetings.meetingInfo.type ===
                    MEETING_TYPES.PODIUM
                ) {
                    const deviceInfo = calculateDeviceInfo(
                        action.communicationMode,
                        action.currentSettings.audioMuted,
                        action.currentSettings.videoMuted,
                        !!store.getState().meetings.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case REVOKE_SPEAKER_PERMISSION: {
                if (alcMeetingJoined) {
                    alc.setSpeakerPermission(
                        store.getState().meetings.clientInfo.id,
                        false
                    );
                }
                return next(action);
            }
            case ALC_CONFIRM_RECORDING: {
                if (alcMeetingJoined) {
                    alc.confirmRecording(action.confirmed);
                }
                return next(action);
            }
            default:
                return next(action);
        }
    };
};
