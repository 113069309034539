import favicon from '../assets/favicons/favicon_css.ico';
import { MEETING_TYPES } from '../constants/constants';
import('../css/themes/Theme_css.scss');

export const Config = {
    serviceUrl: 'https://al5-css.adia.tv',
    serviceId: 'CSS',
    documentTitle: 'CSS',

    favicon,

    communicatorType: MEETING_TYPES.PHONE_CONSULTING,
    phoneConsultingAudioVideoDisabled: true,
    phoneConsultingScreenSharingDisabled: true,
};
