import React, { Component } from 'react';
import classNames from 'classnames';

import ConnectionIndicator from './ConnectionIndicator';
import AudioLevelIndicator from './AudioLevelIndicator';
import VideoContainer from './VideoContainer';
import CombiModeView from './CombiModeView';
import {
    RESPONSIVE_MODES,
    SCREEN_SHARING_MODES,
} from '../../constants/constants';

export default class VideoBox extends Component {
    render() {
        const hasVideo = !!(this.props.videoView && this.props.videoView.view);

        if (this.props.responsiveMode === RESPONSIVE_MODES.SMALL) {
            if (
                hasVideo &&
                this.props.userInfo.deviceInfo &&
                this.props.userInfo.deviceInfo.video
            ) {
                return (
                    <div className="mobile-video-box">
                        <VideoContainer videoView={this.props.videoView} />
                    </div>
                );
            } else return null;
        }

        let videoElement;

        if (
            !this.props.isMainVideo &&
            this.props.screenShareActive &&
            this.props.userInfo.userMode &&
            this.props.userInfo.userMode.screenSharing !==
                SCREEN_SHARING_MODES.VIDEO
        ) {
            if (
                this.props.userInfo.userMode.screenSharing ===
                    SCREEN_SHARING_MODES.COMBI &&
                hasVideo
            ) {
                videoElement = (
                    <CombiModeView
                        videoView={this.props.videoView}
                        screenShareView={this.props.screenShareView}
                    />
                );
            } else {
                videoElement = (
                    <VideoContainer videoView={this.props.screenShareView} />
                );
            }
        } else if (
            hasVideo &&
            (!this.props.isMainVideo ||
                (this.props.screenShareActive &&
                    this.props.userInfo.userMode &&
                    this.props.userInfo.userMode.screenSharing ===
                        SCREEN_SHARING_MODES.SCREEN))
        ) {
            videoElement = <VideoContainer videoView={this.props.videoView} />;
        } else {
            let placeholder;
            if (this.props.isMainVideo) {
                if (this.props.isCurrentActiveUser) {
                    placeholder = (
                        <div className="video-list-icon">
                            <i className="icon-open_in_new" />
                        </div>
                    );
                } else if (this.props.isActiveSpeaker) {
                    placeholder = (
                        <div className="video-list-icon">
                            <i className="icon-open_in_new" />
                        </div>
                    );
                } else {
                    placeholder = (
                        <div className="video-list-icon">
                            <i className="icon-open_in_new" />
                        </div>
                    );
                }
            } else {
                let nameParts;
                if (this.props.userInfo.info.name) {
                    nameParts = this.props.userInfo.info.name.split(' ');
                }
                let initials = '';
                if (nameParts) {
                    if (nameParts.length > 1 && nameParts[1] !== '') {
                        initials =
                            nameParts[0].substr(0, 1) +
                            nameParts[1].substr(0, 1);
                    } else {
                        initials = nameParts[0].substr(0, 2);
                    }
                }
                placeholder = (
                    <div className="video-list-icon">
                        <div className="name-initial">{initials}</div>
                    </div>
                );
            }
            videoElement = placeholder;
        }

        return (
            <div
                className={classNames('video-list-box', {
                    'video-box-active': this.props.isCurrentActiveUser,
                })}
            >
                <div className="participant-name-wrapper">
                    <div className="participant-name">
                        {this.props.userInfo.info.name}
                    </div>
                </div>
                {!this.props.recording && (
                    <ConnectionIndicator
                        userInfo={this.props.userInfo}
                        videoActive={this.props.userInfo.deviceInfo.video}
                        isSelf={this.props.isSelf}
                    />
                )}
                {videoElement}
                {!this.props.recording && (
                    <AudioLevelIndicator userId={this.props.userInfo.id} />
                )}
                {!this.props.userInfo.deviceInfo.audio &&
                    !this.props.recording && (
                        <div className="muted-icon">
                            <i className="icon-mic-disabled" />
                        </div>
                    )}
            </div>
        );
    }
}
