import React from "react";
import classNames from "classnames";

function PlayerTopBar({ visible, content, className }) {
    return <div className={classNames("player-top-bar", className, { visible: visible })}>{content}</div>;
}

export default PlayerTopBar;
/* PlayerTopBar props: (TODO: use props types package?)
    visible,
    content,
    className
*/
