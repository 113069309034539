import * as types from './actionTypes';
import {
    AL_APPLICATIONS,
    MEETING_TYPES,
    RESPONSIVE_MODES,
    SA_APPLICATIONS,
    SPEAKER_PERMISSION_STATE,
} from '../../constants/constants';

// TODO: move alcInitialized, webRtcInitSuccess to different slice?

export const initialState = {
    alcInitialized: false,
    alcMeetingJoined: false,
    alcReconnecting: false,
    webRtcInitialized: false,
    webRtcFlags: {},
    webRtcMeetingJoined: false,
    clientInfo: undefined,
    meetingInfo: undefined,
    ownVideoView: undefined,
    ownScreenShareView: undefined,
    videoViews: [],
    screenShareViews: [],
    meetingParticipants: [],
    mainVideoId: null,
    activeSpeakerId: null,
    screenShareOn: false,
    sharedApplicationsShown: false,
    ownApplicationId: null,
    chatMessagePending: false,
    documentPending: false,
    saAuthToken: undefined,
    controlsVisible: true,
    menuVisible: false,
    infoPanelVisible: false,
    fullscreenActive: false,
    serverTimeDiff: 0,
    saIFrameInitialized: false,
    saIFrameReady: false,
    sidePanelIFrameInitialized: false,
    sidePanelIFrameReady: false,
    speakerConfirmationVisible: false,
    activeSidePanelApplicationId: null,
    lastActiveSidePanelApplicationId: null,
    streamChatMessagePending: false,
    streamSurveyPending: false,
    streamDocumentPending: false,
    sidePanelMenuVisible: false,

    podiumInfo: undefined,
    streamInfo: undefined,
    isOnAir: false,
    wasOnAir: false,
};

export default function meetings(state = initialState, action) {
    switch (action.type) {
        case types.WEBRTC_INIT_SUCCESS: {
            return {
                ...state,
                webRtcInitialized: true,
                webRtcFlags: action.webRtcFlags,
            };
        }
        case types.ALC_INITIALIZED: {
            return {
                ...initialState,
                alcInitialized: true,
                alcMeetingJoined: false,
                webRtcInitialized: state.webRtcInitialized,
                webRtcMeetingJoined: state.webRtcMeetingJoined,
                webRtcFlags: state.webRtcFlags,
            };
        }
        case types.ALC_RECONNECTING: {
            return {
                ...state,
                alcReconnecting: true,
            };
        }
        case types.ALC_SESSION_RESTORED: {
            return {
                ...state,
                alcReconnecting: false,
            };
        }
        case types.ALC_JOIN_MEETING_SUCCESS: {
            let sharedApplicationsShown = state.sharedApplicationsShown;
            if (action.meetingInfo.currentApp) {
                sharedApplicationsShown = true;
            }
            let activeSidePanelApplicationId;

            if (action.responsiveMode < RESPONSIVE_MODES.MEDIUM) {
                activeSidePanelApplicationId = null;
            } else {
                switch (action.meetingInfo.type) {
                    case MEETING_TYPES.PODIUM: {
                        if (action.meetingInfo.additionalMetadata) {
                            if (
                                action.meetingInfo.additionalMetadata
                                    .hideChatTab &&
                                action.meetingInfo.additionalMetadata
                                    .hidePollsTab &&
                                action.meetingInfo.additionalMetadata
                                    .hideDocumentsTab
                            ) {
                                activeSidePanelApplicationId = null;
                            } else if (
                                action.meetingInfo.additionalMetadata
                                    .hideChatTab
                            ) {
                                if (
                                    action.meetingInfo.additionalMetadata
                                        .hideDocumentsTab
                                ) {
                                    activeSidePanelApplicationId =
                                        SA_APPLICATIONS.STREAM_SURVEYS;
                                } else {
                                    activeSidePanelApplicationId =
                                        SA_APPLICATIONS.STREAM_DOCUMENTS;
                                }
                            } else {
                                activeSidePanelApplicationId =
                                    SA_APPLICATIONS.STREAM_CHAT;
                            }
                        } else {
                            activeSidePanelApplicationId =
                                SA_APPLICATIONS.STREAM_CHAT;
                        }
                        break;
                    }
                    default: {
                        activeSidePanelApplicationId = SA_APPLICATIONS.CHAT;
                    }
                }
            }

            return {
                ...state,
                alcMeetingJoined: true,
                meetingInfo: action.meetingInfo,
                sharedApplicationsShown,
                activeSidePanelApplicationId,
                lastActiveSidePanelApplicationId: activeSidePanelApplicationId,
                saAuthToken: action.saAuth ? action.saAuth.token : undefined,
            };
        }
        case types.ALC_CLIENT_INFO_UPDATE: {
            const controlsVisible = action.clientInfo.recorder
                ? false
                : state.controlsVisible;

            return {
                ...state,
                clientInfo: action.clientInfo,
                controlsVisible,
            };
        }
        case types.ALC_CHANGE_SPEAKER_PERMISSION: {
            return {
                ...state,
                saAuthToken: action.saAuth
                    ? action.saAuth.token
                    : state.saAuthToken,
            };
        }
        case types.WEBRTC_JOIN_SUCCESS: {
            return {
                ...state,
                webRtcMeetingJoined: true,
                ownVideoView: action.ownVideoView,
            };
        }
        case types.WEBRTC_USERJOINED: {
            if (action.videoView.type === 'remote') {
                const videoViews = state.videoViews.slice();
                const previousIndex = videoViews.findIndex(
                    (el) => el.userId === action.videoView.userId
                );
                if (previousIndex !== -1) {
                    videoViews[previousIndex] = action.videoView;
                } else {
                    videoViews.push(action.videoView);
                }
                const mainVideoId = determineMainVideoId(
                    state.activeSpeakerId,
                    state.meetingParticipants,
                    videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                return {
                    ...state,
                    videoViews: videoViews,
                    mainVideoId,
                };
            } else if (action.videoView.type === 'screen') {
                const screenShareViews = state.screenShareViews.slice();
                const previousIndex = screenShareViews.findIndex(
                    (el) => el.userId === action.videoView.userId
                );
                if (previousIndex !== -1) {
                    screenShareViews[previousIndex] = action.videoView;
                } else {
                    screenShareViews.push(action.videoView);
                }
                return {
                    ...state,
                    screenShareViews: screenShareViews,
                    //mainVideoId
                };
            } else {
                return state;
            }
        }
        case types.WEBRTC_USERLEFT: {
            if (action.userType === 'remote') {
                let videoViews = state.videoViews.slice();
                const indexToDelete = videoViews.findIndex(
                    (el) => el.userId === action.userId
                );
                if (indexToDelete !== -1) {
                    videoViews.splice(indexToDelete, 1);
                }
                const mainVideoId = determineMainVideoId(
                    state.activeSpeakerId,
                    state.meetingParticipants,
                    videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                return {
                    ...state,
                    videoViews: videoViews,
                    mainVideoId,
                };
            } else if (action.userType === 'screen') {
                let screenShareViews = state.screenShareViews.slice();
                const indexToDelete = screenShareViews.findIndex(
                    (el) => el.userId === action.userId
                );
                if (indexToDelete !== -1) {
                    screenShareViews.splice(indexToDelete, 1);
                }
                return {
                    ...state,
                    screenShareViews: screenShareViews,
                    //mainVideoId
                };
            } else {
                return state;
            }
        }
        case types.WEBRTC_SPEAKER_CHANGED: {
            // ignore self
            if (action.userId === state.clientInfo.id) {
                return state;
            } else {
                const mainVideoId = determineMainVideoId(
                    action.userId,
                    state.meetingParticipants,
                    state.videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                return {
                    ...state,
                    activeSpeakerId: action.userId,
                    mainVideoId,
                };
            }
        }
        case types.WEBRTC_START_SCREENSHARE_SUCCESS: {
            return {
                ...state,
                ownScreenShareView: action.screenShareView,
            };
        }
        case types.WEBRTC_STOP_SCREENSHARE_SUCCESS: {
            return {
                ...state,
                ownScreenShareView: undefined,
            };
        }
        case types.WEBRTC_CLOSE: {
            return {
                ...state,
                webRtcInitialized: false,
                webRtcFlags: {},
            };
        }
        case types.WEBRTC_RECONNECTING: {
            return {
                ...state,
                webRtcReconnecting: true,
            };
        }
        case types.WEBRTC_RECONNECTING_SUCCESS: {
            return {
                ...state,
                webRtcReconnecting: false,
            };
        }
        case types.ALC_MEETING_PARTICIPANTS_UPDATE: {
            const adviser = [];
            const clients = [];
            action.meetingParticipants.forEach((el) => {
                if (el.isAdviser) {
                    adviser.push(el);
                } else {
                    clients.push(el);
                }
            });
            const meetingParticipants = adviser.concat(clients);
            const mainVideoId = determineMainVideoId(
                state.activeSpeakerId,
                meetingParticipants,
                state.videoViews,
                state.ownVideoView,
                state.meetingInfo
            );
            return {
                ...state,
                meetingParticipants,
                mainVideoId,
            };
        }
        case types.ALC_MEETING_INFO_UPDATE: {
            const mainVideoId = determineMainVideoId(
                state.activeSpeakerId,
                state.meetingParticipants,
                state.videoViews,
                state.ownVideoView,
                action.meetingInfo
            );
            let sharedApplicationsShown = false;
            if (
                action.meetingInfo.currentApp ||
                (state.ownApplicationId && state.sharedApplicationsShown)
            ) {
                sharedApplicationsShown = true;
            }
            return {
                ...state,
                meetingInfo: action.meetingInfo,
                mainVideoId,
                sharedApplicationsShown,
            };
        }
        case types.ALC_PODIUM_INFO_UPDATE: {
            return {
                ...state,
                podiumInfo: { ...action.podiumInfo },
            };
        }
        case types.ALC_STREAM_INFO_UPDATE: {
            const isOnAir = action.streamInfo && action.streamInfo.isOnAir;
            const offAirTime =
                action.streamInfo && action.streamInfo.offAirTime;
            return {
                ...state,
                streamInfo: { ...action.streamInfo },
                isOnAir: isOnAir,
                wasOnAir: state.isOnAir ? !isOnAir : !!offAirTime,
            };
        }
        case types.ALC_SET_CURRENT_APP: {
            let ownApplicationId = action.sharedApplicationId;
            if (!ownApplicationId) {
                ownApplicationId = state.ownApplicationId;
            }
            return {
                ...state,
                ownApplicationId,
            };
        }
        case types.ALC_UPDATE_SERVERTIME_DIFF: {
            return {
                ...state,
                serverTimeDiff: action.serverTimeDiff,
            };
        }
        case types.ALC_CLOSE: {
            return {
                ...state,
                alcInitialized: false,
            };
        }
        case types.JOIN_MEETING_SUCCESS: {
            const mainVideoId = determineMainVideoId(
                state.activeSpeakerId,
                state.meetingParticipants,
                state.videoViews,
                state.ownVideoView,
                state.meetingInfo
            );
            return {
                ...state,
                mainVideoId,
            };
        }
        case types.SA_SHOW: {
            let ownApplicationId = state.ownApplicationId
                ? state.ownApplicationId
                : SA_APPLICATIONS.SHOWBOARD;
            return {
                ...state,
                sharedApplicationsShown: true,
                ownApplicationId,
            };
        }
        case types.SA_SWITCH_OWN: {
            return {
                ...state,
                ownApplicationId: action.sharedApplicationId,
            };
        }
        case types.SA_HIDE: {
            return {
                ...state,
                sharedApplicationsShown: false,
                ownApplicationId: null,
            };
        }
        case types.DOCUMENTS_NEW_UPLOAD: {
            if (
                state.activeSidePanelApplicationId === SA_APPLICATIONS.DOCUMENTS
            ) {
                return state;
            } else {
                return {
                    ...state,
                    documentPending: true,
                };
            }
        }
        case types.CHAT_NEW_MESSAGE: {
            if (state.activeSidePanelApplicationId === SA_APPLICATIONS.CHAT) {
                return state;
            } else {
                return {
                    ...state,
                    chatMessagePending: true,
                };
            }
        }
        case types.ALC_LEAVE_MEETING: {
            return {
                ...state,
                alcMeetingJoined: false,
                meetingInfo: undefined,
                meetingParticipants: [],
            };
        }
        case types.ALC_TRANSFERRED:
        case types.ALC_MEETING_COMPLETED:
        case types.ALC_KICKED: {
            return {
                ...state,
                alcMeetingJoined: false,
                meetingInfo: undefined,
                meetingParticipants: [],
                mainVideoId: null,
                activeSpeakerId: null,
                sharedApplicationsShown: false,
                ownApplicationId: null,
                chatMessagePending: false,
                documentPending: false,
                menuVisible: false,
                infoPanelVisible: false,
                activeSidePanelApplicationId: SA_APPLICATIONS.STREAM_CHAT,
                streamChatMessagePending: false,
                streamSurveyPending: false,
                streamDocumentPending: false,
            };
        }
        case types.WEBRTC_LEAVE:
        case types.WEBRTC_RECONNECTING_FAILURE: {
            return {
                ...state,
                webRtcMeetingJoined: false,
                ownVideoView: undefined,
                videoViews: [],
                ownScreenShareView: undefined,
                screenShareViews: [],
                screenShareOn: false,
                webRtcReconnecting: false,
            };
        }
        case types.LEAVE_MEETING: {
            return {
                ...state,
                mainVideoId: null,
                activeSpeakerId: null,
                sharedApplicationsShown: false,
                ownApplicationId: null,
                chatMessagePending: false,
                documentPending: false,
                menuVisible: false,
                infoPanelVisible: false,
                activeSidePanelApplicationId: SA_APPLICATIONS.STREAM_CHAT,
                streamChatMessagePending: false,
                streamSurveyPending: false,
                streamDocumentPending: false,
                sidePanelMenuVisible: false,
            };
        }
        case types.CONTROLS_SHOW: {
            return {
                ...state,
                controlsVisible: true,
            };
        }
        case types.CONTROLS_HIDE: {
            return {
                ...state,
                controlsVisible: false,
                menuVisible: false,
                infoPanelVisible: false,
                sidePanelMenuVisible: false,
            };
        }
        case types.MENU_SHOW: {
            return {
                ...state,
                menuVisible: true,
                infoPanelVisible: false,
                sidePanelMenuVisible: false,
            };
        }
        case types.MENU_HIDE: {
            return {
                ...state,
                menuVisible: false,
            };
        }
        case types.SIDE_PANEL_MENU_SHOW: {
            return {
                ...state,
                sidePanelMenuVisible: true,
                infoPanelVisible: false,
                menuVisible: false,
            };
        }
        case types.SIDE_PANEL_MENU_HIDE: {
            return {
                ...state,
                sidePanelMenuVisible: false,
            };
        }
        case types.INFO_PANEL_SHOW: {
            return {
                ...state,
                infoPanelVisible: true,
                menuVisible: false,
                sidePanelMenuVisible: false,
            };
        }
        case types.INFO_PANEL_HIDE: {
            return {
                ...state,
                infoPanelVisible: false,
            };
        }
        case types.FULLSCREEN_CHANGE: {
            return {
                ...state,
                fullscreenActive: action.fullscreenActive,
            };
        }
        case types.SA_IFRAME_NEW_REF: {
            return {
                ...state,
                saIFrameReady: false,
                saIFrameInitialized: false,
            };
        }
        case types.SA_IFRAME_INIT_CONFIRMED: {
            return {
                ...state,
                saIFrameInitialized: true,
            };
        }
        case types.SA_IFRAME_READY: {
            return {
                ...state,
                saIFrameReady: true,
            };
        }
        case types.SIDE_PANEL_IFRAME_NEW_REF: {
            return {
                ...state,
                sidePanelIFrameReady: false,
                sidePanelIFrameInitialized: false,
            };
        }
        case types.SIDE_PANEL_IFRAME_INIT_CONFIRMED: {
            return {
                ...state,
                sidePanelIFrameInitialized: true,
            };
        }
        case types.SIDE_PANEL_IFRAME_READY: {
            return {
                ...state,
                sidePanelIFrameReady: true,
            };
        }
        case types.SIDE_PANEL_SHOW_APPLICATION: {
            switch (action.applicationId) {
                case SA_APPLICATIONS.STREAM_CHAT: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                        streamChatMessagePending: false,
                        chatMessagePending: false,
                    };
                }
                case SA_APPLICATIONS.STREAM_SURVEYS: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                        streamSurveyPending: false,
                    };
                }
                case SA_APPLICATIONS.STREAM_DOCUMENTS: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                        streamDocumentPending: false,
                        documentPending: false,
                    };
                }
                case AL_APPLICATIONS.PARTICIPANTS: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                        speakerRequestPending: false,
                    };
                }
                case SA_APPLICATIONS.CHAT: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                        chatMessagePending: false,
                        streamChatMessagePending: false,
                    };
                }
                case SA_APPLICATIONS.DOCUMENTS: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                        documentPending: false,
                        streamDocumentPending: false,
                    };
                }
                default: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                    };
                }
            }
        }
        case types.STREAM_CHAT_NEW_MESSAGE: {
            if (
                state.activeSidePanelApplicationId ===
                SA_APPLICATIONS.STREAM_CHAT
            ) {
                return state;
            } else {
                return {
                    ...state,
                    streamChatMessagePending: true,
                };
            }
        }
        case types.STREAM_CHAT_NEW_DOCUMENT: {
            if (
                state.activeSidePanelApplicationId ===
                SA_APPLICATIONS.STREAM_DOCUMENTS
            ) {
                return state;
            } else {
                return {
                    ...state,
                    streamDocumentPending: true,
                };
            }
        }
        case types.STREAM_CHAT_NEW_SURVEY: {
            if (
                state.activeSidePanelApplicationId ===
                SA_APPLICATIONS.STREAM_SURVEYS
            ) {
                return state;
            } else {
                return {
                    ...state,
                    streamSurveyPending: true,
                };
            }
        }
        case types.SPEAKER_CONFIRMATION_SHOW: {
            return {
                ...state,
                speakerConfirmationVisible: true,
            };
        }
        case types.SPEAKER_CONFIRMATION_HIDE: {
            return {
                ...state,
                speakerConfirmationVisible: false,
            };
        }
        default:
            return state;
    }

    function determineMainVideoId(
        activeSpeakerId,
        meetingParticipants,
        videoViews,
        ownVideoView,
        meetingInfo
    ) {
        if (!meetingInfo) {
            return null;
        }
        // if shared application shown no video is big
        else if (meetingInfo.currentApp) {
            return null;
            // if currentActiveUser is set
        } else if (meetingInfo.currentActiveUser) {
            return meetingInfo.currentActiveUser;
            // else current speaker is big (if in meeting)
        } else if (
            activeSpeakerId &&
            meetingParticipants.find((el) => el.id === activeSpeakerId)
        ) {
            return activeSpeakerId;
            // ownVideo is big if no one else in meeting (excluding standalone users)
        } else if (
            ownVideoView &&
            !meetingParticipants.find((participant) => {
                return (
                    (participant.id !== ownVideoView.userId &&
                        !participant.standalone &&
                        !participant.recorder &&
                        !(
                            meetingInfo.type === MEETING_TYPES.WEBINAR &&
                            !participant.speakerPermissionState ===
                                SPEAKER_PERMISSION_STATE.GRANTED
                        )) ||
                    (participant.id === ownVideoView.userId &&
                        participant.recorder)
                );
            })
        ) {
            return ownVideoView.userId;
            // if nothing else applies just show the first webrtc user found
        } else {
            for (let i = 0; i < videoViews.length; i++) {
                if (
                    meetingParticipants.findIndex(
                        (el) =>
                            el.id === videoViews[i].userId &&
                            (el.isAdviser ||
                                meetingInfo.type !== MEETING_TYPES.WEBINAR ||
                                el.speakerPermissionState ===
                                    SPEAKER_PERMISSION_STATE.GRANTED)
                    ) !== -1
                ) {
                    return videoViews[i].userId;
                }
            }
        }
        // if no webrtc views select any user (not self)
        if (ownVideoView && meetingParticipants.length > 1) {
            const participant = meetingParticipants.find(
                (el) =>
                    el.id !== ownVideoView.userId &&
                    !el.recorder &&
                    !el.standalone &&
                    !(
                        meetingInfo.type === MEETING_TYPES.WEBINAR &&
                        !el.speakerPermissionState ===
                            SPEAKER_PERMISSION_STATE.GRANTED
                    )
            );
            if (participant) {
                return participant.id;
            }
        }
        return null;
    }
}
