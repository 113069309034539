import * as types from './actionTypes';

export const initialState = {
    displayStream: false,
    chatOpen: true,
};

export default function livePlayer(state = initialState, action) {
    switch (action.type) {
        case types.TOGGLE_CHAT:
            return {
                ...state,
                chatOpen: !state.chatOpen,
            };

        case types.SHOW_STREAM:
            return {
                ...state,
                displayStream: true,
            };

        case types.HIDE_STREAM:
            return {
                ...state,
                displayStream: false,
            };

        default: {
            return state;
        }
    }
}
