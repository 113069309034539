import * as types from './actionTypes';
import { RESPONSIVE_ORIENTATIONS } from '../../../constants/constants';

export const initialState = {
    responsiveMode: 0,
    responsiveOrientation: RESPONSIVE_ORIENTATIONS.LANDSCAPE,
};

export default function common(state = initialState, action) {
    switch (action.type) {
        case types.RESPONSIVE_MODE_CHANGE: {
            return {
                ...state,
                responsiveMode: action.responsiveMode,
                responsiveOrientation: action.responsiveOrientation,
            };
        }
        default:
            return state;
    }
}
