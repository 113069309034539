import {
    all,
    cancel,
    put,
    race,
    select,
    take,
    takeEvery,
    spawn,
} from 'redux-saga/effects';

import {
    ALC_ADMITTANCE_CHANGED,
    ALC_CHANGE_SPEAKER_PERMISSION,
    ALC_CONFIRM_RECORDING,
    ALC_ERROR,
    ALC_INITIALIZED,
    ALC_JOIN_MEETING_SUCCESS,
    ALC_KICKED,
    ALC_MEETING_COMPLETED,
    ALC_MEETING_INFO_UPDATE,
    ALC_PODIUM_INFO_UPDATE,
    ALC_RECONNECTING,
    ALC_SESSION_RESTORED,
    ALC_STREAM_INFO_UPDATE,
    ALC_TRANSFERRED,
    JOIN_MEETING,
    JOIN_MEETING_STANDALONE,
    JOIN_PODIUM,
    LEAVE_MEETING,
    WEBRTC_INIT_SUCCESS,
    WEBRTC_JOIN_FAILURE,
    WEBRTC_JOIN_SUCCESS,
    WEBRTC_RECONNECTING_FAILURE,
    WEBRTC_START_LOCAL_MEDIA_FAILURE,
    WEBRTC_START_LOCAL_MEDIA_SUCCESS,
} from './actionTypes';
import {
    alcClose,
    alcJoinMeeting,
    alcLeaveMeeting,
    alcRequestAdmittance,
    joinMeeting as joinMeetingAction,
    joinMeetingFailure,
    joinMeetingStandaloneSuccess,
    joinMeetingSuccess,
    joinPodiumFailure,
    joinPodiumSuccess,
    revokeSpeakerPermission,
    showSharedApplications,
    switchOwnSharedApplication,
    webRtcClose,
    webRtcJoin,
    webRtcLeave,
    webRtcStartLocalMedia,
    webRtcStopLocalMedia,
} from './actions';
import {
    alcStartCall,
    changeAppState,
    getMeetingInfo as requestMeetingInfo,
    newError,
    setTopicId,
} from '../landingPage/actions';
import {
    showSettings,
    webRtcChangeCommunicationMode,
    webRtcSettingsError,
} from '../deviceSettings/actions';
import {
    alcErrorCodeToErrorId,
    webRtcCommunicationModeNone,
    webRtcErrorCodeToErrorId,
} from '../base/util/helpers';
import {
    APP_STATE,
    COMMUNICATION_MODES,
    MEETING_TYPES,
    SA_APPLICATIONS,
} from '../../constants/constants';
import { WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS } from '../deviceSettings/actionTypes';
import { showMessage } from '../base/messages/actions';
import {
    ALC_GET_MEETING_INFO_SUCCESS,
    CHANGE_APP_STATE,
    INITIATE_CALL,
} from '../landingPage/actionTypes';
import { appStateWorker, streamInfoWorker } from '../livePlayer/sagas';

const getAlcMeetingJoined = (state) => state.meetings.alcMeetingJoined;
const getWebRtcMeetingJoined = (state) => state.meetings.webRtcMeetingJoined;
const getAlcInitialized = (state) => state.meetings.alcInitialized;
const getWebRtcInitialized = (state) => state.meetings.webRtcInitialized;
const getCommunicationMode = (state) => state.deviceSettings.communicationMode;
const getMeetingType = (state) => state.landingPage.meetingInfo.type;
const getAppState = (state) => state.landingPage.appState;
const getParticipantInfo = (state) => state.landingPage.participantInfo;

function* joinMeeting() {
    while (true) {
        const { meetingId, participantName, email, modes, token } = yield take(
            JOIN_MEETING
        );
        const webRtcInitialized = yield select(getWebRtcInitialized);
        // wait until webRtc layer is initialized
        if (!webRtcInitialized) {
            yield take(WEBRTC_INIT_SUCCESS);
        }
        const alcInitialized = yield select(getAlcInitialized);
        // wait until alc initialized
        if (!alcInitialized) {
            yield take(ALC_INITIALIZED);
        }
        // set communication mode to none when joining a webinar or phoneConsulting or overwrite over get parameter is set
        const type = yield select(getMeetingType);
        if (
            type === MEETING_TYPES.WEBINAR ||
            type === MEETING_TYPES.PHONE_CONSULTING ||
            (modes && modes.recorder) ||
            webRtcCommunicationModeNone
        ) {
            yield put(webRtcChangeCommunicationMode(COMMUNICATION_MODES.NONE));
            yield take(WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS);
        }
        // try to start local media first
        yield put(webRtcStartLocalMedia());
        const startLocalMediaAction = yield take([
            WEBRTC_START_LOCAL_MEDIA_SUCCESS,
            WEBRTC_START_LOCAL_MEDIA_FAILURE,
        ]);
        if (startLocalMediaAction.type === WEBRTC_START_LOCAL_MEDIA_SUCCESS) {
            // if local media could be started, join alc meeting
            yield put(
                alcJoinMeeting(
                    meetingId,
                    participantName,
                    email,
                    startLocalMediaAction.communicationMode,
                    undefined,
                    modes,
                    token
                )
            );
            while (true) {
                let alcJoinAction = yield take([
                    ALC_JOIN_MEETING_SUCCESS,
                    ALC_ERROR,
                ]);
                // if recording is running and confirmation is needed, ask user and join again
                if (
                    alcJoinAction.type === ALC_ERROR &&
                    alcJoinAction.context === 'joinMeeting' &&
                    alcJoinAction.error.errorNo === 40104
                ) {
                    const confirmRecordingAction = yield take(
                        ALC_CONFIRM_RECORDING
                    );
                    if (confirmRecordingAction.confirmed === true) {
                        yield put(
                            alcJoinMeeting(
                                meetingId,
                                participantName,
                                email,
                                startLocalMediaAction.communicationMode,
                                true,
                                modes,
                                token
                            )
                        );
                    }
                } else if (
                    alcJoinAction.type === ALC_ERROR &&
                    alcJoinAction.context === 'joinMeeting' &&
                    alcJoinAction.error.errorNo === 40312
                ) {
                    // if admittance is required, request it and wait for answer
                    yield put(changeAppState(APP_STATE.AWAIT_ADMITTANCE));
                    const participantInfo = yield select(getParticipantInfo);
                    const name = participantName
                        ? participantName
                        : participantInfo.name;

                    yield put(alcRequestAdmittance(meetingId, name, true));

                    const admittanceChangedAction = yield take([
                        ALC_ADMITTANCE_CHANGED,
                        ALC_ERROR,
                    ]);
                    if (
                        admittanceChangedAction.type ===
                            ALC_ADMITTANCE_CHANGED &&
                        admittanceChangedAction.admitted === true
                    ) {
                        yield put(
                            alcJoinMeeting(
                                meetingId,
                                participantName,
                                email,
                                startLocalMediaAction.communicationMode,
                                undefined,
                                modes,
                                token
                            )
                        );
                    } else if (
                        admittanceChangedAction.type === ALC_ADMITTANCE_CHANGED
                    ) {
                        yield put(
                            changeAppState(APP_STATE.DISPLAY_MEETING_INFO)
                        );
                        yield put(newError('alcErrorNotAdmitted'));
                        break;
                    } else if (
                        admittanceChangedAction.context === 'requestAdmittance'
                    ) {
                        yield put(joinMeetingFailure());
                        yield put(webRtcStopLocalMedia());
                        yield put(
                            changeAppState(APP_STATE.DISPLAY_MEETING_INFO)
                        );
                        yield put(
                            newError(
                                alcErrorCodeToErrorId(
                                    admittanceChangedAction.error.errorNo
                                ),
                                admittanceChangedAction.error.errorNo
                            )
                        );
                        break;
                    }
                } else if (alcJoinAction.type === ALC_JOIN_MEETING_SUCCESS) {
                    // if alc meeting is joined, join webRtc meeting
                    yield put(
                        webRtcJoin(
                            alcJoinAction.joinOptions,
                            alcJoinAction.meetingInfo
                        )
                    );
                    const webRtcJoinAction = yield take([
                        WEBRTC_JOIN_SUCCESS,
                        WEBRTC_JOIN_FAILURE,
                    ]);
                    if (webRtcJoinAction.type === WEBRTC_JOIN_SUCCESS) {
                        yield put(joinMeetingSuccess());
                    } else {
                        yield put(joinMeetingFailure());
                        yield put(alcLeaveMeeting());
                        let errorCode;
                        if (webRtcJoinAction.error) {
                            errorCode = webRtcJoinAction.error.errorCode;
                        }
                        yield put(
                            changeAppState(APP_STATE.DISPLAY_MEETING_INFO)
                        );
                        yield put(
                            newError(
                                webRtcErrorCodeToErrorId(errorCode),
                                errorCode
                            )
                        );
                    }
                    break;
                } else {
                    if (alcJoinAction.context === 'joinMeeting') {
                        yield put(joinMeetingFailure());
                        yield put(webRtcStopLocalMedia());
                        // TODO: change app state based on error (-enter meeting id / show meeting info)
                        yield put(
                            changeAppState(APP_STATE.DISPLAY_MEETING_INFO)
                        );
                        yield put(
                            newError(
                                alcErrorCodeToErrorId(
                                    alcJoinAction.error.errorNo
                                ),
                                alcJoinAction.error.errorNo
                            )
                        );
                        break;
                    }
                }
            }
        } else {
            // if local media couldn't be started
            const communicationMode = yield select(getCommunicationMode);
            yield put(showSettings(communicationMode));
            yield put(changeAppState(APP_STATE.DISPLAY_MEETING_INFO));
        }
    }
}

// TODO: need to stop spawned sagas on leavePodium/failed reconnect?
function* joinPodium() {
    while (true) {
        const { meetingId, participantName, email } = yield take(JOIN_PODIUM);

        // wait until alc initialized
        if (!(yield select(getAlcInitialized))) {
            yield take(ALC_INITIALIZED);
        }

        // setup watchers
        const watchers = yield all([
            takeEvery(CHANGE_APP_STATE, appStateWorker),
            takeEvery(ALC_STREAM_INFO_UPDATE, streamInfoWorker),
        ]);
        yield spawn(podiumSwitchHandler);

        // join meeting
        yield put(
            alcJoinMeeting(
                meetingId,
                participantName,
                email,
                COMMUNICATION_MODES.NONE
            )
        );
        while (true) {
            const { joinMeetingSuccess, joinMeetingFailure } = yield race({
                joinMeetingSuccess: all({
                    joinedMeeting: take(ALC_JOIN_MEETING_SUCCESS),
                    podiumInfo: take(ALC_PODIUM_INFO_UPDATE),
                    streamInfo: take(ALC_STREAM_INFO_UPDATE),
                }),
                joinMeetingFailure: take(ALC_ERROR),
            });
            if (joinMeetingSuccess) {
                yield put(joinPodiumSuccess());
                break;
            }

            yield cancel(watchers);

            if (joinMeetingFailure.context === 'joinMeeting') {
                yield put(joinPodiumFailure());

                // TODO: change app state based on error (-enter meeting id / show meeting info)
                yield put(changeAppState(APP_STATE.DISPLAY_MEETING_INFO));
                yield put(
                    newError(
                        alcErrorCodeToErrorId(joinMeetingFailure.error.errorNo),
                        joinMeetingFailure.error.errorNo
                    )
                );
                break;
            }
        }
    }
}

function* podiumSwitchHandler() {
    // TODO: error handling, option to choose comMode/device settings?
    while (true) {
        const alcChangeSpeakerPermissionAction = yield take(
            ALC_CHANGE_SPEAKER_PERMISSION
        );

        if (alcChangeSpeakerPermissionAction.permission) {
            const meetingInfoAction = yield take(ALC_MEETING_INFO_UPDATE);
            // join webRtc meeting
            yield put(
                webRtcJoin(
                    alcChangeSpeakerPermissionAction.joinOptions,
                    meetingInfoAction.meetingInfo
                )
            );
            const webRtcJoinAction = yield take([
                WEBRTC_JOIN_SUCCESS,
                WEBRTC_JOIN_FAILURE,
            ]);
            if (webRtcJoinAction.type === WEBRTC_JOIN_SUCCESS) {
                yield put(changeAppState(APP_STATE.IN_MEETING));
            } else {
                yield put(
                    webRtcChangeCommunicationMode(COMMUNICATION_MODES.NONE)
                );
                yield take(WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS);
                yield put(
                    webRtcJoin(
                        alcChangeSpeakerPermissionAction.joinOptions,
                        meetingInfoAction.meetingInfo
                    )
                );
                const webRtcReJoinAction = yield take([
                    WEBRTC_JOIN_SUCCESS,
                    WEBRTC_JOIN_FAILURE,
                ]);

                if (webRtcReJoinAction.type === WEBRTC_JOIN_SUCCESS) {
                    yield put(changeAppState(APP_STATE.IN_MEETING));
                    yield put(showSettings(COMMUNICATION_MODES.NONE));
                } else {
                    yield put(
                        showMessage({
                            contentId: 'podiumSwitchError',
                            type: 'warn',
                        })
                    );
                    yield put(revokeSpeakerPermission());
                }
            }
        } else {
            // leave webRtc meeting if joined
            const webRtcMeetingJoined = yield select(getWebRtcMeetingJoined);
            if (webRtcMeetingJoined) {
                yield put(webRtcLeave());
            }
            const appState = yield select(getAppState);
            if (appState !== APP_STATE.PODIUM_STREAM) {
                yield put(changeAppState(APP_STATE.PODIUM_STREAM));
            }
        }
    }
}

function* joinMeetingStandalone() {
    while (true) {
        const { meetingId, participantName, email, token } = yield take(
            JOIN_MEETING_STANDALONE
        );
        const alcInitialized = yield select(getAlcInitialized);
        // wait until alc initialized
        if (!alcInitialized) {
            yield take(ALC_INITIALIZED);
        }
        yield put(
            alcJoinMeeting(
                meetingId,
                participantName,
                email,
                COMMUNICATION_MODES.NONE,
                undefined,
                { standalone: true },
                undefined,
                token
            )
        );
        while (true) {
            const action = yield take([ALC_JOIN_MEETING_SUCCESS, ALC_ERROR]);
            if (action.type === ALC_JOIN_MEETING_SUCCESS) {
                yield put(joinMeetingStandaloneSuccess());
                yield put(
                    switchOwnSharedApplication(
                        action.meetingInfo.type === MEETING_TYPES.PODIUM
                            ? SA_APPLICATIONS.STREAM_CHAT
                            : SA_APPLICATIONS.CHAT
                    )
                );
                yield put(showSharedApplications());
                break;
            } else {
                if (action.context === 'joinMeetingStandalone') {
                    yield put(joinMeetingFailure());
                    yield put(changeAppState(APP_STATE.DISPLAY_MEETING_INFO));
                    yield put(
                        newError(
                            alcErrorCodeToErrorId(action.error.errorNo),
                            action.error.errorNo
                        )
                    );
                    break;
                }
            }
        }
    }
}

function* leaveMeeting() {
    while (true) {
        const leaveAction = yield take([
            LEAVE_MEETING,
            ALC_KICKED,
            ALC_MEETING_COMPLETED,
            WEBRTC_RECONNECTING_FAILURE,
            ALC_TRANSFERRED,
        ]);
        // leave webRtc meeting if joined
        const webRtcMeetingJoined = yield select(getWebRtcMeetingJoined);
        if (webRtcMeetingJoined) {
            yield put(webRtcLeave());
        }
        // leave alc meeting if joined
        const alcMeetingJoined = yield select(getAlcMeetingJoined);
        if (alcMeetingJoined) {
            yield put(alcLeaveMeeting());
        }

        if (leaveAction.type !== ALC_TRANSFERRED) {
            const webRtcInitialized = yield select(getWebRtcInitialized);
            if (webRtcInitialized) {
                yield put(webRtcClose());
            }
            const alcInitialized = yield select(getAlcInitialized);
            if (alcInitialized) {
                yield put(alcClose());
            }
        }

        if (leaveAction.type === WEBRTC_RECONNECTING_FAILURE) {
            yield put(changeAppState(APP_STATE.DISPLAY_MEETING_INFO));
            yield put(newError('alcErrorMeetingConnectionLost'));
        } else {
            if (leaveAction.type === ALC_TRANSFERRED) {
                yield put(changeAppState(APP_STATE.CM_CONNECTING));
            } else {
                yield put(changeAppState(APP_STATE.LEFT_MEETING));
            }
            if (leaveAction.type === ALC_KICKED) {
                yield put(newError('msgKick'));
            }
        }
    }
}

function* reconnecting() {
    while (true) {
        yield take(ALC_RECONNECTING);
        const alcMeetingJoined = yield select(getAlcMeetingJoined);
        const action = yield take([ALC_SESSION_RESTORED, ALC_INITIALIZED]);
        if (action.type === ALC_INITIALIZED) {
            const webRtcMeetingJoined = yield select(getWebRtcMeetingJoined);
            if (webRtcMeetingJoined) {
                yield put(webRtcLeave());
            }
            if (alcMeetingJoined) {
                const params = new URLSearchParams(window.location.search);

                // try automatic rejoining in recorder mode
                if (params.get('recorder') === 'true') {
                    const meetingId = params.get('id');
                    yield put(requestMeetingInfo(meetingId));
                    const meetingInfoAction = yield take(
                        ALC_GET_MEETING_INFO_SUCCESS
                    );
                    yield put(
                        joinMeetingAction(
                            meetingInfoAction.meetingInfo._id,
                            'recordingClient',
                            undefined,
                            { recorder: true },
                            params.get('auth')
                        )
                    );
                } else {
                    const topicId = params.get('topicId');
                    if (topicId) {
                        yield put(setTopicId(topicId));
                        yield put(changeAppState(APP_STATE.CM_ENTER_INFO));
                    } else {
                        const meetingId = params.get('id');
                        if (!meetingId) {
                            yield put(
                                changeAppState(APP_STATE.ENTER_MEETING_ID)
                            );
                        } else {
                            yield put(
                                changeAppState(APP_STATE.DISPLAY_MEETING_INFO)
                            );
                        }
                    }
                    yield put(newError('alcErrorMeetingConnectionLost'));
                }
            }
        }
    }
}

function* initiateCallSaga() {
    while (true) {
        const action = yield take([INITIATE_CALL, ALC_TRANSFERRED]);
        let webRtcErrorCode = null;
        // skip setup if being transferred
        if (action.type === INITIATE_CALL) {
            const { topicId, participantName, email } = action;
            yield put(changeAppState(APP_STATE.CM_CONNECTING));

            const webRtcInitialized = yield select(getWebRtcInitialized);
            // wait until webRtc layer is initialized
            if (!webRtcInitialized) {
                yield take(WEBRTC_INIT_SUCCESS);
            }
            const alcInitialized = yield select(getAlcInitialized);
            // wait until alc initialized
            if (!alcInitialized) {
                yield take(ALC_INITIALIZED);
            }

            // if alc meeting is joined, start webRtc localMedia
            yield put(webRtcStartLocalMedia());
            const startLocalMediaAction = yield take([
                WEBRTC_START_LOCAL_MEDIA_SUCCESS,
                WEBRTC_START_LOCAL_MEDIA_FAILURE,
            ]);
            // if webRtc fails try with commMode none
            if (
                startLocalMediaAction.type === WEBRTC_START_LOCAL_MEDIA_FAILURE
            ) {
                webRtcErrorCode = startLocalMediaAction.error.errorCode;
                yield put(
                    webRtcChangeCommunicationMode(COMMUNICATION_MODES.NONE)
                );
                yield take(WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS);
            }
            const communicationMode = yield select(getCommunicationMode);
            yield put(
                alcStartCall(topicId, participantName, email, communicationMode)
            );
        }
        while (true) {
            const alcJoinAction = yield take([
                ALC_JOIN_MEETING_SUCCESS,
                ALC_ERROR,
            ]);
            if (alcJoinAction.type === ALC_JOIN_MEETING_SUCCESS) {
                yield put(webRtcStartLocalMedia());
                const startLocalMediaAction = yield take([
                    WEBRTC_START_LOCAL_MEDIA_SUCCESS,
                    WEBRTC_START_LOCAL_MEDIA_FAILURE,
                ]);
                if (
                    startLocalMediaAction.type ===
                    WEBRTC_START_LOCAL_MEDIA_SUCCESS
                ) {
                    // if alc meeting is joined, join webRtc meeting
                    yield put(
                        webRtcJoin(
                            alcJoinAction.joinOptions,
                            alcJoinAction.meetingInfo
                        )
                    );
                    const webRtcJoinAction = yield take([
                        WEBRTC_JOIN_SUCCESS,
                        WEBRTC_JOIN_FAILURE,
                    ]);
                    if (webRtcJoinAction.type === WEBRTC_JOIN_SUCCESS) {
                        yield put(joinMeetingSuccess());
                        if (webRtcErrorCode) {
                            const communicationMode = yield select(
                                getCommunicationMode
                            );
                            yield put(
                                webRtcSettingsError(
                                    webRtcErrorCodeToErrorId(webRtcErrorCode)
                                )
                            );
                            yield put(showSettings(communicationMode));
                        }
                        break;
                    } else {
                        yield put(joinMeetingFailure());
                        yield put(alcLeaveMeeting());
                        let errorCode;
                        if (webRtcJoinAction.error) {
                            errorCode = webRtcJoinAction.error.errorCode;
                        }
                        yield put(changeAppState(APP_STATE.ENTER_MEETING_ID));
                        yield put(
                            newError(
                                webRtcErrorCodeToErrorId(errorCode),
                                errorCode
                            )
                        );
                        break;
                    }
                } else {
                    // if local media couldn't be started
                    yield put(alcLeaveMeeting());
                    const communicationMode = yield select(
                        getCommunicationMode
                    );
                    yield put(showSettings(communicationMode));
                    yield put(
                        webRtcSettingsError(
                            webRtcErrorCodeToErrorId(webRtcErrorCode)
                        )
                    );
                    yield put(changeAppState(APP_STATE.ENTER_MEETING_ID));
                    break;
                }
            } else {
                if (
                    alcJoinAction.context === 'joinMeeting' ||
                    alcJoinAction.context === 'acceptCall'
                ) {
                    yield put(alcLeaveMeeting());
                    yield put(joinMeetingFailure());
                    yield put(webRtcStopLocalMedia());
                    // TODO: change app state based on error (-enter meeting id / show meeting info)
                    yield put(changeAppState(APP_STATE.CM_ENTER_INFO));
                    yield put(
                        newError(
                            alcErrorCodeToErrorId(alcJoinAction.error.errorNo),
                            alcJoinAction.error.errorNo
                        )
                    );
                    break;
                }
            }
        }
    }
}

export function* meetingsSagas() {
    yield all([
        joinMeeting(),
        joinMeetingStandalone(),
        joinPodium(),
        leaveMeeting(),
        reconnecting(),
        initiateCallSaga(),
    ]);
}
