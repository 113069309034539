import favicon from '../assets/favicons/favicon_poslive.svg';
import('../css/themes/Theme_default.scss');

export const Config = {
    serviceUrl: 'https://al5-pos-live.adia.tv',
    serviceIdFromUrl: true,

    documentTitle: 'PoS-Live',
    favicon,
};
