export const PLAYER_LOAD = "PLAYER_LOAD";
export const PLAYER_LOADED = "PLAYER_LOADED";
export const PLAYER_INIT = "PLAYER_INIT";
export const PLAYER_INITIALIZED = "PLAYER_INITIALIZED";
export const PLAYER_SET_SOURCE = "PLAYER_SET_SOURCE";
export const PLAYER_PLAY = "PLAYER_PLAY";
export const PLAYER_PAUSE = "PLAYER_PAUSE";
export const PLAYER_SEEK = "PLAYER_SEEK";
export const PLAYER_STOP = "PLAYER_STOP";
export const PLAYER_PLAY_STATE_CHANGE = "PLAYER_PLAY_STATE_CHANGE";
export const PLAYER_HIDE = "PLAYER_HIDE";
export const PLAYER_SHOW = "PLAYER_SHOW";
export const PLAYER_VISIBILITY_CHANGE = "PLAYER_VISIBILITY_CHANGE";
export const PLAYER_AVAILABLE_QUALITIES = "PLAYER_AVAILABLE_QUALITIES";
export const PLAYER_SET_QUALITY_LEVEL = "PLAYER_SET_QUALITY_LEVEL";
export const PLAYER_QUALITY_LEVEL_CHANGE = "PLAYER_QUALITY_LEVEL_CHANGE";
export const PLAYER_RESOLUTION_CHANGE = "PLAYER_RESOLUTION_CHANGE";
export const PLAYER_SET_VOLUME = "PLAYER_SET_VOLUME";
export const PLAYER_VOLUME_CHANGE = "PLAYER_VOLUME_CHANGE";
export const PLAYER_TOGGLE_FULLSCREEN = "PLAYER_TOGGLE_FULLSCREEN";
export const PLAYER_FULLSCREEN_CHANGE = "PLAYER_FULLSCREEN_CHANGE";
export const PLAYER_ERROR = "PLAYER_ERROR";
export const PLAYER_PLAYBACK_NOT_ALLOWED = "PLAYER_PLAYBACK_NOT_ALLOWED";
export const PLAYER_REFRESH = "PLAYER_REFRESH";
export const PLAYER_RESET = "PLAYER_RESET";
export const PLAYER_DESTROY = "PLAYER_DESTROY";
