import { serviceUrlToAPIUrl } from '../features/base/util/helpers';
import { Config } from '../config/Config';
import { ERRORS } from '../constants/constants';

const apiUrl = serviceUrlToAPIUrl(Config.serviceUrl);

const al5ApiService = {
    /** PUBLIC: public routes (no authentication needed) **/
    getPublicServiceInfo: async (serviceId) => {
        const url = apiUrl + '/public/services/' + serviceId;

        let response = {
            publicServiceInfo: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.publicServiceInfo = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    createContactRequest: async (serviceId, contactRequestData) => {
        const url = apiUrl + '/public/contact-requests/' + serviceId;

        let response = {
            contactRequest: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(contactRequestData),
                method: 'POST',
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.contactRequest = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },
};

export default al5ApiService;
