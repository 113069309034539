import React, { Component } from 'react';

import { audioLevelMap } from '../base/util/webRtcStats';

export default class DeviceSettingsAudioLevel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            audioLevel: 0
        };

        this.checkAudioLevel = this.checkAudioLevel.bind(this);
        this.checkAudioLevelInterval = null;
    }

    componentDidMount() {
        this.checkAudioLevelInterval = setInterval(this.checkAudioLevel, 100);
    }

    componentWillUnmount() {
        clearInterval(this.checkAudioLevelInterval);
    }

    checkAudioLevel() {
        let audioLevel = audioLevelMap.get('preview');
        if (!audioLevel) {
            audioLevel = 0;
        }
        this.setState({ audioLevel });
    }

    render() {
        const barWidth =
            Math.round(
                (100 * Math.log(1 + this.state.audioLevel)) / Math.log(2)
            ) + '%';

        return (
            <div className="settings-audio-level-container">
                <div
                    className="settings-audio-level-bar"
                    style={{ width: barWidth }}
                />
            </div>
        );
    }
}
