import React from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import VideoBox from './VideoBox';
import {
    MEETING_TYPES,
    RESPONSIVE_MODES,
    RESPONSIVE_ORIENTATIONS,
    SCREEN_SHARING_MODES,
    SPEAKER_PERMISSION_STATE,
} from '../../constants/constants';
import classNames from 'classnames';

export function MeetingVideoList(props) {
    const isMainVideo = props.clientInfo.id === props.mainVideoId;
    const videoActive = props.clientInfo.deviceInfo.video;
    const screenShareActive = props.clientInfo.deviceInfo.screen;

    let ownVideoView = undefined;
    let ownScreenShareView = undefined;

    if (
        videoActive &&
        !props.settingsPanelShown &&
        (!isMainVideo ||
            (screenShareActive &&
                props.clientInfo.userMode &&
                props.clientInfo.userMode.screenSharing ===
                    SCREEN_SHARING_MODES.SCREEN))
    ) {
        ownVideoView = props.ownVideoView;
    }
    if (screenShareActive) {
        ownScreenShareView = props.ownScreenShareView;
    }

    if (props.responsiveMode === RESPONSIVE_MODES.SMALL) {
        const localMobileVideo = (
            <VideoBox
                key={'self'}
                userInfo={props.clientInfo}
                isMainVideo={isMainVideo}
                isSelf={true}
                isCurrentActiveUser={
                    props.clientInfo.id === props.meetingInfo.currentActiveUser
                }
                isActiveSpeaker={props.clientInfo.id === props.activeSpeakerId}
                videoView={ownVideoView}
                screenShareActive={screenShareActive}
                screenShareView={ownScreenShareView}
                responsiveMode={props.responsiveMode}
            />
        );

        let remoteMobileVideo = null;

        let remoteVideoView;

        // if sharedApplications open, show currentActiveUser or currentSpeaker
        if (props.meetingInfo.currentApp) {
            let displayedRemoteParticipant;
            if (
                props.meetingInfo.currentActiveUser &&
                props.meetingInfo.currentActiveUser !==
                    props.ownVideoView.userId
            ) {
                displayedRemoteParticipant = props.meetingParticipants.find(
                    (el) => el.id === props.meetingInfo.currentActiveUser
                );
            }
            if (
                (!displayedRemoteParticipant ||
                    !displayedRemoteParticipant.deviceInfo.video) &&
                props.activeSpeakerId
            ) {
                displayedRemoteParticipant = props.meetingParticipants.find(
                    (el) => el.id === props.activeSpeakerId
                );
            }
            if (
                !displayedRemoteParticipant ||
                !displayedRemoteParticipant.deviceInfo.video
            ) {
                displayedRemoteParticipant = props.meetingParticipants.find(
                    (el) => el.deviceInfo.video
                );
            }
            if (
                displayedRemoteParticipant &&
                displayedRemoteParticipant.deviceInfo.video
            ) {
                remoteVideoView = props.videoViews.find(
                    (el) => el.userId === displayedRemoteParticipant.id
                );

                remoteMobileVideo = (
                    <VideoBox
                        key={displayedRemoteParticipant.id}
                        userInfo={displayedRemoteParticipant}
                        isMainVideo={false}
                        isSelf={false}
                        isCurrentActiveUser={
                            displayedRemoteParticipant.id ===
                            props.meetingInfo.currentActiveUser
                        }
                        isActiveSpeaker={
                            displayedRemoteParticipant === props.activeSpeakerId
                        }
                        videoView={remoteVideoView}
                        recording={false}
                        responsiveMode={props.responsiveMode}
                    />
                );
            }
        }

        return (
            <React.Fragment>
                <div
                    className={classNames('mobile-local-video', {
                        controlsVisible: props.controlsVisible,
                        landscape:
                            props.responsiveOrientation ===
                            RESPONSIVE_ORIENTATIONS.LANDSCAPE,
                        portrait:
                            props.responsiveOrientation ===
                            RESPONSIVE_ORIENTATIONS.PORTRAIT,
                    })}
                >
                    {localMobileVideo}
                </div>
                <div
                    className={classNames('mobile-remote-video', {
                        controlsVisible: props.controlsVisible,
                        landscape:
                            props.responsiveOrientation ===
                            RESPONSIVE_ORIENTATIONS.LANDSCAPE,
                        portrait:
                            props.responsiveOrientation ===
                            RESPONSIVE_ORIENTATIONS.PORTRAIT,
                    })}
                >
                    {remoteMobileVideo}
                </div>
            </React.Fragment>
        );
    } else {
        let videoItems = [];
        for (let i = 0; i < props.meetingParticipants.length; i++) {
            const meetingParticipant = props.meetingParticipants[i];
            if (
                !meetingParticipant.standalone &&
                meetingParticipant.id !== props.clientInfo.id &&
                ((props.meetingInfo.type !== MEETING_TYPES.WEBINAR &&
                    props.meetingInfo.type !== MEETING_TYPES.PODIUM) ||
                    meetingParticipant.isAdviser ||
                    meetingParticipant.speakerPermissionState ===
                        SPEAKER_PERMISSION_STATE.GRANTED) &&
                (!props.clientInfo.recorder ||
                    !meetingParticipant.isVisible === false)
            ) {
                const isMainVideo = meetingParticipant.id === props.mainVideoId;
                const videoActive = meetingParticipant.deviceInfo.video;
                const screenShareActive = meetingParticipant.deviceInfo.screen;

                let videoView = undefined;
                let screenShareView = undefined;

                if (
                    videoActive &&
                    (!isMainVideo ||
                        (screenShareActive &&
                            meetingParticipant.userMode &&
                            meetingParticipant.userMode.screenSharing ===
                                SCREEN_SHARING_MODES.SCREEN))
                ) {
                    videoView = props.videoViews.find(
                        (el) => el.userId === meetingParticipant.id
                    );
                }
                if (screenShareActive) {
                    screenShareView = props.screenShareViews.find(
                        (el) => el.userId === props.meetingParticipants[i].id
                    );
                }

                videoItems.push(
                    <VideoBox
                        key={meetingParticipant.id}
                        userInfo={meetingParticipant}
                        isMainVideo={isMainVideo}
                        isSelf={false}
                        isCurrentActiveUser={
                            meetingParticipant.id ===
                            props.meetingInfo.currentActiveUser
                        }
                        isActiveSpeaker={
                            meetingParticipant === props.activeSpeakerId
                        }
                        videoView={videoView}
                        screenShareActive={screenShareActive}
                        screenShareView={screenShareView}
                        recording={props.clientInfo.recorder}
                    />
                );
            }
        }

        return (
            <div
                className={
                    'participant-list' +
                    (props.controlsVisible ? ' controlsVisible' : '')
                }
            >
                <div className="remote-videos">
                    <Scrollbars>{videoItems}</Scrollbars>
                </div>
                <div className="local-video">
                    {!props.clientInfo.recorder && (
                        <VideoBox
                            key={'self'}
                            userInfo={props.clientInfo}
                            isMainVideo={isMainVideo}
                            isSelf={true}
                            isCurrentActiveUser={
                                props.clientInfo.id ===
                                props.meetingInfo.currentActiveUser
                            }
                            isActiveSpeaker={
                                props.clientInfo.id === props.activeSpeakerId
                            }
                            videoView={ownVideoView}
                            screenShareActive={screenShareActive}
                            screenShareView={ownScreenShareView}
                            responsiveMode={props.responsiveMode}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        meetingParticipants: state.meetings.meetingParticipants,
        meetingInfo: state.meetings.meetingInfo,
        activeSpeakerId: state.meetings.activeSpeakerId,
        videoViews: state.meetings.videoViews,
        screenShareViews: state.meetings.screenShareViews,
        ownVideoView: state.meetings.ownVideoView,
        ownScreenShareView: state.meetings.ownScreenShareView,
        clientInfo: state.meetings.clientInfo,
        mainVideoId: state.meetings.mainVideoId,
        controlsVisible: state.meetings.controlsVisible,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
        responsiveMode: state.base.common.responsiveMode,
        responsiveOrientation: state.base.common.responsiveOrientation,
    };
};

export default connect(mapStateToProps, undefined)(MeetingVideoList);
