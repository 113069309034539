import * as types from './actionTypes';

export function webRtcSettingsStartSuccess(
    currentSettings,
    view,
    micDeviceList,
    camDeviceList,
    communicationMode
) {
    return {
        type: types.WEBRTC_SETTINGS_START_SUCCESS,
        currentSettings,
        view,
        micDeviceList,
        camDeviceList,
        communicationMode
    };
}

export function webRtcChangeCam(cam) {
    return { type: types.WEBRTC_CHANGE_CAM, cam };
}

export function webRtcChangeCamSuccess(newSettings) {
    return { type: types.WEBRTC_CHANGE_CAM_SUCCESS, newSettings };
}

export function webRtcChangeMic(mic) {
    return { type: types.WEBRTC_CHANGE_MIC, mic };
}

export function webRtcChangeMicSuccess(newSettings) {
    return { type: types.WEBRTC_CHANGE_MIC_SUCCESS, newSettings };
}

export function webRtcChangeRes(res) {
    return { type: types.WEBRTC_CHANGE_RES, res };
}

export function webRtcChangeResSuccess(newSettings) {
    return { type: types.WEBRTC_CHANGE_RES_SUCCESS, newSettings };
}

export function webRtcChangeCommunicationMode(communicationMode) {
    return { type: types.WEBRTC_CHANGE_COMMUNICATION_MODE, communicationMode };
}

export function webRtcChangeCommunicationModeSuccess(
    currentSettings,
    view,
    micDeviceList,
    camDeviceList,
    communicationMode
) {
    return {
        type: types.WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS,
        currentSettings,
        view,
        micDeviceList,
        camDeviceList,
        communicationMode
    };
}

export function webRtcSettingsError(errorId) {
    return { type: types.WEBRTC_SETTINGS_ERROR, errorId };
}

export function webRtcMuteAudio(shouldMute) {
    return { type: types.WEBRTC_MUTE_AUDIO, shouldMute };
}

export function webRtcMuteVideo(shouldMute) {
    return { type: types.WEBRTC_MUTE_VIDEO, shouldMute };
}

export function webRtcRefreshDeviceLists(communicationMode) {
    return { type: types.WEBRTC_REFRESH_DEVICE_LISTS, communicationMode };
}

export function webRtcRefreshDeviceListsSuccess(
    micDeviceList,
    camDeviceList,
    errorId
) {
    return {
        type: types.WEBRTC_REFRESH_DEVICE_LISTS_SUCCESS,
        micDeviceList,
        camDeviceList,
        errorId
    };
}

export function webRtcApplyRecoverySettings() {
    return { type: types.WEBRTC_APPLY_RECOVERY_SETTINGS };
}

export function webRtcApplyRecoverySettingsSuccess(
    currentSettings,
    view,
    micDeviceList,
    camDeviceList,
    communicationMode
) {
    return {
        type: types.WEBRTC_APPLY_RECOVERY_SETTINGS_SUCCESS,
        currentSettings,
        view,
        micDeviceList,
        camDeviceList,
        communicationMode
    };
}

export function settingsRecoveryStart(communicationMode, errorCode) {
    return {
        type: types.SETTINGS_RECOVERY_START,
        communicationMode,
        errorCode
    };
}

export function settingsRecoveryStartSuccess(
    communicationMode,
    micDeviceList,
    camDeviceList,
    errorId
) {
    return {
        type: types.SETTINGS_RECOVERY_START_SUCCESS,
        communicationMode,
        micDeviceList,
        camDeviceList,
        errorId
    };
}

export function settingsRecoveryChangeCam(cam) {
    return { type: types.SETTINGS_RECOVERY_CHANGE_CAM, cam };
}

export function settingsRecoveryChangeMic(mic) {
    return { type: types.SETTINGS_RECOVERY_CHANGE_MIC, mic };
}

export function settingsRecoveryChangeRes(res) {
    return { type: types.SETTINGS_RECOVERY_CHANGE_RES, res };
}

export function settingsRecoveryChangeCommunicationMode(communicationMode) {
    return {
        type: types.SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE,
        communicationMode
    };
}

export function settingsRecoveryChangeCommunicationModeSuccess(
    communicationMode,
    micDeviceList,
    camDeviceList
) {
    return {
        type: types.SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE_SUCCESS,
        communicationMode,
        micDeviceList,
        camDeviceList
    };
}

export function settingsRecoveryChangeCommunicationModeFailure(errorId) {
    return {
        type: types.SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE_FAILURE,
        errorId
    };
}

export function showSettings() {
    return { type: types.SETTINGS_SHOW };
}

export function hideSettings() {
    return { type: types.SETTINGS_HIDE };
}
