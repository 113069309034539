import React, { Component } from 'react';
import { connect } from 'react-redux';

export class MeetingTime extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTimeString: '00:00'
        };

        this.updateTime = this.updateTime.bind(this);
        this.updateInterval = null;
    }

    componentDidMount() {
        this.updateTime();
        this.updateInterval = setInterval(this.updateTime, 500);
    }

    componentWillUnmount() {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
            this.updateInterval = null;
        }
    }

    static secondsToTime(sec, full) {
        let sec_num = parseInt(sec, 10);
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - hours * 3600) / 60);
        let seconds = sec_num - hours * 3600 - minutes * 60;
        let time;
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        if (hours > 0 || full) {
            if (full && hours < 10) {
                hours = '0' + hours;
            }
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            time = hours + ':' + minutes + ':' + seconds;
        } else {
            time = minutes + ':' + seconds;
        }

        return time;
    }

    updateTime() {
        let totalSeconds = Math.round(
            (Date.now() - this.props.startTime + this.props.serverTimeDiff) /
                1000
        );
        this.setState({
            currentTimeString: MeetingTime.secondsToTime(totalSeconds)
        });
    }

    render() {
        return <span className="timeInfo">{this.state.currentTimeString}</span>;
    }
}

const mapStateToProps = state => {
    return {
        serverTimeDiff: state.meetings.serverTimeDiff
    };
};

export default connect(mapStateToProps)(MeetingTime);
