import { call, delay, put, race, select, take } from 'redux-saga/effects';

import {
    playerHide,
    playerPlay,
    playerReset,
    playerShow,
    playerStop,
} from '../../libs/@adiacast/player/src/view/redux/actions';
import { hideStream, showStream } from './actions';
import { PLAYER_INITIALIZED } from '../../libs/@adiacast/player/src/view/redux/actionTypes';
import { HIDE_STREAM, SHOW_STREAM } from './actionTypes';
import { CHANGE_APP_STATE } from '../landingPage/actionTypes';
import {
    ALC_PODIUM_INFO_UPDATE,
    ALC_STREAM_INFO_UPDATE,
    JOIN_PODIUM_SUCCESS,
} from '../meetings/actionTypes';

import { isHttps, log } from '../base/util/helpers';
import { APP_STATE } from '../../constants/constants';

const getAppState = (state) => state.landingPage.appState;
const getService = (state) => state.landingPage.publicServiceInfo;
const getDisplayStream = (state) => state.livePlayer.displayStream;
const getPlayerInitialized = (state) => state.player.initialized;
const getPlayerType = (state) => state.player.type;
const getServerTimeDiff = (state) => state.meetings.serverTimeDiff;
const getStreamInfo = (state) => state.meetings.streamInfo;
const getPodiumInfo = (state) => state.meetings.podiumInfo;
const getIsOnAir = (state) => state.meetings.isOnAir;

export function* appStateWorker({ appState }) {
    if (appState !== APP_STATE.PODIUM_STREAM) return;

    const streamInfo = yield select(getStreamInfo);
    if (streamInfo.isOnAir) {
        yield call(streamInfoWorker, { streamInfo, changes: null });
    } else {
        yield put(hideStream());
        yield put(playerHide());
        yield put(playerStop());
    }
}

export function* streamInfoWorker({ streamInfo, changes }) {
    const appState = yield select(getAppState);
    if (appState !== APP_STATE.PODIUM_STREAM) {
        const { newStreamInfo } = yield race({
            podiumStream: take([
                JOIN_PODIUM_SUCCESS,
                (action) =>
                    action.type === CHANGE_APP_STATE &&
                    action.appState === APP_STATE.PODIUM_STREAM,
            ]),
            newStreamInfo: take(ALC_STREAM_INFO_UPDATE),
        });
        if (newStreamInfo) return;
    }
    log.debug('streamInfoWorker:', streamInfo, changes);

    const isOnAirChange = changes
        ? changes.hasOwnProperty('isOnAir')
        : streamInfo.hasOwnProperty('isOnAir') && streamInfo.isOnAir;
    if (isOnAirChange) {
        if (streamInfo.isOnAir) {
            if (!(yield select(getPlayerInitialized))) {
                yield take(PLAYER_INITIALIZED);
            } else {
                const isLowLatency = streamInfo.isLowLatency;
                const playerType = yield select(getPlayerType);
                if (
                    (playerType === 'ivs' && !isLowLatency) ||
                    (playerType !== 'ivs' && isLowLatency)
                ) {
                    // reset player because player type needs to be changed
                    const streamIsShown = yield select(getDisplayStream);
                    if (streamIsShown) {
                        yield put(hideStream());
                        yield put(playerHide());
                        yield put(playerStop());
                    }

                    yield put(playerReset({ useLiveLowLatency: isLowLatency }));
                    yield take(PLAYER_INITIALIZED);
                }
            }

            let source = streamInfo.playbackUrl;
            if (!source) {
                const service = yield select(getService);
                let podiumInfo = yield select(getPodiumInfo);
                if (!podiumInfo) {
                    ({ podiumInfo } = yield take(ALC_PODIUM_INFO_UPDATE));
                }

                source = `${podiumInfo.streamingUrl}/${
                    podiumInfo.streamingApp
                }/video_${service.podiumSettings.serviceId}_${podiumInfo.uid}${
                    streamInfo.isDynamicStream ? '_abr' : ''
                }/manifest.mpd`;
            }
            if (isHttps()) {
                source = source.replace(/^http:/, 'https:');
            } else {
                source = source.replace(/^https:/, 'http:');
            }
            log.debug('source', source);

            yield put(playerPlay(source));
        }

        // fallback for show/hide stream (in case id3 tags are missing)
        yield* displayStreamFallbackWorker(streamInfo);
    }
}

function* displayStreamFallbackWorker(streamInfo) {
    const streamIsShown = yield select(getDisplayStream);
    log.debug('displayStreamFallbackWorker:', streamInfo, streamIsShown);

    if (streamInfo.isOnAir) {
        // fallback for failed showStream
        const defaultMaxDelay = 60000;
        const defaultMinDelay = 2000;
        const onAirTime = streamInfo.onAirTime;
        const serverTimeDiff = yield select(getServerTimeDiff);
        const fallbackTimeout = Math.max(
            defaultMaxDelay - (Date.now() - (onAirTime - serverTimeDiff)),
            defaultMinDelay
        );
        log.debug(`show stream fallback timeout: ${fallbackTimeout}`);

        const { timeout } = yield race({
            showStream: take(SHOW_STREAM),
            timeout: delay(fallbackTimeout),
        });
        if (timeout) {
            const isOnAir = yield select(getIsOnAir);
            log.warn(`show stream fallback activated! (is on air: ${isOnAir})`);

            if (isOnAir) {
                yield put(playerShow());
                yield put(showStream());
            }
            return;
        }
        log.debug('show stream fallback cancelled');
    } else {
        // fallback for failed hideStream
        const defaultDelay = 60000;
        const lowLatencyDelay = 30000;
        const fallbackTimeout = streamInfo.isLowLatency
            ? lowLatencyDelay
            : defaultDelay;
        log.debug(`hide stream fallback timeout: ${fallbackTimeout}`);

        const { timeout } = yield race({
            showStream: take(HIDE_STREAM),
            timeout: delay(fallbackTimeout),
        });
        if (timeout) {
            const isOnAir = yield select(getIsOnAir);
            log.warn(`hide stream fallback activated! (is on air: ${isOnAir})`);

            if (!isOnAir) {
                yield put(hideStream());
                yield put(playerHide());
                yield put(playerStop());
            }
            return;
        }
        log.debug(`hide stream fallback cancelled`);
    }
}
